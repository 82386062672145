import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { } from '../../redux/reducers/reducer';
import { removeSpecialCharecters, location } from '../../utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip';
import { Buffer } from 'buffer';
import axios from 'axios';
import SelectionsExample from './SelectionsExample';
import { number } from 'prop-types';

let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let { appName } = window.app;
var urlParams = location("channelCode");

class Security extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getFormData: {},
            errors: {},
            formvalues: {},
            availability: this.props.availability || {},
            countriesArray: [],
            // added territoryType, territoryToggle, excludedTerritory in state
            territoryType: this.props.territoryType,
            territoryToggle: this.props.territoryType === 'exclude',
            territory: this.props.availability && this.props.availability.territory || [],
            excludedTerritory: this.props.availability && this.props.availability.excludedTerritory || [],
        }
        this.onChangeMultiSelect = this.onChangeMultiSelect.bind(this);
    }

    componentDidMount() {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let commonApi = siteConfig && siteConfig.commonApi;
        // self.props.getCountries();
        // let userId = localStorage.getItem("user_id");
        // // console.log('siteConfig.imagePrefix454545', imagePath)
        // this.setState({ userId })
        // if (urlParams) {
        //     this.setState({ id: urlParams });
        //     var payload = { "channelCode": urlParams }
        //     this.props.getChannels(payload);
        // }
        axios.get(commonApi + '/countries')
            .then((response) => {
                let countriesData = response.data.result || [];
                self.setState({ countriesData });
                if (countriesData.length > 0) {
                    // let countriesArray = [];
                    // _.each(countriesData, function (item) {
                    //   let obj = {};
                    //   obj["country"] = item.countryname
                    //   obj["code"] = item.alpha3 ? item.alpha3 : ''
                    //   countriesArray.push(obj)
                    // })
                    self.setState({ countriesArray: countriesData });
                }
            })
            .catch((err) => {
                console.error.bind("reducer.js getCountries", { err });
            })

    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.availability != this.props.availability) {
            // console.log("availability.....",this.props.availability)
            setTimeout(() => { self.setState({ availability: this.props.availability }) }, 1000);
        }

    }

    onChangeMultiSelect(e, type) {
        let self = this;
        let catalogvalues = self.state.formvalues;
        this.setState({ executing: false })
        switch (type) {
            // case "territory":
            //   let obj = { ...self.state.availability }
            //   obj["territory"] = obj["territory"] ? obj["territory"] : [];
            //   if (e && e.length > 0) {
            //     obj["territory"] = e;
            //     catalogvalues["availability"] = obj;
            //   } else {
            //     obj["territory"] = [];
            //     catalogvalues["availability"] = obj;
            //   }
            //   self.setState({ catalogvalues, formvalues: catalogvalues, availability: obj });
            //   break;
            case "City":
                let territoryObj = { ...self.state.territoryObj }
                territoryObj['city'] = e;
                self.setState({ territoryObj });
                break;
        }
        // this.props.handleAvailability(catalogvalues)
    }
    getRegions(countrycode) {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let commonApi = siteConfig && siteConfig.commonApi;
        axios.get(commonApi + '/regions/' + countrycode)
            .then((response) => {
                let regionData = response.data.result || [];
                self.setState({ regionData });
            })
            .catch((err) => {
                console.error.bind("reducer.js getCountries", { err });
            })
    }
    getCities(region, country) {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let commonApi = siteConfig && siteConfig.commonApi;
        let countryname = country || "";
        countryname = Buffer.from(countryname, "utf8");
        countryname = countryname.toString("base64");
        let regionname = region || "";
        regionname = Buffer.from(regionname, "utf8");
        regionname = regionname.toString("base64");
        axios.get(commonApi + '/cities/' + countryname + "/" + regionname)
            .then((response) => {
                let citiesData = response.data.result || [];
                this.setState({ citiesData, loading: false });
                if (citiesData.length > 0) {
                    let citesArray = [];
                    _.each(citiesData, function (item) {
                        let obj = item.city;
                        citesArray.push(obj)
                    })
                    self.setState({ citesArray });
                }
            })
            .catch((err) => {
                console.error.bind("reducer.js getCountries", { err });
            })
    }
    handleTerritory(e) {
        let self = this;
        let territoryObj = { ...self.state.territoryObj }
        const name = e.target.name;
        const value = e.target.value;

        if (name == 'country') {
            let tempCountry = value;
            if (tempCountry.includes("/")) {
                tempCountry = tempCountry.split('/');
                var countryname = tempCountry[0];
                var countrycode = tempCountry[1];
                delete territoryObj['region']
                delete territoryObj['regionCode']
                delete territoryObj['city'];
                territoryObj['country'] = countryname;
                territoryObj['countryCode'] = countrycode;
                let errors = { ...self.state.errors };
                delete errors['country'];
                self.setState({ errors, territoryObj, citesArray: undefined, regionData: [] });
                self.getRegions(countrycode);
            }
        } else if (name == 'region') {
            let tempRegion = value;
            if (tempRegion.includes("/")) {
                tempRegion = tempRegion.split('/');
                var regionname = tempRegion[0];
                var regioncode = tempRegion[1];
                territoryObj['region'] = regionname;
                territoryObj['regionCode'] = regioncode;
                delete territoryObj['city'];
                self.setState({ territoryObj, citesArray: undefined, loading: true })
                self.getCities(regionname, territoryObj.country);
            }
        } else {
            territoryObj[name] = value;
            self.setState({ territoryObj })
        }

        // if (name == 'country') {
        //   self.props.getRegions(value);
        //   let errors = { ...self.state.errors };
        //   delete errors['country'];
        //   self.setState({ errors });
        // } else if (name == 'region') {
        //   self.props.getCities(value, territoryObj.country);
        // }
        // territoryObj[name] = value;
        // self.setState({ territoryObj })

        //deleted error message after selecting same country following region
        let errors = { ...self.state.errors };
        errors['country'] = '';
        self.setState({ errors });
    }
    addTerritory(e) {
        let self = this;
        let territoryObj = { ...self.state.territoryObj };
        let formvalues = { ...self.state.formvalues };
        let availability = { ...self.state.availability };
        let territory = self.state.territory || availability.territory
        let excludedTerritory = self.state.excludedTerritory || availability.excludedTerritory;

        // checking territory is exists or not
        let isIncludeTerritoryExists = territory.find(eachItem => eachItem.country === territoryObj.country && eachItem.region === territoryObj.region)
        let isExcludeTerritoryExists = excludedTerritory.find(eachItem => eachItem.country === territoryObj.country && eachItem.region === territoryObj.region)

        //added Function for Error Message

        const validateCountryandRegion = (errorMessage) => {
            let errors = { ...self.state.errors };
            errors['country'] = errorMessage;
            self.setState({ errors });
        }

        //added condition to append object based on territory type
        if (territoryObj && territoryObj.country) {

            //added condition to display unique objects for include and exclude territory
            if (!isIncludeTerritoryExists && !isExcludeTerritoryExists) {
                this.props.territoryType === 'exclude' ? excludedTerritory.push(territoryObj) : territory.push(territoryObj)
            }
            else if (isIncludeTerritoryExists && this.props.territoryType === 'include') {
                //if the IncludeTerritory contains object it display error message
                validateCountryandRegion('Already Exists in this Territory')
            }
            else if (isIncludeTerritoryExists && this.props.territoryType === 'exclude') {
                //if the IncludeTerritory contains object it display error message
                validateCountryandRegion('Already Exists in Included Territory')
            }
            else if (isExcludeTerritoryExists && this.props.territoryType === 'exclude') {
                //if the ExcludeTerritory contains object it display error message
                validateCountryandRegion('Already Exists in this Territory')
            }
            else if (isExcludeTerritoryExists && this.props.territoryType === 'include') {
                //if the ExcludeTerritory contains object it display error message
                validateCountryandRegion('Already Exists in Excluded Territory')
            }
            else {
                validateCountryandRegion('Please select Country')
            }

            availability['excludedTerritory'] = excludedTerritory;
            availability['territory'] = territory;
            formvalues['availability'] = availability;
            self.setState({ excludedTerritory, territory, territoryObj: {}, availability, formvalues, regionData: [], citesArray: [] });

            this.props.handleAvailability(formvalues);


        }
        else {
            validateCountryandRegion('Please select Country')
        }
    }
    deleteExcludedTerritory(item, i) {
        let self = this;
        let formvalues = { ...self.state.formvalues };
        let availability = { ...self.state.availability };
        let excludedTerritory = [...availability.excludedTerritory];
        excludedTerritory.splice(i, 1);
        availability['excludedTerritory'] = excludedTerritory;
        formvalues['availability'] = availability;
        self.setState({ excludedTerritory, availability, formvalues });
        this.props.handleAvailability(formvalues);
    }
    deleteTerritory(item, i) {
        let self = this;
        let formvalues = { ...self.state.formvalues };
        let availability = { ...self.state.availability };
        let territory = [...availability.territory];
        territory.splice(i, 1);
        availability['territory'] = territory;
        formvalues['availability'] = availability;
        self.setState({ territory, availability, formvalues });
        this.props.handleAvailability(formvalues);
    }

    render() {
        let self = this;
        const { countriesArray, territoryObj, regionData, citesArray, territoryToggle, loading } = this.state;
        let territory = self.state.territory;
        let excludedTerritory = self.state.excludedTerritory;
        let selectedCountry = territoryObj && territoryObj.country + "/" + territoryObj.countryCode;
        let selectedRegion = territoryObj && territoryObj.region + "/" + territoryObj.regionCode;
        let siteSettings = window.site;

        return (
            <div className='panel_content page_height2'>

                <div className="row">
                    <div className="col-sm-8">
                        <div className='form_section security'>
                            <h4 className="mb-3">Security</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> Country</p>
                                        <select className="form-control" name="country" value={territoryObj && selectedCountry || ''} onChange={e => self.handleTerritory(e)}>
                                            <option value="">Select </option>
                                            {countriesArray && countriesArray.length > 0 && countriesArray.map((item, i) => {
                                                var countryValue = item.countryname + "/" + (item.alpha3 ? item.alpha3 : '');
                                                return (
                                                    <option key={i} value={countryValue}>{item.countryname}</option>
                                                );
                                            })}
                                        </select>
                                        <span className="errormsg">{self.state.errors && self.state.errors.country}</span>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> Region</p>
                                        <select className="form-control" name="region" value={territoryObj && selectedRegion || ''} onChange={e => self.handleTerritory(e)}>
                                            <option value="">Select </option>
                                            {regionData && regionData.length > 0 && regionData.map((item, i) => {
                                                var regionValue = item.regionname + "/" + (item.regioncode ? item.regioncode : '');
                                                return (
                                                    <option key={i} value={regionValue}>{item.regionname}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> City</p>
                                        {citesArray ? <SelectionsExample className="ter" options={citesArray} onChange={self.onChangeMultiSelect} selected={territoryObj && territoryObj.city || []} placeholder='City' labelKey='item' /> :
                                            <select><option value="">{loading ? 'Loading...' : 'Select'} </option></select>}
                                        <span className="errormsg">{self.state.errors.tettitory}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> Zipcode</p>
                                        <input type='number' min='1' name="postalcode" placeholder='Zipcode' value={territoryObj && territoryObj.postalcode || ''} onChange={e => self.handleTerritory(e)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> Latitude</p>
                                        <input type='text' name="latitude" placeholder='Latitude' value={territoryObj && territoryObj.latitude || ''} onChange={e => self.handleTerritory(e)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> Longitude</p>
                                        <input type='text' name="longitude" placeholder='Longitude' value={territoryObj && territoryObj.longitude || ''} onChange={e => self.handleTerritory(e)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="inputgroup">
                                        <p className="label"> Radius</p>
                                        <input type='number' min='1' name="radius" placeholder='Radius' value={territoryObj && territoryObj.radius || ''} onChange={e => self.handleTerritory(e)} />
                                    </div>
                                </div>


                                <div className="col-md-2">
                                    <div className="list_block">
                                        <div className="add_list ">

                                            <div className="close_custom">

                                                <div className="form-group" style={{ marginBottom: '-5px' }}>
                                                    <ul className="tool-list selected-categorie">
                                                        <li className="cat-list">
                                                            <span className="delete-btn">
                                                                <button className=" user_search" onClick={e => self.addTerritory(e)}><span className="material-icons"> add_circle_outline </span>Add</button>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 security_items">

                                    {/* Added territoryToggle in below conditon to display the dom based on territory type */}
                                    {!territoryToggle && territory && territory.length > 0 ? <div className="list_block">
                                        {territory && territory.length > 0 &&
                                            territory.map((item, i) => {
                                                return (
                                                    <div className="add_list" key={i}>
                                                        <div className='sec_cn'>
                                                            <span className="add_litm tr_country">{item.country},</span>
                                                            <span className="add_litm tr_region">{item.region},</span>
                                                            <span className="add_litm tr_city"> {item.city && item.city.length > 0 &&
                                                                item.city.map((item, j) => {
                                                                    return (
                                                                        <span key={j}> {item},</span>
                                                                    );
                                                                })},
                                                            </span>
                                                            <span className="add_litm tr_zipcode">{item.postalcode},</span>
                                                            <span className="add_litm tr_latitude">{item.latitude},</span>
                                                            <span className="add_litm tr_longitude">{item.longitude},</span>
                                                            <span className="add_litm tr_radius">{item.radius}</span>
                                                        </div>
                                                        <div className="delete-btn">
                                                            <button onClick={e => self.deleteTerritory(item, i)} className="btn"><span className="material-icons"> delete_outline </span></button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div> : null}
                                    {/* Added excludedTerritory object items  */}
                                    {territoryToggle && excludedTerritory && excludedTerritory.length > 0 ? <div className="list_block">
                                        {excludedTerritory && excludedTerritory.length > 0 &&
                                            excludedTerritory.map((item, i) => {
                                                return (
                                                    <div className="add_list" key={i}>
                                                        <div className="add_litm tr_country">{item.country}</div>
                                                        <div className="add_litm tr_region">{item.region}</div>
                                                        <div className="add_litm tr_city"> {item.city && item.city.length > 0 &&
                                                            item.city.map((item, j) => {
                                                                return (
                                                                    <span key={j}> {item},</span>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="add_litm tr_zipcode">{item.postalcode}</div>
                                                        <div className="add_litm tr_latitude">{item.latitude}</div>
                                                        <div className="add_litm tr_longitude">{item.longitude}</div>
                                                        <div className="add_litm tr_radius">{item.radius}</div>
                                                        <div className="delete-btn">
                                                            <button onClick={e => self.deleteExcludedTerritory(item, i)} className="btn-grey btn btn-default u-bt"><i className="zmdi zmdi-delete zmdi-hc-fw"></i></button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

const mapState = ({ loading }) => ({ loading });
const mapDispatch = {};
export default connect(mapState, mapDispatch)(Security);