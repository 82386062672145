import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { manageChannels, getChannels,getVodSources, getSourceLocation, } from '../../redux/reducers/reducer';
import { removeSpecialCharecters, location } from '../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip'
// import SelectionsExample from './SelectionsExample';
import axios from 'axios';
import Availability from './Availability';
import Security from './Security';
import Loader from "../Loader";
let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let { appName } = window.app;
var urlParams = location("channelCode");

const userMenuList = [
    {
        id: '1',
        name: 'info',
        labelName: 'Info'
    },
    {
        id: '2',
        name: 'event_available',
        labelName: 'Availability'
    },
    {
        id: '10',
        name: 'security',
        labelName: 'Security'
    },
    // {
    //     id: '10',
    //     name: 'settings',
    //     labelName: 'Playlist'
    // },

]
class Profile extends Component {
    constructor() {
        super();
        this.state = {
            getFormData: {},
            errors: {},
            buttonClass: "btn btn-green jr-btn un-btn",
            buttonClassGray: "btn btn-gray jr-btn un-btn",
            showMessage: "",
            custom: false,
            showalert: false,
            playListsassetsData: [],
            playlistbyurl: {},
            playlistbyUsers: [],
            deleteEvent: false,
            emailSendingButton: false,
            emailSendingSecureButton: false,
            availabilityPopup: false,
            embedLinkButton: false,
            embedEmailButton: false,
            showalertmsg: "",
            dummyObj: {},
            perPageConst: 4,
            perPage: 4,
            pageNo: 1,
            assetFile: false,
            alert: false,
            showSideMenu: false,
            addMetadata: false,
            status: "Inactive",
            settings: {},
            formvalues: {},
            availability: { territory: [], excludedTerritory: [] },
            assetType: 'Standalone',
            audioData: [],
            videoData: [],
            loader: false,
            isLoader:false,
            isSubscription: false,
            assetLoader: false,
            preview: {
                "enable": false,
                "maxMin": 1,
                "startAt": 0
            },
            embed: {
                "allow": false,
                "sidePanel": {
                    "display": false,
                    "enable": false
                }
            },
            donation: {
                "allow": false,
                sessions: []
            },
            voting: {
                "allow": false,
                sessions: []
            },
            likeDislike: {
                "allow": false,
            },
            isScheduling: true,
            weekendsVisible: true,
            currentEvents: [],
            calendarEvents: [],
            events: [],
            activity: 'fetch',
            activeTab: '1',
            isOpen: false,
            assetData: {},
            assetCount: 12,
            pageCount: 12,
            pageNumber: 1,
            assetsData: [],
            loadbtn: "Load More",
            assetTotal: 0,
            noOfAssets: 0,
            isLoading: true,
            isSementsLoading: false,
            isAddScheduleOpen: false,
            showAlert: false,
            errorMsg: '',
            isMetaDataReady: false,
            eventsData: [],
            addMetadata: false,
            assetFile: false,
            noOfPlaylists: 0,
            playlistTotal: 0,
            playlistPageNumber: 1,
            playlistPageCount: 12,
            isUpdateEnabled: false,
            assetInfoData: {},
            formData: {},
            showDeleteEventAlert: false,
            isSegmentPopup: false,
            segmentData: {},
            isExpand: false,
            markerData: {},
            showDeletePopup: false,
            custom: false,
            showDeleteAlert: false,
            actionButton: false,
            isActiveId: userMenuList[0].id,
            playbackmodeObj: {
                "STANDARD": ["LINEAR"],
                "BASIC": [ "LINEAR","LOOP"]
            },
            playbackmode: "",
            sucesspopup:false
            // id :this.props.params.id,
            // assetid :this.props.params.assetid
        }
        this.handleAvailability = this.handleAvailability.bind(this);
        // this.handleShare = this.handleShare.bind(this);
        // this.videoChange = this.videoChange.bind(this);
        // this.audioChange = this.audioChange.bind(this);
        // this.emailSendingSubmit = this.emailSendingSubmit.bind(this);
        // this.emailSending = this.emailSending.bind(this);
        // this.onKeyDown = this.onKeyDown.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.tagsRef = React.createRef();
        // this.onChangeMultiselect = this.onChangeMultiselect.bind(this);
        // this.onChangeForm = this.onChangeForm.bind(this);
        // this.onChangeStatus = this.onChangeStatus.bind(this);
    }

    componentDidMount() {
        let self = this;
        let userId = localStorage.getItem("user_id");
        this.setState({ userId })
        if (urlParams) {
            this.setState({ channelCode: urlParams });
            var payload = { "channelCode": urlParams }
           localStorage.setItem("storageScheduleId", urlParams)
            this.props.getChannels(payload, true);
        }
      //  self.getPlaylist();

      this.props.getVodSources();
      this.props.getSourceLocation();

    }
    onConfirm() {
        let self = this
        this.setState({ custom: false, addMetadata: false, showalert: false, deleteEvent: false, showMessage: false, isSubscription: false, availabilityPopup: false,sucesspopup:false });
        this.setState({ custom: false, showMessage: false, showalert: false, addMetadata: false,sucesspopup:false });
    };
    onConfirmSuccess(){
        this.setState({ sucesspopup:false });
        // console.log('this.state.schedulesData',this.state.schedulesData)
        localStorage.setItem("schedulesLocalData", JSON.stringify(this.state.schedulesData[0]))
        let calendarId = this.state.schedulesData && this.state.schedulesData[0] && this.state.schedulesData[0].channelcode
        window.location = `/channel?channelCode=${calendarId}`;
    }
    componentDidUpdate(prevProps) {
        let self = this;
        var payload;
        let type = "envoiPlaylistByUsers"
       
        // if (prevProps.channels !== this.props.channels) {
        //     let channelsData = ((this.props.channels || {}).data || [])[0] || {}
        //     if (channelsData) {
        //         let data = { channelcode: channelsData.channelcode, type: channelsData.serviceprovider, pageNo: self.state.pageNo, perPage: self.state.perPage, isActive: true }
        //         let videoDevicesData = channelsData && channelsData.settings && channelsData.settings.videoDevices && channelsData.settings.videoDevices.label;
        //         let audioDevicesData = channelsData && channelsData.settings && channelsData.settings.audioDevices && channelsData.settings.audioDevices.label;

        //         this.setState({
        //             formData: channelsData,
        //             availability: channelsData.availability,
        //             destinations: channelsData.destinations,
        //             id: channelsData._id,
        //             serviceProvider: channelsData.serviceprovider,
        //             audio: audioDevicesData,
        //             video: videoDevicesData,
        //             playlistbyUsers: channelsData.playlistbyUsers,
        //             isScheduling: true
        //         }, () => {
        //             this.init();
        //         })
        //         _.each(Object.keys(channelsData), function (item) {
        //             self.setState({ [item]: channelsData[item] });
        //         })


        //         let userIds = []
        //         channelsData && channelsData.playlistbyUsers && channelsData.playlistbyUsers.map((user) => { userIds.push(user.uuid) });
        //         switch (data && data.type) {
        //             case 'envoiPlaylistByUsers':
        //                 data['type'] = 'envoiPlaylistByUsers'
        //                 if (userIds.length == 0) {
        //                     const { playlistbyUsers } = this.state;
        //                     playlistbyUsers && playlistbyUsers.map((user) => { userIds.push(user.uuid) });
        //                 }
        //                 break;
        //         }

        //         // if (data.type === 'envoiPlaylistByUsers') {
        //         //     let payload = { playListId: userIds, isActive: true }
        //         //     self.getPlaylistsassets(payload);
        //         // } else {
        //         //     self.getPlaylistsassets(data);
        //         // }

        //         // if (channelsData && channelsData.playlistbyUsers) {
        //         //     let userPlaylistData;
        //         //     const { playListsData } = self.state;
        //         //     userPlaylistData = playListsData && playListsData.filter(playListsData => playListsData.uuid && playListsData.playlistname)
        //         //     self.setState({ playListsData: playListsData });
        //         // }
        //     }
        // }
       

        if (prevProps.vodSourcesData !== this.props.vodSourcesData) {
            let vodSourcesData = (this.props.vodSourcesData || []);
            this.setState({ vodSourcesData, isLoading: false, });
        }

        if (prevProps.sourceLocationData !== this.props.sourceLocationData) {
            //  console.log("this.props.sourceLocationData", this.props.sourceLocationData)
            let sourceLocation = (this.props.sourceLocationData || '');
            this.setState({ sourceLocationData: [sourceLocation], isLoading: false, });
        }



    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        //let payload;
        let type = "envoiPlaylistByUsers"
        if (nextProps.manageChannelsData !== this.props.manageChannelsData) {
            let manageChannelData = (nextProps && nextProps.manageChannelsData || {})[0] || {};
            self.setState({
                channelCode: manageChannelData.channelcode,
            });
            if (manageChannelData && manageChannelData._id) {
                
                //   console.log('manageChannelData', manageChannelData)
                // self.props.getForms(payload);
                setTimeout(function () {
                   let payload = { "_id": manageChannelData._id }
                    self.props.getChannels(payload, true);
                    self.setState({
                        sucesspopup:true,
                        isLoader: false
                    });
                    // if(manageChannelData && manageChannelData.channelcode){
                    //     window.location = '?channelCode=' + manageChannelData.channelcode
                    // }else{
                    //     window.location = '?channelCode=' + this.state.calendarId;
                    // }
                }, 1000);
                
                //
               // window.location = '?channelCode=' + this.state.calendarId || manageChannelData.channelcode;

            }
            
        }
        if (nextProps.channels !== this.props.channels) {
            let channelsData = (nextProps.channels || {}).data || [];
            
           // console.log("channelsData ", channelsData, calendarId)
            let assetTotal = (this.props.channels || {}).assetTotal || "";
            let noOfAssets = this.state.noOfAssets + channelsData.length;
            // let newlyCreatedSchedule = channelsData[channelsData.length - 1];
            let newlyCreatedSchedule = channelsData[0];
            let calendarId = newlyCreatedSchedule && newlyCreatedSchedule.channelcode
            //this.setState({ calendarId })
            // let body = { "activity": "fetch", calendarId }
            // this.props.manageScheduling(body);
            let firstChannel = channelsData && channelsData.length > 0 && channelsData[0];
            //  console.log("channelsData ", channelsData, calendarId)
            this.setState({
                schedulesData: channelsData, assetTotal, noOfAssets, isLoading: false, formData: firstChannel,
                availability: firstChannel && firstChannel.availability,
                // destinations: channelsData.destinations,
                id: firstChannel && firstChannel._id,
                serviceProvider: firstChannel && firstChannel.serviceprovider,
                calendarId,
                isMediatailorCreated: firstChannel && firstChannel.mediatailor,
                actionButton: false,
                
                //audio: audioDevicesData,
                // video: videoDevicesData,
                //playlistbyUsers: channelsData.playlistbyUsers, }, () => {
                // this.loadChannelsOnScroll();
            });
            // self.SuccessNotify();
           // window.location = '?channelCode=' + calendarId;
        }
    }
    // getPlaylist() {
    //     let self = this;
    //     // const {playListsData} = self.state
    //     axios.post(envoiSubmissionApiBaseUrl + '/PlayLists?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&public=true')

    //         .then((response) => {

    //             if (response && response.data.result) {
    //                 let playListsDataReturn = response.data.result;
    //                 let userId = localStorage.getItem("user_id");
    //                let playListsData = playListsDataReturn && playListsDataReturn.filter(playListsDataReturn => playListsDataReturn.uuid && playListsDataReturn.playlistname)
    //         self.setState({ playListsData: playListsData });
    //              console.log('playlistsdadsaar123456', playListsData)

    //                 //self.setState({ playListsassetsData: playListsData });
    //                 //self.setState({ userPlaylistData: response.data.result })
    //             }
    //         })
    //         .catch(error => error)
    // }
    // getPlaylistsassets(payload) {
    //     let self = this

    //     axios.post(envoiSubmissionApiBaseUrl + '/PlayLists?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&type=envoiPlaylistByUsers' + '&assetcount=' + self.state.perPage + '&pageNumber=' + self.state.pageNo + '&public=true'
    //         , payload)

    //         .then((response) => {

    //             if (response && response.data.result) {

    //                 //  playListsData = playListsData && playListsData.filter(playListsData => playListsData.uuid && playListsData.playlistname)


    //                 let playListsassetsData = (response.data.result || {}).data || [];

    //                 self.setState({ playListsassetsData: playListsassetsData, loader: false, assetLoader: false, assets: [] });
    //                 let assetTotal = (response.data.result || {}).assetTotal || 0;
    //                 if (response.data.result && assetTotal > 0) {
    //                     let totalPages = assetTotal / self.state.perPage;
    //                     totalPages = Math.ceil(totalPages)
    //                     let totalArray = [];
    //                     for (var i = 0; i < totalPages; i++) {
    //                         totalArray.push(i + 1)
    //                     }
    //                     this.setState({ totalPages: totalPages, totalPagesArray: totalArray, assetTotal, })
    //                 }
    //             }
    //         })
    //         .catch(error => error)
    // }
    _validate() {
        let self = this;
        let getFormData = { ...self.state.formvalues };
        var errors = {};
        let formIsVaild = true;

        let formdata = { ...self.state.getFormData,...self.state.formvalues };
        // console.log('formdatrata validateeee',formdata)
        let availability = formdata['availability'] || { ...self.state.availability } || {};
        let buyplans = availability.buyplans || {};
        if (!availability || ((!availability['free']) &&
            (!availability['buyplans']) &&
            (!availability['publicViewing']))
        ) {
            // self.setState({ custom: true, showMessage: 'Please select Availability Plan' })
            self.errorToast('Please select Availability Plan')
            formIsVaild = false;
        }

        if(buyplans && buyplans.allow == true &&
            ((buyplans.monthly && buyplans.monthly.contentPrice && buyplans.monthly.contentPrice.planPrice <= 0)||
             (buyplans.yearly && buyplans.yearly.contentPrice && buyplans.yearly.contentPrice.planPrice <= 0)||
             (buyplans.buy && buyplans.buy.allow == true && !buyplans.buy.contentPrice ))){
            self.errorToast('Please Enter Price')
            formIsVaild = false;
        }

        if (self.state.channelname == "" || self.state.channelname == undefined || self.state.channelname == "undefined") {
            // self.setState({ alert: true, alertMsg: 'Channel name is required' });
            self.errorToast('Channel name is required')
            formIsVaild = false;
        }
        // if (self.state.serviceprovider && self.state.serviceprovider == 'Envoi') {
        if (self.state.serviceprovider && self.state.serviceprovider == 'envoiPlaylistByUsers' && self.state.playlistbyUsers && self.state.playlistbyUsers.length == 0) {
            // self.setState({ alert: true, alertMsg: 'User playlist is required' });
            self.errorToast('User playlist is required')
            formIsVaild = false;
        }
        if (self.state.serviceprovider && self.state.serviceprovider == 'envoiPlaylistByUsers' && self.state.playListsassetsData && self.state.playListsassetsData.length == 0) {
            // self.setState({ alert: true, alertMsg: 'No assets in playlist' });
            self.errorToast('No assets in playlist')
            formIsVaild = false;
        }



        if (formIsVaild) {
            if (self.state.serviceprovider == "envoiPlaylistByUsers" && (self.state.playListsassetsData && self.state.playListsassetsData.length > 0)) {
                if (self.state.serviceprovider == 'awsMediaTailor' && self.state.status == 'Active') {
                    if (!self.state.mediaTailorChannelResponse) {
                        formIsVaild = false;
                        // this.setState({ isSubscription: true, status: 'Inactive', message: "Please create a channel in the Media Tailor tab and start the channel" })
                        this.setState({ status: 'Inactive', }, this.errorToast('Please create a channel in the Media Tailor tab and start the channel'))

                    } else {
                        formIsVaild = true;
                    }
                } else {
                    formIsVaild = true;
                }
            }
            else if (self.state.serviceprovider == "envoiPlaylistByUsers" && self.state.status == 'Active') {
                // this.setState({ isSubscription: true, status: 'Inactive', message: "Please add assets to channel" })
                this.setState({ status: 'Inactive' })
                this.errorToast('Please add assets to channel')
                formIsVaild = false;
            }
        }
        this.setState({ errors })
        return formIsVaild;
    }
    SuccessNotify = () => toast.success('Updated Successfully', window.app.toastConfig,
    )
    errorToast = (msg) => toast.error(msg, window.app.toastConfig)
    onKeyDown(event) {
        let self = this;
        let channelTags = self.state.channelTags || [];
        let getFormData = { ...self.state.formvalues };
        if (event.key === 'Enter') {
            channelTags.push(self.state.tagValue)
            channelTags = channelTags.filter((item,
                index) => channelTags.indexOf(item) === index)
            getFormData["channelTags"] = channelTags;
            this.tagsRef.current.typeahead.clear()
            self.setState({ tagValue: "" }, () => {
                self.setState({ getFormData, channelTags })
            });
        }
    }
    handleInputChange(e) {
        let self = this;
        self.setState({ tagValue: e })
    }
    // onChangeForm(e, type) {
    //     let self = this;
    //     let formvalues = { ...self.state.formvalues };
    //     this.setState({ executing: false })
    //     switch (type) {
    //         case 'Tags':
    //             if (e && e.length > 0) {
    //                 let channelTags = []
    //                 e && e.map((item) => {
    //                     let name = item && item.name ? item.name : item;
    //                     channelTags.push(name)
    //                     channelTags = channelTags.filter((item,
    //                         index) => channelTags.indexOf(item) === index)
    //                 })
    //                 formvalues["channelTags"] = channelTags;
    //                 self.setState({ formvalues, channelTags });
    //             } else {
    //                 formvalues["channelTags"] = [];
    //                 self.setState({ formvalues, channelTags: [] });
    //             }
    //             break;
    //     }
    // }
    // copyToClipboard = (promourl) => {
    //     const el = document.createElement("input");
    //     el.value = promourl;
    //     el.id = "url-input";
    //     document.body.appendChild(el);
    //     el.select();
    //     document.execCommand("copy");
    //     el.remove();
    // };

    // async init() {
    //     try {
    //         await this.createClient();
    //     } catch (err) {
    //         this.setError(err.message);
    //     }
    // }

    // setError(message) {
    //     if (Array.isArray(message)) {
    //         message = message.join("<br/>");
    //     }
    //     const errorEl = document.getElementById("error");
    //     // errorEl.innerHTML = message;
    // }

    // async createClient() {
    //     await this.getDevices();
    // }
    // // Handle audio/video device enumeration
    // async getDevices() {
    //     const devices = await navigator.mediaDevices.enumerateDevices();
    //     const videoDevices = devices.filter((d) => d.kind === "videoinput");
    //     if (!videoDevices.length) {
    //         this.setError("No video devices found.");
    //     }
    //     const audioDevices = devices.filter((d) => d.kind === "audioinput");
    //     if (!audioDevices.length) {
    //         this.setError("No audio devices found.");
    //     }

    //     this.setState({ videoDevices, audioDevices })

    //     return { videoDevices, audioDevices };
    // }
    // searchForAssets() {
    //     var self = this;
    //     const { playlistbyUsers } = this.state;
    //     let userIds = []
    //     playlistbyUsers && playlistbyUsers.map((user) => { userIds.push(user.uuid) });
    //     let payload = { playListId: userIds, isActive: true }
    //     if (userIds.length > 0) {
    //         self.setState({ assetLoader: true })
    //        // self.getPlaylistsassets(payload);
    //     }
    // }

    backToChannels = () => {
        // browserHistory.push('/home');
        window.location = '/scheduler'
    }
    // handleChange(e) {
    //     var self = this;
    //     let formvalues = { ...self.state.formvalues };
    //     // console.log('formvalues handle change', formvalues)
    //     const name = e.target.name;
    //     let value = e.target.value;
    //     if (!!this.state.errors[name]) {
    //         let errors = Object.assign({}, this.state.errors);
    //         formvalues[name] = value;
    //         delete errors[name];
    //         this.setState({ [name]: value, errors, formvalues });
    //     } else {
    //         this.setState({ [name]: value, executing: false });
    //         formvalues[name] = value;
    //         this.setState({ formvalues })
    //     }
    // }
    uploadS3(e, type) {
        var self = this;
        var fileData = new FormData();
        var file = e.target.files[0];
        var uploadFilePath = "";
        var filename = e.target.files[0].name;
        var s3file = e.target.files[0];
        var filekey = type[2];
        fileData.append(type[1], s3file);
        self.setState({ fileData: file, type, filekey })
        let appName = window.app.appName;
        if (self.state.formData && self.state.formData.channelname && !self.state.formData.channelname == "" || !self.state.formData.channelname == undefined || !self.state.formData.channelname == null) {
            var bucket;
            var imgHight;
            var imgWidth;

            if (type[1] == "Image") {
                if (type[0] == "1000*1294") {
                    imgHight = 1294;
                    imgWidth = 1000;
                } else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "3000*600") {
                    imgHight = 600;
                    imgWidth = 3000;
                }
                else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "175") {
                    //imgHight = 1080;
                    imgWidth = 610;
                }
                let height;
                let width;
                bucket = imageSourcebucket;
                var reader = new FileReader();
                reader.readAsDataURL(s3file);
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        height = this.height;
                        width = this.width;
                        let imagePathWidth;
                        if (imgWidth == "610") {
                            imagePathWidth = width <= imgWidth
                        } else {
                            imagePathWidth = width == imgWidth && height == imgHight
                        }
                        if (imagePathWidth) {
                            bucket = imageSourcebucket;
                            // if (width == imgWidth && height == imgHight) {
                            var path = "submission/partner";
                            let id = self.state.formData && self.state.formData._id;
                            let name = self.state.formData.channelname;
                            name = name && name.toLowerCase();
                            name = name && name.replace(/ /g, "-");
                            name = name && name.replace(/[^a-zA-Z 0-9]/g, "");
                            let folder = name;
                            uploadFilePath = appName + "/" + path + "/" + folder + "/" + removeSpecialCharecters(filename);
                            var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                            self.setState({ uploadFilePath })
                            let siteSettings = window.site;
                            let siteConfig = siteSettings.config;
                            let imagePath = siteConfig && siteConfig.appResourcesUrl;
                            axios.post(envoiSubmissionApiBaseUrl + '/uploadURL?appname=' + appName + '&token=' + token, data, { type: 'application/json' })
                                .then((response) => {
                                    if (response.data && response.data.result) {
                                        var url = response.data.result;
                                        let config = {
                                            onUploadProgress: function (progressEvent) {
                                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                                self.setState({ [type[3]]: percentCompleted });
                                            }
                                        };
                                        axios.put(url, file, config, {
                                            "headers": {
                                                "Content-Type": "multipart/form-data",
                                                "Accept": "/",
                                                "Cache-Control": "no-cache",
                                                "Accept-Encoding": "gzip, deflate",
                                                "Connection": "keep-alive",
                                                "cache-control": "no-cache"
                                            }
                                        })
                                            .then((response) => {
                                                if (response && response.status === 200) {
                                                    var filename = self.state.uploadFilePath;
                                                    let imageUploadPath = imagePath + filename;

                                                    if (imageUploadPath.includes(appName + "/" + appName)) {
                                                        imageUploadPath = imageUploadPath.replace(appName + "/" + appName, appName);
                                                    }
                                                    self.SuccessNotify();
                                                    let obj = {
                                                        [self.state.filekey]: imageUploadPath
                                                    }
                                                    // console.log('obj', obj.landscape_logo_image)

                                                    if (id) {
                                                        // console.log('id', id)
                                                        self.props.manageChannels(obj, id);
                                                    } else {
                                                        // console.log('id123123', self.state.formData)
                                                        self.state.formData["landscape_logo_image"] = obj.landscape_logo_image || siteConfig.defaultLandscape;
                                                        //self.props.manageChannels(obj);
                                                    }
                                                    //  self.setState(obj);
                                                }
                                            })
                                            .catch((err) => {
                                                console.error.bind(err);
                                            })
                                    }
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })
                            // } else {
                            //     self.setState({ addMetadata: true, alertMsg: 'Please select image(' + type[0] + ')' });
                            // }
                        } else {
                            let showAlertMsg = 'Please select image(' + type[0] + ')'
                            // self.errorToast(showAlertMsg);
                            self.setState({ addMetadata: true, alertMsg: 'Please select image(' + type[0] + ')' });
                        }
                    };
                }
            } else {
                bucket = assetSourcebucket;

            }
        } else {
            self.setState({ addMetadata: true, alertMsg: '"Please Enter (Title required)' });
        }
    }

    
    onChangeStatus(e) {
        var self = this;
        let catalogvalues = { ...self.state.formData };
        const name = e.target.name;
        let value = e.target.value;
        let availability = self.state.availability;
        let isAvailability = (availability && (availability.publicViewing || availability.free && availability.free.allow || availability.buyplans && availability.buyplans.allow));
        let theme = self.state.theme || {};
        let availabilityTheme = (theme.metadata || {}).availability || {};
        let accessibilityTheme = availabilityTheme.accessibility || {}
        if ((availabilityTheme.display != undefined && !availabilityTheme.display) || (availabilityTheme.enable != undefined && !availabilityTheme.enable) || (accessibilityTheme.display != undefined && !accessibilityTheme.display) || (accessibilityTheme.enable != undefined && !accessibilityTheme.enable)) {
            isAvailability = true;
            catalogvalues['availability'] = {
                free: { allow: true, viewsLimit: 1, deviceLimit: 1, contentType: { hd: true } },
                publicViewing: true
            }
        }
        if (name == 'status') {
            if (value != 'Active' || (value == 'Active' && isAvailability)) {
                if (!!this.state.errors[name]) {
                    let errors = Object.assign({}, this.state.errors);
                    catalogvalues[name] = value;
                    delete errors[name];
                    this.setState({ [name]: value, errors, formData:catalogvalues });
                } else {
                    catalogvalues[name] = value;
                    this.setState({ formData:catalogvalues, [name]: value })
                }
            } else {
                this.errorToast('Please select subcription plan in availability tab');
                // this.setState({ isSubscription: true, message: "Please select subcription plan in availability tab" })
            }
        }
    }
    validate() {
        let self = this;
        let isError = true;
        let errors = {};
        
        let formData = { ...self.state.formData };
        // console.log('formdatrata validateeee',formdata)
        let availability = formData['availability'] || { ...self.state.availability } || {};
        let buyplans = availability.buyplans || {};
        if (!availability || ((!availability['free']) &&
            (!availability['buyplans']) &&
            (!availability['publicViewing']))
        ) {
            // self.setState({ custom: true, showMessage: 'Please select Availability Plan' })
            self.errorToast('Please select Availability Plan')
            //self.setState({ submitText: false, executing: false })
            isError = false;
        }

        if(buyplans && buyplans.allow == true &&
            ((buyplans.monthly && buyplans.monthly.contentPrice && buyplans.monthly.contentPrice.planPrice <= 0)||
             (buyplans.yearly && buyplans.yearly.contentPrice && buyplans.yearly.contentPrice.planPrice <= 0)||
             (buyplans.buy && buyplans.buy.allow == true && !buyplans.buy.contentPrice ))){
            self.errorToast('Please Enter Price')
            isError = false;
        }
        if (formData && formData.average_ad_duration == undefined || formData.average_ad_duration == '') {
            isError = false;
            errors.average_ad_duration = "Average Ad Duration Required";
        }
        if (formData && formData.channelname == undefined || formData.channelname == '') {
            isError = false;
            errors.channelname = "Name Required";
        }
        if (formData && formData.status == undefined || formData.status == '') {
            isError = false;
            errors.status = "Status Required";
        }
        if (formData && formData.type == undefined || formData.type == '') {
            isError = false;
            errors.type = "Channel Type Required";
        }
        // if (formData && formData.VodSourceName == '' || formData && formData.VodSourceName == undefined) {
        //     isError = false;
        //     errors.VodSourceName = "Enter VodSourceName";
        // }
        // if (formData && formData.SourceLocationName == '' || formData && formData.SourceLocationName == undefined) {
        //     isError = false;
        //     errors.SourceLocationName = "Enter SourceLocationName";
        // }
        this.setState({ errors });
      //  self.setState({ submitText: false, executing: false })
        return isError;
    }
    Save(e) {
        let self = this;
        //e.stopImmediatePropagation()
        let formData = { ...self.state.formData }
        
        self.setState({ isLoader: true});
        if (this.validate()) {
            let body;
            if (formData && formData._id) {
                formData["activity"] = "update";
                // formData["scheduleId"] = this.state.calendarId;
                // console.log('formData', formData)
                // // body = {
                // //     title: this.state.calendarName,
                // //     description: this.state.description || '',
                // //     landscape_logo_image: this.state.landscape_logo_image || siteConfig.defaultLandscape,
                // //     portrait_logo_image: this.state.portrait_logo_image || siteConfig.defaultPortrait,
                // //     logoImage: this.state.logoImage || siteSettings.logoImage,
                // //     activity: 'update'
                // // }
                // this.props.manageSchedules(formData);
                let id = formData._id;
                delete formData._id;
                //getFormData = self.state.getFormData
                // getFormData["channelname"] = self.state.channelname;
                // getFormData["serviceprovider"] = 'envoiPlaylistByUsers';
                formData["appname"] = window.app.appName;
                formData["parentAppName"] = window.app.appName;
                //formData['verticalMode'] = formData.verticalMode && formData.verticalMode == 'true' ? "On" : "Off";
                //console.log('formData123', formData)
                // console.log('else condition')
                self.props.manageChannels(formData, id);
                
                // self.setState({ custom: true, showMessage: "Updated Successfully" });

            } else {
                //self.setState({ submitText: true, executing: true })
                // formData["portrait_logo_image"] = formData.portrait_logo_image || siteConfig.defaultPortrait;
                // formData["landscape_logo_image"] = formData.landscape_logo_image || siteConfig.defaultLandscape;
                // formData["logoImage"] = formData.logoImage || siteSettings.logoImage;
                formData["activity"] = "create";
                // // body = {
                // //     title: this.state.calendarName,
                // //     description: this.state.description || '',
                // //     landscape_logo_image: formData.landscape_logo_image || siteConfig.defaultLandscape,
                // //     portrait_logo_image: formData.portrait_logo_image || siteConfig.defaultPortrait,
                // //     logoImage: formData.logoImage || siteSettings.logoImage,
                // //     activity: 'create'
                // // }
                // this.props.manageSchedules(formData);
                let siteSettings = window.site || {};
                let siteConfig = siteSettings.config || {};
                let email = window.localStorage.getItem("email");
                let firstname = window.localStorage.getItem("firstname");
                let lastname = window.localStorage.getItem("lastname");
                let userid = window.localStorage.getItem("user_id");
                let name = firstname + " " + lastname;
                formData["serviceprovider"] = "envoiPlaylistByUsers";
                formData["portrait_logo_image"] = formData.portrait_logo_image || siteConfig.defaultPortrait;
                formData["landscape_logo_image"] = formData.landscape_logo_image || siteConfig.defaultLandscape;
                formData["logoImage"] = formData.logoImage || siteSettings.logoImage;
                formData["appname"] = window.app.appName;
                formData["parentAppName"] = window.app.appName;
                formData["status"] = formData.status;
                formData["quickbooksProductType"] = "NonInventory";
                formData["contact"] = { email };
                formData["owner"] = { code: userid, name: name };
                formData['verticalMode'] = formData.verticalMode
              //  console.log('formData', formData)
                self.props.manageChannels(formData);
                // self.props.manageChannels(getFormData, self.state.id);
               // self.SuccessNotify();

                // self.setState({ custom: true, showMessage: "Updated Successfully" });

            }

            this.setState({ isAddScheduleOpen: false, })
        }
        
    }

    deleteImageFiles(e, item, type) {
        var self = this;
        let selectedType = { "imageType": type }
        this.setState({ assetFile: true, deleteItem: item, selectedType });
    }
    onCancelDelete() {
        this.setState({ assetFile: false, });
    }
    onConfirmDelete() {
        var self = this;
        let imageType = this.state.selectedType && this.state.selectedType.imageType ? this.state.selectedType.imageType : 'none'
        let siteSettings = window.site;
        let siteConfig = siteSettings.config || {};
        let id = self.state.formData && self.state.formData._id;
        // if (self.state.deleteItem && self.state.deleteItem == "landscape_logo_image") {
        //     self.setState({ landscape_logo_image: undefined, })
        //     toast.success('Deleted Successfully', window.app.toastConfig)
        // } else if (self.state.deleteItem && self.state.deleteItem == "portrait_logo_image") {
        //     self.setState({ portrait_logo_image: undefined })
        //     toast.success('Deleted Successfully', window.app.toastConfig)
        // }
        // else {
        //     self.setState({
        //         logoImage: '',
        //     })
        //     toast.success('Deleted Successfully', window.app.toastConfig)

        // }
        let formData = {};
        if (self.state.deleteItem && self.state.deleteItem == "landscape_logo_image") {
            formData["landscape_logo_image"] = siteConfig.defaultLandscape + siteConfig.imageSuffix;
            self.setState({
                custom: true, isAddScheduleOpen: false, showMessage: "Deleted Successfully"
            })
            //toast.success('Deleted Successfully', window.app.toastConfig)
        } else if (self.state.deleteItem && self.state.deleteItem == "portrait_logo_image") {
            formData["portrait_logo_image"] = siteConfig.defaultPortrait + siteConfig.imageSuffix;
            self.setState({
                custom: true, isAddScheduleOpen: false, showMessage: "Deleted Successfully"
            })
            // toast.success('Deleted Successfully', window.app.toastConfig)
        }
        else {
            formData["logoImage"] = '';
            self.setState({
                custom: true, isAddScheduleOpen: false, showMessage: "Deleted Successfully"
            })
            //toast.success('Deleted Successfully', window.app.toastConfig)
        }
        self.setState({
            assetFile: false,
            [imageType]: 0
        });
        self.props.manageChannels(formData, id);
    }
    // publishToggle(e,value){
    //     let self = this;
    //     let name = 'status';
    //     let getFormData = { ...self.state.formvalues ,...self.state.getFormData};
    //     let payload = {}
    //     let id = self.state.getFormData && self.state.getFormData._id
    //     let availability = self.state.availability;
    //     let isAvailability = (availability && (availability.publicViewing || availability.free && availability.free.allow || availability.buyplans && availability.buyplans.allow));
     
    //         if (value != 'Active' || (value == 'Active' && isAvailability)) {
    //             if (!!this.state.errors[name]) {
    //                 let errors = Object.assign({}, this.state.errors);
    //                 getFormData[name] = value;
    //                 delete errors[name];
    //                 this.setState({ [name]: value, errors, getFormData });
    //             } else {
    //                 getFormData[name] = value;
    //                 payload[name] = value;
    //                 this.setState({ getFormData, [name]: value })
    //                 self.props.manageChannels(payload, id);
    //             }
    //         } else {
    //             this.errorToast('Please select subcription plan in availability tab');
    //         }
        
    // }
    // publishClick(e, item) {
    //     let self = this;
    //     let id = item && item._id
    //     let getFormData = {};
    //     getFormData["status"] = "Active";
    //     self.props.manageChannels(getFormData, id);
    // }
    // unPublishClick(e, item) {
    //     let self = this;
    //     let id = item && item._id
    //     let getFormData = {...self.state.formvalues, ...self.state.getFormData}
    //     let validStatus = true
    //     getFormData["status"] = "Inactive";
    //     self.props.manageChannels(getFormData, id);
    // }
    handleAvailability(data) {
        let self = this;
        //self.setState(data);
        // console.log("data", data)
        let availability = data.availability ? data.availability : self.state.availability;
        let formData = { ...self.state.formData, ...data };
        // console.log('handleAvailability in channel',getFormData)
        let preview = formData.preview ? formData.preview : self.state.preview;
        self.setState({ formData, availability, executing: false, preview });
    }
    // handleShare(data) {
    //     let self = this;
    //     //self.setState(data);
    //     // console.log("data", data)
    //     //let availability = data.availability ? data.availability : self.state.availability;
    //     let getFormData = { ...self.state.formvalues, ...data };
    //     //let preview = formvalues.preview ? formvalues.preview : self.state.preview;
    //     self.setState({ getFormData, });
    // }
    onClickMenu(id) {
        let self = this;
        self.setState({ isActiveId: id })
    }
    // onChangeMultiselect(e, type) {
    //     var self = this;
    //     var formvalues = { ...self.state.formvalues };
    //     switch (type) {
    //         case type:
    //             formvalues["playlistbyUsers"] = e;
    //             this.setState({ playlistbyUsers: e, formvalues, executing: false })
    //             if (e && e.length == 0) {
    //                 this.setState({ playListsassetsData: [] })
    //             }
    //             break;

    //     }
    // }

    onChangeCalendarName(e) {
        let self = this;
        let catalogvalues = { ...self.state.formData };
        let name = e.target.name;
        let value = e.target.value;
        // console.log('value',value,name)

        // console.log('value aftewr==> ',k)
        if (name == undefined) {
            const time = e;
            let startAt = time ? time.format('HH:mm:ss') : null;
            let durationStart = moment.duration(startAt)
            durationStart = durationStart._milliseconds / 1000;
            catalogvalues['average_ad_duration'] = durationStart
            this.setState({ ['average_ad_duration']: durationStart, formData: catalogvalues });
        } else {
            let errors = Object.assign({}, this.state.errors);
            delete errors[name];
            if (name == 'type') {
                let playbackModeVal = "";
                if (value == 'STANDARD') {
                    playbackModeVal = "LINEAR";
                }
                catalogvalues[name] = value;
                catalogvalues["playbackmode"] = playbackModeVal;
                this.setState({ ["playbackmode"]: playbackModeVal, [name]: value });
                this.setState({ formData: catalogvalues })

            }
             else {
                this.setState({ actionButton: true });
            }
            catalogvalues[name] = value;
            this.setState({ [name]: value, formData: catalogvalues, errors });
            
        }
    }

    // handleChangePages(e) {
    //     let self = this;
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     self.setState({ [name]: value });
    //     self.handlePaginationData(name, value);
    // }

    // handlePaginationData(name, value) {
    //     let self = this;
    //     let pageNo, perPage;
    //     if (name == 'pageNo') {
    //         pageNo = value;
    //         perPage = self.state.perPage
    //     } else if (name == 'perPage') {
    //         pageNo = self.state.pageNo;
    //         perPage = value
    //     } else {
    //         pageNo = name;
    //         perPage = value
    //     }

    //     if (this.state.assetTotal) {
    //         if (perPage >= this.state.assetTotal) {
    //             if (pageNo != 1) {
    //                 pageNo = 1
    //             }
    //         }
    //     }
    //     if (self.state.serviceprovider != 'awsInteractiveVideo') {

    //         const { playlistbyUsers } = this.state
    //         let userIds = []
    //         self.setState({ loader: true })
    //         playlistbyUsers && playlistbyUsers.map((user) => { userIds.push(user.uuid) });
    //         let formdata = { playListId: userIds, isActive: true }
    //         if (userIds.length > 0) {
    //             this.setState({ pageNo: pageNo, perPage: perPage, loader: true }, () => self.getPlaylistsassets(formdata));

    //         }
    //     }
    //     this.setState({ pageNo: pageNo, perPage: perPage, loader: true });

    // }

    // createMediatailorChannel() {
    //     let assetsData = this.props.manageSchedulingData || [];
    //     if (this.state.channelCode && assetsData.length > 0) {
    //         let payload = {
    //             channelCode: this.state.channelCode,
    //             assetId: assetsData[0].assetid,
    //         }
    //         this.props.createMediatailorChannel(payload)
    //     }
    // }

    render() {
        let self = this;
        const { userName, availabilityPopup, isActiveId, markerData } = self.state;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let logoImage = siteSettings && siteSettings.logoImage;
        //  console.log('self.state.getFormData', self.state.formData.playbackmode)
        // console.log('self.state.formvalues', self.state.formvalues)

        return (
            <React.Fragment>
                <div className="edit-channel">
                    <header className="fixed-top live-header">
                        <div className="container-fluid">
                            <div className="sec-header"><a href="javascript:void(0)" onClick={() => self.backToChannels()}><span className="material-symbols-outlined"> arrow_back </span></a>
                                <h3>{self.state.formData && self.state.formData.channelname}</h3>
                                {/* {self.state.getFormData && self.state.getFormData.status == "Active" ?
                                    // <a className="publish-btn selected" onClick={e => self.unPublishClick(e, self.state.getFormData)}>
                                     <a className="publish-btn selected" onClick={e => self.publishToggle(e, 'Inactive')}>
                                    <span className="material-symbols-outlined"> cloud_upload </span><span className="unpubtxt">Unpublish</span>
                                    </a> :
                                    //  <a className="publish-btn selected" onClick={e => self.publishClick(e, self.state.getFormData)}>
                                    <a className="publish-btn selected" onClick={e => self.publishToggle(e, 'Active')}>

                                        <span className="material-symbols-outlined"> cloud_upload </span><span className="pubtxt"></span>
                                    </a>
                                } */}
                            </div>
                        </div>
                    </header>
                    <div className="envlive_wrapper minbody-ht">
                        <div className="dashboard_panel">
                            <div className='panel_menu'>
                                    <ul>
                                        {userMenuList.map(eachItem => (
                                            <li key={eachItem.id}><a href='#' className={`material-icons ${eachItem.id === isActiveId ? 'active' : ''}`} onClick={() => self.onClickMenu(eachItem.id)} ref={ref => this.fooRef = ref} data-tip={eachItem.labelName}>{eachItem.name}</a>   <ReactTooltip /></li>
                                        ))}
                                        {/* <p ref={ref => this.fooRef = ref} data-tip='tooltip'>hellloooooo</p> */}

                                    </ul>
                            </div>
                           
                            {isActiveId === '1' &&
                                <div className='panel_content'>

                                    <div className='row m-0 about-channel'>
                                        <div className='col-md-7 p-0'>
                                            <div className='form_section'>
                                                <h4 className='mb-3'>About Channel</h4>
                                                <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Title</p>
                                                    <input type="text" placeholder='Enter Title' label='channel-title' name="channelname" value={self.state.formData && self.state.formData.channelname} onChange={e => self.onChangeCalendarName(e)} />
                                                    <span className="errormsg">{this.state.errors.channelname}</span>
                                                </div>
                                                <div className="inputgroup">
                                                    <p className='label'>Status</p>
                                                    <select className="custom-select form-control" name="status" value={self.state.formData && self.state.formData.status} onChange={e => self.onChangeStatus(e)}>
                                                        <option value="">Select</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                    <span className="errormsg">{this.state.errors.status}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className='label'>Channel Type</p>
                                                    <select className="custom-select form-control" name="type" value={self.state.formData && this.state.formData.type} onChange={e => this.onChangeCalendarName(e)}>
                                                        <option value=" ">Select</option>
                                                        <option value="BASIC">Basic</option>
                                                        <option value="STANDARD">Standard</option>
                                                    </select>
                                                    <span className="errormsg">{this.state.errors.type}</span>
                                                </div>

                                                <div className='inputgroup'>
                                                    <p className='label'>Average Ad Duration</p>
                                                    <input type="text" placeholder='Average Ad Duration' name="average_ad_duration" value={self.state.formData && self.state.formData.average_ad_duration} onChange={e => self.onChangeCalendarName(e)} />
                                                    <span className="errormsg">{this.state.errors.average_ad_duration}</span>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Description</p>
                                                    <textarea className='channel_description' rows={"4"} placeholder="Description" name="description" value={self.state.formData && self.state.formData.description} onChange={e => self.onChangeCalendarName(e)}></textarea>
                                                </div>
                                            </div>
                                            {
                                                self.state.formData && self.state.formData.type &&
                                                <div className="col-md-12">
                                                    <div className="inputgroup">
                                                        <p className='label'>Channel Duration</p>
                                                        <select className="form-control u-form-control" name="duration" value={self.state.formData.duration} onChange={e => self.onChangeCalendarName(e)}>
                                                            <option value="">Select</option>
                                                            <option value="60">1 hour</option>
                                                            <option value="240">4 hours</option>
                                                            <option value="480">8 hours</option>
                                                            <option value="1440">24 hours</option>
                                                            <option value="4320">3 days</option>
                                                            <option value="10080">7 days</option>
                                                            <option value="20160">14 days</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                self.state.formData && self.state.formData.type &&
                                                <div className="col-md-12">
                                                    <div className="inputgroup">
                                                        <p className='label'>Playback mode</p>
                                                        <p>Determine's the channel playback behaviour</p>
                                                        <select className="form-control u-form-control" name="playbackmode" value={self.state.formData.playbackmode} defaultValue={self.state.formData.playbackmode} disabled={self.state.formData.type == 'STANDARD'} onChange={e => self.onChangeCalendarName(e)}>
                                                        {self.state.formData.type == 'BASIC' && <option value="">Select</option>}

                                                            
                                                            {
                                                                self.state.playbackmodeObj[self.state.formData.type].map((item)=>
                                                                 {
                                                                    //console.log('item',item)
                                                                    return(<option value={item} >{item}</option>)})
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                self.state.formData && self.state.formData.playbackmode == "LINEAR" &&
                                            <div className='row'>
                                            <h4 className='mb-3'>Filer Slate</h4>
                                            {
                                                self.state.formData && self.state.formData.playbackmode == "LINEAR" &&
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Source Location Name</p>
                                                    <select id="SourceLocationName" name="SourceLocationName" value={self.state.formData.SourceLocationName} onChange={e => self.onChangeCalendarName(e)}>
                                                        <option value="">Select</option>
                                                        {this.props.sourceLocationData && [this.props.sourceLocationData].length > 0 && [this.props.sourceLocationData].map((item, index) => { return (<option key={index} value={item}>{item}</option>) })}
                                                    </select>
                                                    {/* <span className="errormsg">{this.state.errors.SourceLocationName}</span> */}
                                                </div>

                                            </div>}
                                            {
                                                self.state.formData && self.state.formData.playbackmode == "LINEAR" &&
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Vod Source Name</p>
                                                    {/* <input type="text" name="VodSourceName" value={markerData.VodSourceName} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <select id="VodSourceName" name="VodSourceName" value={self.state.formData.VodSourceName} onChange={e => self.onChangeCalendarName(e)}>
                                                        <option value="">Select</option>
                                                        {this.state.vodSourcesData && this.state.vodSourcesData.length > 0 && this.state.vodSourcesData.map((item, index) => {
                                                            return (<option key={index} value={item.VodSourceName}>{item.assetname || item.VodSourceName}</option>)
                                                        })}


                                                    </select>
                                                    {/* <span className="errormsg">{this.state.errors.VodSourceName}</span> */}
                                                </div>

                                            </div>}
                                            </div>}
                                            <div className='col-md-12'>
                                                <h3 className='sub_title'>Thumbnail</h3>
                                                <div className='uploadPics'>
                                                    <div className='upload'>
                                                        <div className="img_block">
                                                            <img src={self.state.formData && self.state.formData.landscape_logo_image ? self.state.formData.landscape_logo_image + siteConfig.imageSuffix : siteConfig.defaultLandscape + siteConfig.imageSuffix} />
                                                            <div className="upload_btns">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="landscape_logo_image" className="udisplay-none" onChange={self.state.landscape_logo_image ? e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"]) : e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.landscape_logo_image ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "landscape_logo_image", "landscapeLogoProgress")}>delete</span> </a> : null}
                                                            </div>
                                                        </div>
                                                        <div className="size">Landscape  (1920*1080)</div>


                                                    </div>
                                                    <div className='upload portrait'>
                                                        <div className="img_block u-potrait">
                                                            <img className="portait_img" src={self.state.formData && self.state.formData.portrait_logo_image ? self.state.formData.portrait_logo_image + siteConfig.imageSuffix : siteConfig.defaultPortrait + siteConfig.imageSuffix} />
                                                            <div className="upload_btns">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="portrait_logo_image" className="udisplay-none " onChange={self.state.portrait_logo_image ? e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"]) : e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.portrait_logo_image ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "portrait_logo_image", "portraitLogoProgress")}>delete</span></a> : null}
                                                            </div>
                                                        </div>
                                                        <div className="size">Portrait (1000*1294)</div>
                                                    </div>
                                                    <div className='upload up-logo'>
                                                        <div className="img_block u-potrait u-bg-black">
                                                            <img className="new-user-logo-u" src={self.state.formData && self.state.formData.logoImage ? self.state.formData.logoImage + siteConfig.imageSuffix : siteSettings.logoImage + siteConfig.imageSuffix} />
                                                            <div className="upload_btns pt-15">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="logoImage" className="udisplay-none " onChange={self.state.logoImage ? e => self.uploadS3(e, ["175", "Image", "logoImage", "logoImageProgress"]) : e => self.uploadS3(e, ["175", "Image", "logoImage", "logoImageProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.logoImage ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "logoImage", "logoImageProgress")}>delete</span><i  ></i></a> : null}

                                                            </div>
                                                        </div>
                                                        <div className="size">Logo</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>









                                            </div>
                                        </div>
                                        {/* <div className='col-md-5 p-0'>

                                            <div className='form_section'>

                                                {self.state.serviceProvider != "awsInteractiveVideo" &&
                                                    <React.Fragment>
                                                        <div className="channel-playlist">
                                                            <div className="form-group u-select-het">
                                                                <h3 className="card-heading">Playlist</h3>
                                                                <div className='playlist_search'>
                                                                {self.state.playListsData && <SelectionsExample options={self.state.playListsData && self.state.playListsData} onChange={this.onChangeMultiselect} selected={self.state.playlistbyUsers && self.state.playlistbyUsers} placeholder='Playlist' labelKey="playlistname" />}
                                                                    <div className="channel-assets-button">
                                                                        {self.state.assetLoader ? <button className="jr-btn btn btn-primary"><i className="zmdi zmdi-rotate-right zmdi-hc-spin"></i></button> :
                                                                            <button type="button" disabled={self.state.playlistbyUsers && self.state.playlistbyUsers.length == 0} style={{ padding: 6, marginTop: 4 }} className="jr-btn btn btn-primary" onClick={e => self.searchForAssets()}>Search</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </React.Fragment>}
                                                {self.state.serviceProvider != 'awsInteractiveVideo' ? <div className=" form-group playlist-search">
                                                  
                                                    <div className="row fldr">
                                                        {self.state.loader ?
                                                            <div className="loader" style={{ marginLeft: '500px', height: '70%' }}>
                                                                <svg className="circular" viewBox="25 25 50 50">
                                                                    <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
                                                                </svg>
                                                            </div>
                                                            :
                                                            !self.state.loader && self.state.playListsassetsData && self.state.playListsassetsData.length > 0 &&
                                                            <div className="col-md-12">
                                                                <div className="up_items">
                                                                    {self.state.playListsassetsData && self.state.playListsassetsData.map((item, i) => {
                                                                        return (
                                                                            item && <div className="u-playlist-sec" id={i} key={i}>
                                                                                <div className="u-img-blk"><img className="card-img" src={item && item.landscape_logo_image ? item.landscape_logo_image + siteConfig.imageSuffix : siteConfig.defaultLandscape + siteConfig.imageSuffix} /></div>

                                                                                <div className="u-cont-blk"><h3 className="card-title">
                                                                                    <a href="javascript:void(0)" title={item && item.assetname}>{item && item.assetname}</a></h3>
                                                                                 
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                                {self.state.assetTotal > self.state.perPageConst ?
                                                                    <div className="pagenation">


                                                                        <div className="pagenation_prv"><button disabled={self.state.pageNo == 1} onClick={e => self.handlePaginationData((parseInt(self.state.pageNo) - 1), self.state.perPage)} className="jr-btn btn-white btn btn-default btn-block">Previous</button></div>
                                                                        <div className="pagenation_page"> <label>Page :</label>
                                                                            <select className="form-control custom-select u-w60" value={self.state.pageNo} name="pageNo" onChange={e => self.handleChangePages(e)}>
                                                                                {self.state.totalPagesArray && self.state.totalPagesArray.map(function (item, i) {
                                                                                    return (
                                                                                        <option key={i} value={item}>{item}</option>
                                                                                    )
                                                                                })}

                                                                            </select>
                                                                            <label><strong> of {self.state.totalPages}</strong></label>
                                                                        </div>
                                                                        <div className="pagenation_count">
                                                                            <label>{(self.state.pageNo * self.state.perPage) >= self.state.assetTotal ? self.state.assetTotal : (self.state.pageNo * self.state.perPage)} of {self.state.assetTotal}</label>
                                                                        </div>
                                                                        <div className="pagenation_perpage">
                                                                            <label>Per Page:</label>
                                                                            <select className="form-control custom-select u-w60" name="perPage" value={self.state.perPage} onChange={e => self.handleChangePages(e)} >
                                                                                <option>{self.state.perPage}</option>
                                                                                <option value="4">4</option>
                                                                                <option value="6">6</option>
                                                                                <option value="8">8</option>
                                                                                <option value="10">10</option>
                                                                                <option value="12">12</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="pagenation_next"><button disabled={self.state.pageNo == self.state.totalPages} onClick={e => self.handlePaginationData(parseInt((self.state.pageNo) + 1), self.state.perPage)} className="jr-btn btn-white btn btn-default btn-block">Next</button></div>



                                                                    </div> : <p>Total Assets : {self.state.assetTotal}</p>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div> : null}
                                            </div>
                                        </div> */}

                                        {/* <MiniPlayer getFormData={self.state.getFormData}/> */}
                                        {/* <div className='col-md-3'>
                                            <div className='video_preview'>
                                                <div className='video' onClick={() => self.playerClick(self.state.getFormData, "playMovie")} id="playMovie">
                                                    <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a>
                                                    {!popupPlayer &&
                                                        <img src='https://vlive.imgix.net/screening/images/landscape_logo.jpg?auto=compress,format' />}
                                                </div>
                                                <div className='video_info'> */}
                                        {/* {self.state.getFormData && self.state.getFormData.created && <div className='date'>Created: {self.state.getFormData.created}</div>} */}
                                        {/* {self.state.getFormData && self.state.getFormData.formatedduration &&
                                                        <div className='drs'>{self.state.getFormData.formatedduration}</div>}
                                                </div>
                                            </div>

                                        </div> */}
                                        {/* <div className='col-md-3'>
                                 <div className='video_preview'>
                                    <div className='video'>
                                        <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a>
                                  
                                        <img src='https://vlive.imgix.net/screening/images/landscape_logo.jpg?auto=compress,format' />
                                    </div>
                                    <div className='video_info'>
                                        <div className='date'>Created: 08-11-2022</div>
                                        <div className='drs'>5:25</div>
                                    </div>
                                 </div>
                                    
                                </div> */}

                                    </div>
                                </div>
                            }
                            {isActiveId === '2' &&
                                <div className='panel_content'>

                                    {/* <h1>kiran</h1> */}
                                    <Availability
                                                handleAvailability={self.handleAvailability} availability={self.state.availability}
                                                donation={self.state.donation} voting={self.state.voting} likeDislike={self.state.likeDislike}
                                                embed={self.state.embed} preview={self.state.preview} timezoneData={self.state.timezoneData} formData={self.state.formData}
                                                assetType={self.state.assetType} assetid={self.state.assetid ? self.state.assetid : ""} serviceProvider={self.state.serviceProvider} />
                                </div>
                            }
                           

                            
                           
                            {isActiveId === '10' &&
                                <Security getFormData={self.state.formData} channelCode={urlParams} availability={self.state.availability} handleAvailability={self.handleAvailability} />

                            }
                        </div>
                    </div>
                    <div className="form_footer form_footer1">
                        <div></div>
                        {isActiveId != '3' && <div className="form_buttons">
                            <button type="button" className="btn btn-link" onClick={() => self.backToChannels()}>cancel</button>
                            {isActiveId != '9' && <button type="button" className="btn btn-primary" onClick={e => self.Save(e)} >Save</button>}
                            {/* {isActiveId == '9' && <button type="button" className="btn btn-primary" onClick={() => self.createMediatailorChannel()} disabled={self.state.executing}><span>{self.state.submitText ? "Saving..." : "Save"}</span></button>} */}
                        </div>
                        }


                    </div>
                    {this.state.isLoader && <Loader />}
                    <footer className="footer">


                        <ToastContainer position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light" />
                        <div className="container-fluid"><p className="footer-para">  &copy; 2022 Envoi Live. All rights reserved. </p></div>
                    </footer>
                </div>
                <SweetAlert className="success" show={this.state.custom}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={this.state.showMessage}
                    onConfirm={e => this.onConfirm()}
                >
                </SweetAlert>
                <SweetAlert show={this.state.showalert}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={this.state.showalertmsg}
                    onConfirm={e => this.onConfirm('imageError')}
                >
                </SweetAlert>
                <SweetAlert show={this.state.alert}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    title={self.state.alertMsg}
                    onConfirm={e => self.setState({ alertMsg: '', alert: false })}
                >
                </SweetAlert>

                <SweetAlert show={this.state.assetFile}
                    custom
                    showCancel
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={"Are you sure to delete this Asset File ?"}
                    onConfirm={e => this.onConfirmDelete()}
                    onCancel={e => this.onCancelDelete()}
                >
                </SweetAlert>
                <SweetAlert show={this.state.isSubscription}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={self.state.message}
                    onConfirm={e => this.onConfirm()}
                >
                </SweetAlert>
                <SweetAlert show={this.state.addMetadata}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={"Please Enter (Channel Name required)"}
                    onConfirm={e => this.onConfirm()}
                >
                </SweetAlert>
                <SweetAlert show={this.state.sucesspopup}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={"Update Successfully"}
                    onConfirm={e => this.onConfirmSuccess()}
                >
                </SweetAlert>
            </React.Fragment>
        )

    }
}

const mapState = ({ loading, channels,  manageChannelsData,vodSourcesData, sourceLocationData,}) => ({ loading, channels,  manageChannelsData,vodSourcesData, sourceLocationData, });
const mapDispatch = { manageChannels, getChannels,getVodSources,getSourceLocation };
export default connect(mapState, mapDispatch)(Profile);