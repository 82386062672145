import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import axios from 'axios';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, ModalBody, ModalHeader, } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker'
import moment from 'moment';
import Slider from "react-slick";
import { CompactPicker } from 'react-color';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Alert from "sweetalert2";
import VideoPlayer from '../videoPlayer/VideoPlayer';
import Player from '../Player/channelPlayer';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { manageScheduling, manageSchedules, getAssets, playlistAssets, scheduleEventsToS3, getPlaybackUrls, manageChannels, getChannels, createMediatailorChannel, fetchMarkers, inserAssetMarkers, msTimeMilliseconds, getVodSources, getSourceLocation, deleteAssetMarkers, channelsList, channelAssets, getCategory, getLookup } from '../../redux/reducers/reducer';
import { location, removeSpecialCharecters } from '../../utils/utils';
import SelectionsExample from '../SelectionsExample';
import SelectionsExampleSearch from "../ControlSelectionsSearch/index";
import SweetAlert from 'react-bootstrap-sweetalert';
import asyncLoop from "node-async-loop";
import Header from '../authentication/Header'
import Footer from '../authentication/Footer'
import Loader from "../Loader";
import VideoMarkers from "../markers/VideoMarkers";
import Availability from '../scheduler/Availability';
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./calendar.css";
import './date.css';
import { setTranslate3d } from 'rsuite/esm/List/helper/utils';
import ReactFileReader from 'react-file-reader';
import Pagination from "react-js-pagination";
const csv = require('csvtojson')
import * as XLSX from "xlsx";
//import useGoogleSheets from 'use-google-sheets';
const Tabletop = require('tabletop');
let arrayType = ["genre", "rating", 'content'];
//import Papa from "papaparse";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
const disabledStyle = { cursor: "default", pointerEvents: "none", opacity: 0.6 };
let urlParams = location("channelCode");
let imgUrl = '';
let userType;
let eventFormatDuration = '';
let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let user_id = localStorage.getItem('user_id');
userType = localStorage.getItem('usertype');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let siteConfig = siteSettings && siteSettings.config;
let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
var publicSpreadsheetUrl = '1sbyMINQHPsJctjAtMW0lCfLrcpMqoGMOJj6AN-sNQrc';

class Scheduling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekendsVisible: true,
            currentEvents: [],
            calendarEvents: [],
            formvalues: props.formvalues || {},
            formvalues1: props.formvalues1 || {},
            events: [],
            categorylist: [],
            activity: 'fetch',
            isCreatorLoading: false,
            activeTab: '1',
            isOpen: false,
            assetData: {},
            assetCount: 12,
            pageCount: 12,
            pageNumber: 1,
            assetsData: [],
            loadbtn: "Load More",
            assetTotal: 0,
            noOfAssets: 0,
            isLoading: true,
            isSementsLoading: false,
            isAddScheduleOpen: false,
            showAlert: false,
            // showAlertMessage: false,
            errors: {},
            errorMsg: '',
            isMetaDataReady: false,
            eventsData: [],
            addMetadata: false,
            assetFile: false,
            noOfPlaylists: 0,
            playlistTotal: 0,
            playlistPageNumber: 1,
            playlistPageCount: 12,
            noOfChannels: 0,
            channelTotal: 0,
            channelPageNumber: 1,
            channelPageCount: 12,
            isUpdateEnabled: false,
            assetInfoData: {},
            formData: {},
            showDeleteEventAlert: false,
            availabilityPopup: false,
            availability: { territory: [], excludedTerritory: [] },
            buttonClass: "btn btn-green jr-btn un-btn",
            buttonClassGray: "btn btn-gray jr-btn un-btn",
            isSegmentPopup: false,
            segmentData: {},
            isExpand: false,
            markerData: {},
            showDeletePopup: false,
            custom: false,
            showDeleteAlert: false,
            actionButton: false,
            isCreateButton: false,
            isadvancedSearch: false,
            isChannelAdvSearch: false,
            playbackmodeObj: {
                "STANDARD": ["LINEAR"],
                "BASIC": ["LINEAR", "LOOP"]
            },
            playbackmode: "",
            isDownloadPopup: false,
            startTimePopup: false,
            channelTimePopup: false,
            dateValueData: {},
            availabilityError: false,
            createChannelValidation: false,
            eventReceived: false,
            eventbody: {},
            importStartTimePopup: false,
            importStartTimeValue: new Date()
        }
        this.calendarComponentRef = React.createRef()
        this.handleAvailability = this.handleAvailability.bind(this);
        this.handleMultiSelect = this.handleMultiSelect.bind(this);
        this.handleMultiSelectChannel = this.handleMultiSelectChannel.bind(this);
    }

    // getGooglesheetInfo = () => {
    //     const { data, loading, error, refetch, called } = useGoogleSheets({

    //        apiKey: "AIzaSyBT90n4WNVETIMoDNVZLzWMZZYs1BzuEYk",
    //        sheetId: "1hNrtjO-ZvGy314NEsLY6PWSNoYviNH2d8eqiu6SDF3g",
    //     sheetsOptions: [{ id: 'Sheet1' }],
    //     });
    //     console.log("data", data)
    // }

    convertAssestToEvent(playlistAssetsData) {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = siteSettings && siteSettings.config;
        let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
        let events = [];
        playlistAssetsData && playlistAssetsData.map((item) => {
            let obj = {
                title: item.playlistname,
                // start: new Date(item.startTime),
                assetcount: item.videoCount,
                id: item.assetid,
                imageurl: item && item.thumbnail ? item.thumbnail : item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                totalduration: item.totalduration,
                assets: item.assets,
                uuid: item.uuid,
                adcount: item.adcount,
                durationtime: item.duration,
                created: item.created,
                award: item.award,
                cast: item.cast,
                crew: item.crew,
                writer: item.writer,
                genre: item.genre,
                screening: item.screening,
                tags: item.tags,
                distributer: item.distributer,
                studio: item.studio,
                maturityrating: item.maturityrating,
                totalAssetsduration: item.totalAssetsduration,
                scheduleId: item && item._id,
                userid : item && item.userid
            }
            events.push(obj)
        })

        this.setState({ events });
    }

    // getGooglesheetInfo() {
    //     Tabletop.init({
    //         key: 'https://docs.google.com/spreadsheets/d/18YcSLUFvCu0Bql0PUZWB3DPuQRyNTcH7l6uxeW9ub1Y',
    //         simpleSheet: true
    //     }
    //     ).then(function (data, tabletop) {
    //         console.log("data",data,tabletop)
    //     })
    //     window.addEventListener('DOMContentLoaded', init)
    // }

    getGooglesheetInfo() {
        Tabletop.init({
            key: publicSpreadsheetUrl,
            callback: this.showInfo(),
            simpleSheet: false
        })
    }

    showInfo(data, tabletop) {
        // do something with the data
        // console.log(JSON.stringify(data, null, 2));
    }
    // getGooglesheetInfo = () => {
    //     //const [data, setData] = useState({});
    //     const [data, setData] = useState({});
    //     Papa.parse("https://docs.google.com/spreadsheets/d/1SJ8LxWmaxKBTgDJLvfD9NZLctBT931x19--qH2yLxck/pub?output=csv", {
    //         download: true,
    //         header: true,
    //         complete: (results) => {
    //             setData(results.data);
    //         },
    //     });
    //     const movies = Array.from(data);
    //     console.log('movies',movies)
    // };
    //
    convertRecordingsToEvent(assets) {
        let self = this;

        let recordingAssets = [];
        assets && assets.map((item) => {
            //   console.log('item',item)
            let obj = {
                title: item.assetname,
                // start: new Date(item.startTime),
                id: item.assetid,
                imageurl: item && item.thumbnail ? item.thumbnail : item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                totalduration: item.totalduration,
                formatedduration: item.formatedduration,
                adcount: item.adcount,
                durationtime: item.duration,
                created: item.created,
                award: item.award,
                cast: item.cast,
                crew: item.crew,
                writer: item.writer,
                genre: item.genre,
                screening: item.screening,
                tags: item.tags,
                distributer: item.distributer,
                studio: item.studio,
                maturityrating: item.maturityrating,
                scheduleId: item && item._id,
            }
            recordingAssets.push(obj)
            //this.setState({ programtotalduration: item.totalduration });
        })

        this.setState({ recordingAssets });
    }
    convertChannelsToEvent(channels) {
        let self = this;

        let channelAssets = [];
        channels && channels.map((item) => {
            let obj = {
                title: item.channelname,
                // start: new Date(item.startTime),
                id: item.channelcode,
                imageurl: item && item.thumbnail ? item.thumbnail : item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                availabilityStartTime: item.availability && item.availability.availableFrom,
                availabilityEndTime: item.availability && item.availability.availableTo,
                totalduration: item.totalduration,
                formatedduration: item.formatedduration,
                adcount: item.adcount,
                durationtime: item.duration,
                created: item.created,
                serviceprovider: item.serviceprovider
            }
            channelAssets.push(obj)
            //this.setState({ programtotalduration: item.totalduration });
        })

        this.setState({ channelAssets });
    }

    componentDidMount() {
        let self = this;
        const { assetCount, pageNumber, playlistPageCount, playlistPageNumber, channelPageNumber, channelPageCount } = this.state;
        let userCode = localStorage.getItem('userCode') || '';
        let payload = { assetType: ['Standalone', 'Recording'], assetCount, pageNumber }
        this.props.getAssets(payload);
        let playlistPayload = { isActive: true, type: 'envoiPlaylistByUsers', playlistPageCount, playlistPageNumber, }
        // let playlistPayload = { activity: "fetch", playlistPageCount, playlistPageNumber, }
        self.props.getLookup(arrayType);
        self.props.getCategory();
        this.props.playlistAssets(playlistPayload);
        let channelPayload = { schedulerProvider: "scheduler", channelPageCount, channelPageNumber, }

        this.props.channelsList(channelPayload);
        // this.convertAssestToEvent();
        let latestScheduleUUID = self.generateUUID();
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                if (self.state.calendarId) {
                    let eventInfo = eventEl.getAttribute("data-for");
                    eventInfo = JSON.parse(eventInfo);
                    let title = eventInfo.title;
                    let id = eventEl.getAttribute("data");
                    return {
                        title: title,
                        id: id,
                        extendedProps: { scheduleUUID: latestScheduleUUID, calendarId: self.state.calendarId, description: '', playlistData: eventInfo }
                    };
                } else {
                    // console.log("schedule required !")
                    self.setState({ errorMsg: 'Schedule name required!', showAlert: true })
                }


            },

            eventOverlap: function (stillEvent, movingEvent) {
                return stillEvent.allDay && movingEvent.allDay;
            },
        });
        // let scheulesBody = { "activity": "fetch", }
        // this.props.manageSchedules(scheulesBody);


        let type = "envoiPlaylistByUsers"
        let data = { type }
        this.props.getChannels(data, true);

        this.props.getVodSources();
        this.props.getSourceLocation();


        let documentinterval = setInterval(() => {
            if (document.getElementsByName("start") && document.getElementsByName("start")[0] && document.getElementsByName("end") && document.getElementsByName("end")[0]) {
                var today = new Date().toISOString().slice(0, 16);
                document.getElementsByName("start")[0].min = today;
                document.getElementsByName("end")[0].min = today;
                clearInterval(documentinterval)
            }
        }, 5000)





    }


    s3CalendarObject = (item) => {
        // console.log("utt ", item)
        // this.setState({ item })
        let fDuration = item && item.extendedProps && item.extendedProps.formattedDuration ? item.extendedProps.formattedDuration : "";
        //  console.log("fDuration ", fDuration)
        let obj = {
            title: item.title,
            start: item.start,
            end: item.end,
            classNames: item.classNames ? item.classNames : [],
            //textColor:item.formatedduration.toString(),
            extendedProps: {
                envoiAssetId: (item.extendedProps || {}).envoiAssetId,
                formattedDuration: fDuration,
                totalduration: item && item.extendedProps && item.extendedProps.totalduration,
                calendarId: item && item.extendedProps && item.extendedProps.calendarId,
                userId: item && item.extendedProps && item.extendedProps.userid ? item.extendedProps.userid : user_id,
                appName: item.appname ? item.appname : window.app.appName,
                scheduleId: item && item._id,
                adcount: item && item.extendedProps && item.extendedProps.adcount,
                durationtime: item && item.extendedProps && item.extendedProps.duration,
                programtotalduration: item && item.extendedProps && item.extendedProps.programtotalduration,
                imageurl: item && item.thumbnail ? item.thumbnail : item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                award: item && item.extendedProps && item.extendedProps.award,
                cast: item && item.extendedProps && item.extendedProps.cast,
                crew: item && item.extendedProps && item.extendedProps.crew,
                writer: item && item.extendedProps && item.extendedProps.writer,
                genre: item && item.extendedProps && item.extendedProps.genre,
                screening: item && item.extendedProps && item.extendedProps.screening,
                tags: item && item.extendedProps && item.extendedProps.tags,
                distributer: item && item.extendedProps && item.extendedProps.distributer,
                studio: item && item.extendedProps && item.extendedProps.studio,
                maturityrating: item && item.extendedProps && item.extendedProps.maturityrating,
                serviceProvider: item && item.extendedProps && item.extendedProps.playlistData && item.extendedProps.playlistData.serviceProvider,
                playlistAssets: item && item.extendedProps && item.extendedProps.assets,
                playlistDuration: item && item.extendedProps && item.extendedProps.playlistData && item.extendedProps.playlistData.totalAssetsduration
            },
            durationEditable: false
        }
        //  console.log('obj', obj)
        return obj
    }

    componentDidUpdate(prevProps) {
        let self = this;

        let payload;
        if (prevProps.formvalues !== this.props.formvalues) {
            // console.log("formvalues",this.props.formvalues)
            this.setState({ formvalues: this.props.formvalues })
        }
        if (prevProps.formvalues1 !== this.props.formvalues1) {
            // console.log("formvalues",this.props.formvalues)
            this.setState({ formvalues1: this.props.formvalues1 })
        }
        //console.log("ccc", prevProps.categoryInfo, this.props.categoryInfo)
        if (prevProps.categoryInfo != this.props.categoryInfo) {
            let categoryInfo = ((this.props.categoryInfo || [])[0] || {}).category || [];
            let menusData = [];
            _.each(categoryInfo, function (item) {
                if (item) {
                    let category = item && (item.value ? item.value : item.key);
                    menusData.push(category);
                }
            })
            self.setState({ menusData });
        }
        if (prevProps.genereInfo !== this.props.genereInfo) {
            let genereInfo = this.props.genereInfo || [];
            if (genereInfo.length > 0)
                genereInfo.forEach((item1) => {
                    arrayType.forEach((item2) => {
                        if (item1.type == item2) {
                            let itemArray = item1.type + "Data"
                            if (item1.type == "rating") {
                                self.setState({
                                    [itemArray]: item1
                                })
                            } else {
                                self.setState({
                                    [itemArray]: item1.values
                                })
                            }
                        }
                    });
                });
        }

        let type = "envoiPlaylistByUsers"
        if (prevProps.channels !== this.props.channels) {
            let channelsData = (this.props.channels || {}).data || [];
            let assetTotal = (this.props.channels || {}).assetTotal || "";
            let noOfAssets = this.state.noOfAssets + channelsData.length;
            // let newlyCreatedSchedule = channelsData[channelsData.length - 1];
            let storageScheduleId = localStorage.getItem("storageScheduleId")

            let newlyCreatedSchedule = channelsData[0];
            // let calendarId = newlyCreatedSchedule && newlyCreatedSchedule.channelcode
            let calendarId = storageScheduleId ? storageScheduleId : newlyCreatedSchedule && newlyCreatedSchedule.channelcode
            //this.setState({ calendarId })

            let body = { "activity": "fetch", calendarId }
            this.props.manageScheduling(body);
            let schedulesLocalData = JSON.parse(localStorage.getItem("schedulesLocalData"))
            console.log("schedulesLocalData ", schedulesLocalData)
            let firstChannel;
            if (schedulesLocalData) {
                firstChannel = schedulesLocalData;
                console.log("channelsData ", firstChannel._id)

                this.setState({
                    schedulesData: channelsData, assetTotal, noOfAssets, isLoading: false, formData: firstChannel,
                    availability: firstChannel && firstChannel.availability,
                    // destinations: channelsData.destinations,
                    id: firstChannel && firstChannel._id,
                    serviceProvider: firstChannel && firstChannel.serviceprovider,
                    calendarId,
                    isMediatailorCreated: firstChannel && firstChannel.mediatailor,
                    actionButton: false,
                    isCreateButton: firstChannel && firstChannel.mediatailor ? true : false
                    //audio: audioDevicesData,
                    // video: videoDevicesData,
                    //playlistbyUsers: channelsData.playlistbyUsers, }, () => {
                    // this.loadChannelsOnScroll();
                });
            } else {
                firstChannel = channelsData && channelsData.length > 0 && channelsData[0];
                //  console.log("channelsData ", channelsData, calendarId)
                this.setState({
                    schedulesData: channelsData, assetTotal, noOfAssets, isLoading: false, formData: firstChannel,
                    availability: firstChannel && firstChannel.availability,
                    // destinations: channelsData.destinations,
                    id: firstChannel && firstChannel._id,
                    serviceProvider: firstChannel && firstChannel.serviceprovider,
                    calendarId,
                    isMediatailorCreated: firstChannel && firstChannel.mediatailor,
                    actionButton: false,
                    isCreateButton: firstChannel && firstChannel.mediatailor ? true : false
                    //audio: audioDevicesData,
                    // video: videoDevicesData,
                    //playlistbyUsers: channelsData.playlistbyUsers, }, () => {
                    // this.loadChannelsOnScroll();
                });
            }

            localStorage.removeItem("storageScheduleId")
            localStorage.removeItem("schedulesLocalData")
        }



        if (prevProps.manageSchedulingData !== this.props.manageSchedulingData) {
            const manageSchedulingData = (this.props.manageSchedulingData || []);
            // console.log("manageSchedulingData ", manageSchedulingData)
            let eventScheduleData = { ...self.state.eventbody }
            // console.log("eventScheduleData ", eventScheduleData)
            if (eventScheduleData && eventScheduleData.extendedProps) {
                let eventDataStart = eventScheduleData.start
                let eventDataEnd = eventScheduleData.end
                let eventDataTitle = eventScheduleData.title
                let eventDataAssetId = eventScheduleData.extendedProps.envoiAssetId

                // let eventPopData = manageSchedulingData.filter((item) => {
                //     if(item.extendedProps.envoiAssetId == eventDataAssetId && item.title == eventDataTitle){
                //         return item
                //     }
                // });
                let eventPopData = manageSchedulingData.filter((item) => {
                    let endDateEqual = moment(new Date(item.end)).isSame(moment(new Date(eventDataEnd)));
                    let startDateEqual = moment(new Date(item.start)).isSame(moment(new Date(eventDataStart)));
                    if (item.extendedProps.envoiAssetId == eventDataAssetId
                        && item.title == eventDataTitle
                        && endDateEqual
                        && startDateEqual
                    ) {
                        return item
                    }
                });
                // console.log("eventPopData ", eventPopData)
                self.setState({ eventPopData });
            }

            self.setState({ allSchedulesData: manageSchedulingData });
            // if (self.state.activity != 'create') {
            let calendarEvents = [];
            manageSchedulingData && Array.isArray(manageSchedulingData) && manageSchedulingData.map((item) => {
                //  console.log("item ", item)
                let obj = self.s3CalendarObject(item)
                calendarEvents.push(obj)
            })
            if (manageSchedulingData) {
                self.setState({ calendarEvents: [...calendarEvents], eventsData: [...calendarEvents], actionButton: false });
                // self.setState({ calendarEvents: [...calendarEvents], eventsData: [...calendarEvents] });
            }
            // }
        }
        if ((prevProps.manageSchedulesData !== this.props.manageSchedulesData)) {
            let manageSchedulesData = (this.props.manageSchedulesData || []);
            // console.log("manageSchedulesData ", manageSchedulesData)
            // self.setState({ schedulesData: manageSchedulesData, });
            if (manageSchedulesData.length > 0) {

                let newlyCreatedSchedule = manageSchedulesData[manageSchedulesData.length - 1];
                // console.log("newlyCreatedSchedule ", newlyCreatedSchedule)
                let calendarId = newlyCreatedSchedule.scheduleId
                this.setState({ calendarId, actionButton: false })
                let body = { "activity": "fetch", calendarId }
                this.props.manageScheduling(body);
            }
        }
        if (prevProps.assets != this.props.assets) {
            let assetsData = ((this.props.assets || {}).data || []);
            let assetTotal = (this.props.assets || {}).assetTotal || '';
            let noOfAssets = (this.state.noOfAssets + assetsData.length);
            //console.log('noOfAssets',noOfAssets,assetsData.length)
            // if (assetsData && assetTotal > 0) {
            //     let totalPages = assetTotal / self.state.assetCount
            //     totalPages = Math.ceil(totalPages)
            //     let totalArray = [];
            //     for (var i = 0; i < totalPages; i++) {
            //         totalArray.push(i + 1)
            //     }
            //     console.log('totalPages',totalPages,totalArray)
            //     this.setState({
            //         totalPages: totalPages,
            //         totalPagesArray: totalArray,
            //         assetsData,
            //         isLoading: false
            //     })

            // }
            this.convertRecordingsToEvent(assetsData)
            this.setState({ assetsData, assetTotal, noOfAssets, isLoading: false });
        }

        if (prevProps.channelList != this.props.channelList) {
            let channelsData = ((this.props.channelList || {}).data || []);
            let channelTotal = (this.props.channelList || {}).assetTotal || '';
            let noOfChannels = (this.state.noOfChannels + channelsData.length);
            this.convertChannelsToEvent(channelsData)
            this.setState({ channelsData, channelTotal, noOfChannels, isLoading: false });
        }

        if (prevProps.playlistAssetsData != this.props.playlistAssetsData) {
            let playlistAssetsData = (this.props.playlistAssetsData || {}).data || [];
            let playlistTotal = (this.props.playlistAssetsData || {}).assetTotal || '';
            let noOfPlaylists = (this.state.noOfPlaylists + playlistAssetsData.length);
            this.setState({ playlistAssetsData, isLoading: false, playlistTotal, noOfPlaylists })
            this.convertAssestToEvent(playlistAssetsData)
        }
        if (prevProps.scheduleEventsData != this.props.scheduleEventsData) {
            this.setState({ isLoading: false, errorMsg: 'Schedule saved successfully', showAlert: true });
        }

        if (prevProps.playbackUrls != this.props.playbackUrls) {
            let playbackUrlsData = (this.props.playbackUrls || {});
            this.setState({ playbackUrlsData })
            let playerUrl = '';
            if (playbackUrlsData) {
                let playerUrls = playbackUrlsData;
                if (playerUrls && playerUrls.playback) {
                    let playbackUrls = playerUrls.playback;
                    if (playbackUrls) {
                        playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl;
                    }
                }
                // console.log("playerUrls", playerUrls)
                //Added this to play for previous object 
                if (!playerUrl) {
                    playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || (playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
                    // console.log("playerUrls22", playerUrl)
                }
            }
            if (!playerUrl) {
                this.setState({
                    showMessage: true
                })
            } else {
                this.setState({ playbackURL: playerUrl })
            }
        }
        if (prevProps.manageChannelsData !== this.props.manageChannelsData) {
            let manageChannelData = (this.props.manageChannelsData || {})[0] || {};
            self.setState({
                channelCode: manageChannelData.channelcode
            });
            if (manageChannelData && manageChannelData._id) {
                payload = { "_id": manageChannelData._id, type }
                //  console.log('payload', payload)
                // self.props.getForms(payload);
                this.props.getChannels(payload, true);
            }
            else {
                // self.props.getForms(payload)
                payload = { type }
                this.props.getChannels(payload, true);
            }
        }
        if ((prevProps.mediatailorChannelData !== this.props.mediatailorChannelData)) {
            if (typeof this.props.mediatailorChannelData === 'object' && this.props.mediatailorChannelData !== null) {
                let firstMediatailorChannelData = this.props.mediatailorChannelData.createChannelResponse;
                if (this.props.mediatailorChannelData && this.props.mediatailorChannelData.message) {
                    self.setState({ showAlert: true, errorMsg: this.props.mediatailorChannelData.message, isMediatailorCreated: firstMediatailorChannelData });
                } else {
                    self.setState({ showAlert: true, errorMsg: 'Mediatailor channel created successfully', isMediatailorCreated: firstMediatailorChannelData });
                }
                //console.log('firstData', firstMediatailorChannelData)

            } else {
                self.setState({ showAlert: true, errorMsg: this.props.mediatailorChannelData });
            }

        }
        if (prevProps.markers != this.props.markers) {
            let markersData = (this.props.markers || []);
            this.setState({ markersData, isSementsLoading: false, isLoading: false, });
        }
        if (prevProps.insertMarkerSuccess !== this.props.insertMarkerSuccess) {
            if (this.props.insertMarkerSuccess) {
                let assetInfo = this.state.assetInfo || {}
                let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
                let formData = {};
                formData["mode"] = "fetch";
                formData["type"] = "segment";
                self.props.fetchMarkers(assetId, formData);
                self.setState({ showAlert: true, errorMsg: 'Added successfully', segmentData: {}, markerData: {} });

            }
        }

        if (prevProps.updateMarkerSuccess !== this.props.updateMarkerSuccess) {
            if (this.props.updateMarkerSuccess) {
                let assetInfo = this.state.assetInfo || {}
                let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
                let formData = {};
                formData["mode"] = "fetch";
                formData["type"] = "segment";
                self.props.fetchMarkers(assetId, formData);
                self.setState({ showAlert: true, errorMsg: 'Deleted successfully', segmentData: {}, markerData: {} });
            }
        }
        if (prevProps.vodSourcesData !== this.props.vodSourcesData) {
            let vodSourcesData = (this.props.vodSourcesData || []);
            this.setState({ vodSourcesData, isLoading: false, });
        }

        if (prevProps.sourceLocationData !== this.props.sourceLocationData) {
            //  console.log("this.props.sourceLocationData", this.props.sourceLocationData)
            let sourceLocation = (this.props.sourceLocationData || '');
            this.setState({ sourceLocationData: [sourceLocation], isLoading: false, });
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.mt_channelsdata !== this.props.mt_channelsdata) {
            let mt_channelsdata = (nextProps.mt_channelsdata || {}).data || [];
            this.setState({
                schedulesData: mt_channelsdata
            });
        }
        if (nextProps.channelAssetsProps !== this.props.channelAssetsProps) {
            let channelArrayAssets = nextProps.channelAssetsProps && nextProps.channelAssetsProps[0];
            // console.log('channelArrayAssets',channelArrayAssets)
            this.setState({ playbackUrlsData: channelArrayAssets })
            let playerUrl = '';
            if (channelArrayAssets && channelArrayAssets.channelassets) {
                let playerUrls = channelArrayAssets.channelassets && channelArrayAssets.channelassets[0];
                if (playerUrls && playerUrls.playerurls) {
                    let playbackUrls = playerUrls.playerurls;
                    if (playbackUrls) {
                        playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl || playbackUrls.m3u8_Url;
                    }
                }
                //  console.log("playerUrls", playerUrls)
                //Added this to play for previous object 
                // if (!playerUrl) {
                //     playerUrl = (playerUrls.playerurls.hls) || (playerUrls.playerurls.m3u8_Url) || (playerUrls.playerurls.progressive) || (playerUrls.playerurls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || (playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
                //     // console.log("playerUrls22", playerUrl)
                // }
                //console.log("playerUrls22", playerUrl)
            }
            if (!playerUrl) {
                this.setState({
                    showMessage: true
                })
            } else {
                this.setState({ playbackURL: playerUrl })
            }
        }
    }

    loadMore(number, loader) {
        let self = this;
        let formvalues = self.state.formvalues;
        console.log('formvalues', formvalues)
        let pageNumber = number ? number : self.state.pageNumber + 1;
        let assetCount = self.state.pageCount;
        self.setState({
            loadbtn: !loader ? "Loading..." : this.state.loadbtn,
            loading: loader,
            pageNumber: pageNumber,
        });
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let url = envoiSubmissionApiBaseUrl + "/asset?appname=" + window.app.appName + "&token=" + token + "&assetcount=" + assetCount + "&pageNumber=" + pageNumber + "&env=" + window.app.appEnvName + "&isMarkerDuration=true";
        // let userCode = localStorage.getItem("userCode") || "";
        // if (userCode) url += "&code=" + userCode;
        if (formvalues) {
            let urlLink = `${envoiSubmissionApiBaseUrl}/asset?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&assetCount=${assetCount}&pageNumber=${pageNumber}`;

            axios({
                method: 'POST',
                url: urlLink,
                data: formvalues
            })
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.data) {
                        let assetsData = response.data.result.data;
                        console.log('assetsData', assetsData)
                        let assetTotal = response.data.result.assetTotal || '';
                        let assetCount = response.data.result.asssetcount || '';
                        self.convertRecordingsToEvent(assetsData)
                        self.setState({ assetTotal, assetCount, assetsData, pageNumber: pageNumber, });
                        // self.convertRecordingsToEvent(response.data.result.data)
                    }
                });
        } else {
            axios
                .post(url, { assetType: ['Standalone', 'Recording'] })
                .then((response) => {
                    self.setState({ assetsData: number ? [] : self.state.assetsData, isLoading: false });
                    if (
                        response.data &&
                        response.data.result &&
                        response.data.result.data &&
                        response.data.result.data.length > 0
                    ) {
                        asyncLoop(
                            response.data.result && response.data.result.data,
                            function (item, next) {
                                self.setState(
                                    {
                                        assetsData: self.state.assetsData.concat(item),
                                        loading: false,
                                        loadbtn: !loader ? "Load More" : this.state.loadbtn,
                                        isLoading: false,
                                    },
                                    function () {
                                        this.convertRecordingsToEvent(self.state.assetsData)
                                        setTimeout(function () {
                                            next();
                                        }, 100);
                                    }
                                );
                            }
                        );
                        let newNoOfAssets = response.data.result.data.length;
                        self.setState({
                            noOfAssets: number
                                ? newNoOfAssets
                                : self.state.noOfAssets + newNoOfAssets,
                            loading: false,
                        });
                    } else {
                        self.setState({
                            assetTotal:
                                self.state.noOfAssets + response.data.result.data.length,
                            loading: false,
                        });
                    }
                })
                .catch((err) => { });
        }
    }

    _handleSearch = (query, type, userType) => {
        // console.log("userType", userType);
        userType = userType && userType.toLowerCase();
        this.makeAndHandleRequest(query, userType)
    };
    _handleSearchChannel = (query, type, userType) => {
        // console.log("userType", userType);
        userType = userType && userType.toLowerCase();
        this.makeAndHandleRequest(query, userType)
    };
    _handleSearchChannelOwner = (query, type, userType) => {
        // console.log("userTypecccccccccccccc", userType);
        userType = userType && userType.toLowerCase();
        this.makeAndHandleRequest1(query, userType)
    };
    makeAndHandleRequest1(query, userType) {
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        const { assetCount, pageNumber } = this.state;
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let SEARCH_URI = `${envoiSubmissionApiBaseUrl}/user?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&contentType=${'user'}&userType=${userType}&assetCount=${assetCount}&pageNumber=${pageNumber}isProjection=true`;
        axios.post(SEARCH_URI + '&search=' + query)
            .then((response) => {
                let data = (((response || {}).data || {}).result || {}).data || [];
                let partnerOptions = [];
                let creatorOptions = [];
                _.each(data, function (item) {
                    if (item.code && item.code != '' && item.code != undefined && item.code != 'undefined') {
                        var values = {}
                        values['code'] = item.code;
                        values['name'] = ((item.firstname && item.lastname) && (item.firstname == item.lastname)) ? item.firstname : (item.lastname ? item.firstname + ' ' + item.lastname : '');
                        if (userType == 'creator') {
                            values["type"] = item.creatorType;
                            creatorOptions.push(values);
                        }
                        else if (userType == 'partner') {
                            values['companyname'] = ((item || {}).companyAddress || {}).name || '';
                            partnerOptions.push(values)
                        }
                    }
                })
                this.setState({ isCreatorLoading: false, isLoading: false, partnerOptions, creatorOptions })
            })

            .catch((err) => {
                console.error.bind(err);
            })
    }
    makeAndHandleRequest(query, userType) {
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        const { assetCount, pageNumber } = this.state;
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let SEARCH_URI = `${envoiSubmissionApiBaseUrl}/user?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&contentType=${'user'}&userType=${userType}&assetCount=${assetCount}&pageNumber=${pageNumber}&isProjection=true`;
        axios.post(SEARCH_URI + '&search=' + query)
            .then((response) => {
                let data = (((response || {}).data || {}).result || {}).data || [];
                let partnerOptions = [];
                let creatorOptions = [];
                _.each(data, function (item) {
                    if (item.code && item.code != '' && item.code != undefined && item.code != 'undefined') {
                        var values = {}
                        values['code'] = item.code;
                        values['name'] = ((item.firstname && item.lastname) && (item.firstname == item.lastname)) ? item.firstname : (item.lastname ? item.firstname + ' ' + item.lastname : '');
                        if (userType == 'creator') {
                            values["type"] = item.creatorType;
                            creatorOptions.push(values);
                        }
                        else if (userType == 'partner') {
                            values['companyname'] = ((item || {}).companyAddress || {}).name || '';
                            partnerOptions.push(values)
                        }
                    }
                })
                this.setState({ isCreatorLoading: false, isLoading: false, partnerOptions, creatorOptions })
            })

            .catch((err) => {
                console.error.bind(err);
            })
    }

    handleChangeAsset(e) {
        let self = this;
        let catalogvalues = { ...self.state.formvalues };
        let name = e.target.name;
        let value = e.target.value;
        if (value != '') {
            if (name.includes('available') || name.includes('release')) {
                let dateFromId = name.includes('available') ? 'availableFrom' : 'releasedateFrom';
                console.log('dateFromId', dateFromId)
                // let dateFrom = document.getElementById(dateFromId);
                // console.log('dateFrom',dateFrom)
                let dateToId = name.includes('available') ? 'availableTo' : 'releasedateTo'
                // let dateto = document.getElementById(dateToId);
                // console.log('dateto',dateto)
                let fromdate = dateFromId;
                console.log("fromdate", fromdate)
                let todate = dateToId;
                console.log("todate", todate)
                // let count = todate.diff(fromdate, 'days');
                // console.log("count",count)
                let errors = Object.assign({}, this.state.errors);
                catalogvalues[name] = value;
                console.log("catalogvalues catalogvalues catalogvalues", catalogvalues)
                if (catalogvalues && catalogvalues.releasedateTo < catalogvalues.releasedateFrom) {
                    errors[dateToId] = 'Todate must be greater than Fromdate';
                    console.log(errors, "errors")

                } else {
                    delete errors[dateToId];
                }
                this.setState({ errors });
            } else {
                catalogvalues[name] = value;
                this.setState({ [name]: value, formvalues: catalogvalues });
            }
        } else {
            delete catalogvalues[name]
            this.setState({ [name]: value, formvalues: catalogvalues });
        }
        console.log('catalogvalues', catalogvalues)
        this.setState({ [name]: value, formvalues: catalogvalues });
        // self.props.handleData(catalogvalues);
    }

    handleChangeChannel(e) {
        let self = this;
        let catalogvalues = { ...self.state.formvalues1 };
        let name = e.target.name;
        let value = e.target.value;
        // if (value != '') {
        //     if (name == 'createddateTo') {
        //         let dateFrom = catalogvalues.createddateForm;
        //         // if (name == 'releasedateTo') dateFrom = catalogvalues.releasedateFrom;
        //         let fromdate = moment(dateFrom);
        //         let todate = moment(value);
        //         // console.log(fromdate, todate)
        //         let count = todate.diff(fromdate, 'days');
        //         // console.log("cunt", count);
        //         let errors = Object.assign({}, this.state.errors);
        //         if (count > 0) {
        //             delete errors[name];
        //             catalogvalues[name] = value;
        //         } else {
        //             errors[name] = 'Todate must be greater than Fromdate';
        //         }
        //         this.setState({ errors });
        //     } else {
        //         catalogvalues[name] = value;
        //         this.setState({ [name]: value, formvalues1: catalogvalues });
        //     }
        // } else {
        //     delete catalogvalues[name]
        //     this.setState({ [name]: value, formvalues1: catalogvalues });
        // }
        // this.setState({ [name]: value, formvalues1: catalogvalues });
        // self.props.handleData(catalogvalues);

        if (value != '') {
            if (name.includes('available') || name.includes('created')) {
                let dateFromId = name.includes('available') ? 'availableFrom' : 'createdFrom';
                console.log('dateFromId', dateFromId)
                // let dateFrom = document.getElementById(dateFromId);
                // console.log('dateFrom',dateFrom)
                let dateToId = name.includes('available') ? 'availableTo' : 'createdTo'
                // let dateto = document.getElementById(dateToId);
                // console.log('dateto',dateto)
                let fromdate = dateFromId;
                console.log("fromdate", fromdate)
                let todate = dateToId;
                console.log("todate", todate)
                // let count = todate.diff(fromdate, 'days');
                // console.log("count",count)
                let errors = Object.assign({}, this.state.errors);
                catalogvalues[name] = value;
                console.log("catalogvalues catalogvalues catalogvalues", catalogvalues)
                if (catalogvalues && catalogvalues.createdTo < catalogvalues.createdFrom) {
                    errors[dateToId] = 'Todate must be greater than Fromdate';
                    console.log(errors, "errors")

                } else {
                    delete errors[dateToId];
                }
                this.setState({ errors });
            } else {
                catalogvalues[name] = value;
                this.setState({ [name]: value, formvalues1: catalogvalues });
            }
        } else {
            delete catalogvalues[name]
            this.setState({ [name]: value, formvalues1: catalogvalues });
        }
        console.log('catalogvalues', catalogvalues)
        this.setState({ [name]: value, formvalues1: catalogvalues });
    }

    handleMultiSelect(e, type) {
        let self = this;
        let catalogvalues = { ...self.state.formvalues };
        this.setState({ executing: false })
        switch (type) {
            case 'Category':
                if (e && e.length > 0) {
                    catalogvalues["carousel"] = e;
                    self.setState({ formvalues: catalogvalues, carousel: e });
                } else {
                    // catalogvalues["carousel"] = [];
                    delete catalogvalues['carousel']
                    self.setState({ formvalues: catalogvalues, carousel: [] });
                }
                break;
            case 'Genre':
                if (e && e.length > 0) {
                    catalogvalues["genre"] = e;
                    self.setState({ formvalues: catalogvalues, genre: e });
                } else {
                    // catalogvalues["genre"] = [];
                    delete catalogvalues['genre']
                    self.setState({ formvalues: catalogvalues, genre: [] });
                }
                break;
            case 'Rating':
                if (e && e.length > 0) {
                    catalogvalues["maturityrating"] = e;
                    self.setState({ formvalues: catalogvalues, maturityrating: e });
                } else {
                    // catalogvalues["maturityrating"] = [];
                    delete catalogvalues['maturityrating']
                    self.setState({ formvalues: catalogvalues, maturityrating: [] });
                }
                break;
            case 'Content':
                if (e && e.length > 0) {
                    catalogvalues["assetType"] = e;
                    self.setState({ formvalues: catalogvalues, assetType: e });
                } else {
                    // catalogvalues["assetType"] = [];
                    delete catalogvalues['assetType']
                    self.setState({ formvalues: catalogvalues, assetType: [] });
                }
                break;
            case 'Creator':
                if (e && e.length > 0) {
                    catalogvalues["creator"] = e;
                    self.setState({ formvalues: catalogvalues, creator: e });
                } else {
                    // catalogvalues["creator"] = [];
                    delete catalogvalues['creator']
                    self.setState({ formvalues: catalogvalues, creator: [] });
                }
                break;
            case "Partner":
                if (e && e.length > 0) {
                    catalogvalues["partner"] = e
                    self.setState({ formvalues: catalogvalues, partner: e });
                } else {
                    // catalogvalues["partner"] = [];
                    delete catalogvalues['partner']
                    self.setState({ formvalues: catalogvalues, partner: [] });
                }
                break;

            case "Brand":
                if (e && e.length > 0) {
                    catalogvalues["brand"] = e;
                    self.setState({ formvalues: catalogvalues, brand: e });
                } else {
                    catalogvalues["brand"] = [];
                    self.setState({ formvalues: catalogvalues, brand: [] });
                }
                break;
            case "Channel":
                if (e && e.length > 0) {
                    catalogvalues["channel"] = e;
                    self.setState({ formvalues: catalogvalues, channel: e });
                } else {
                    catalogvalues["channel"] = [];
                    self.setState({ formvalues: catalogvalues, channel: [] });
                }
                break;
            case "Product":
                if (e && e.length > 0) {
                    catalogvalues["product"] = e;
                    self.setState({ formvalues: catalogvalues, product: e });
                } else {
                    catalogvalues["product"] = [];
                    self.setState({ formvalues: catalogvalues, product: [] });
                }
                break;
            case "Event":
                if (e && e.length > 0) {
                    catalogvalues["event"] = e;
                    self.setState({ formvalues: catalogvalues, event: e });
                } else {
                    catalogvalues["event"] = [];
                    self.setState({ formvalues: catalogvalues, event: [] });
                }
                break;
        }
        // self.props.handleData(catalogvalues);
    }

    handleMultiSelectChannel(e, type) {
        let self = this;
        let catalogvalues = { ...self.state.formvalues };
        this.setState({ executing: false })
        switch (type) {



            case 'Creator':
                if (e && e.length > 0) {
                    catalogvalues["creator"] = e;
                    self.setState({ formvalues: catalogvalues, creator: e });
                } else {
                    // catalogvalues["creator"] = [];
                    delete catalogvalues['creator']
                    self.setState({ formvalues: catalogvalues, creator: [] });
                }
                break;
            case "Partner":
                if (e && e.length > 0) {
                    catalogvalues["partner"] = e
                    self.setState({ formvalues1: catalogvalues, partner: e });
                } else {
                    // catalogvalues["partner"] = [];
                    delete catalogvalues['partner']
                    self.setState({ formvalues1: catalogvalues, partner: [] });
                }
                break;

            case "Owner":
                if (e && e.length > 0) {
                    catalogvalues["owner"] = e
                    self.setState({ formvalues1: catalogvalues, owner: e });
                } else {
                    delete catalogvalues['owner']
                    self.setState({ formvalues1: catalogvalues, owner: [] });
                }
                break;
        }
        // self.props.handleData(catalogvalues);
    }
    handleClear = (e) => {
        e.preventDefault();
        let self = this;
        let formvalues = { ...self.state.formvalues };
        let keys = Object.keys(formvalues);
        keys.map((key) => {
            formvalues[key] = [];
        })
        self.setState({ formvalues }, () => {
            //  self.handlePaginationData(1, 16);
            // console.log('formvaluess after',self.state.formvalues)
        })
        if (formvalues) {
            self.setState({
                isadvancedSearch: false,
            });
            let appSettings = window.app || {};
            let appStorage = appSettings.storage || {};
            let token = appStorage.getItem("token");
            let siteSettings = window.site || {};
            const { assetCount, pageNumber } = this.state;
            let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
            let urlLink = envoiSubmissionApiBaseUrl + "/asset?appname=" + window.app.appName + "&token=" + token + "&assetcount=" + assetCount + "&pageNumber=" + pageNumber + "&env=" + window.app.appEnvName + "&isMarkerDuration=true";

            axios
                .post(urlLink, { assetType: ['Standalone', 'Recording'] })
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.data) {
                        let assetsData = response.data.result.data;
                        console.log('assetsData', assetsData)
                        let assetTotal = response.data.result.assetTotal || '';
                        let assetCount = response.data.result.asssetcount || '';
                        self.convertRecordingsToEvent(assetsData)
                        self.setState({ assetTotal, assetCount, assetsData, pageNumber: 1, formvalues: [] });
                    }
                });
            // self.props.getAssets({ advancedSearch: formvalues, contentType: 'content', subModule: 'false', pageNo: 1, perPage: 16 });
            // self.setState({ loader: true, advancedSearch: false, pageNo: 1, perPage: 16 });
        }
    }
    handleChannelClear = (e) => {
        e.preventDefault();
        let self = this;
        let formvalues1 = { ...self.state.formvalues1 };
        let keys = Object.keys(formvalues1);
        keys.map((key) => {
            formvalues1[key] = [];
        })
        self.setState({ formvalues1 }, () => {
            //  self.handlePaginationData(1, 16);
            // console.log('formvaluess after',self.state.formvalues)
        })
        if (formvalues1) {
            self.setState({
                isChannelAdvSearch: false,
            });
            let appSettings = window.app || {};
            let appStorage = appSettings.storage || {};
            let token = appStorage.getItem("token");
            let siteSettings = window.site || {};
            const { assetCount, pageNumber } = this.state;
            let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
            let urlLink = `${envoiSubmissionApiBaseUrl}/channel?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&assetCount=${assetCount}&pageNumber=${pageNumber}`;

            axios({
                method: 'POST',
                url: urlLink,
            })
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.data) {
                        self.convertChannelsToEvent(response.data.result.data)
                    }
                });
            // self.props.getAssets({ advancedSearch: formvalues, contentType: 'content', subModule: 'false', pageNo: 1, perPage: 16 });
            // self.setState({ loader: true, advancedSearch: false, pageNo: 1, perPage: 16 });
        }
    }
    handleSearch() {
        let self = this;
        let formvalues = self.state.formvalues;

        if (formvalues['creator'] && formvalues['creator'] != []) {
            let creatorValues = formvalues['creator'] || [];
            let names = [];
            _.each(creatorValues, function (item) {
                let name = item.name ? item.name : item ? item : "";
                names.push(name)
            })

            formvalues['creator'] = names
        }
        if (formvalues) {
            self.setState({
                isadvancedSearch: false,
            });
            let appSettings = window.app || {};
            let appStorage = appSettings.storage || {};
            let token = appStorage.getItem("token");
            let siteSettings = window.site || {};
            const { assetCount, pageNumber } = this.state;
            let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
            let urlLink = `${envoiSubmissionApiBaseUrl}/asset?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&assetCount=${assetCount}&pageNumber=${"1"}`;

            axios({
                method: 'POST',
                url: urlLink,
                data: formvalues
            })
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.data) {
                        let assetsData = response.data.result.data;
                        console.log('assetsData', assetsData)
                        let assetTotal = response.data.result.assetTotal || '';
                        let assetCount = response.data.result.asssetcount || '';
                        self.convertRecordingsToEvent(assetsData)
                        self.setState({ assetTotal, assetCount, assetsData, pageNumber: 1 });
                        // self.convertRecordingsToEvent(response.data.result.data)
                    }
                });
            // self.props.getAssets({ advancedSearch: formvalues, contentType: 'content', subModule: 'false', pageNo: 1, perPage: 16 });
            // self.setState({ loader: true, advancedSearch: false, pageNo: 1, perPage: 16 });
        }
    }
    handleSearchChannel() {
        let self = this;
        let formvalues1 = self.state.formvalues1;

        if (formvalues1['creator'] && formvalues1['creator'] != []) {
            let creatorValues = formvalues1['creator'] || [];
            let names = [];
            _.each(creatorValues, function (item) {
                let name = item.name ? item.name : item ? item : "";
                names.push(name)
            })

            formvalues1['creator'] = names
        }
        if (formvalues1['owner'] && formvalues1['owner'] != []) {
            let creatorValues = formvalues1['owner'] || [];
            let names = [];
            _.each(creatorValues, function (item) {
                let name = item.name ? item.name : item ? item : "";
                names.push(name)
            })

            formvalues1['owner'] = names
        }
        if (formvalues1) {
            self.setState({
                isChannelAdvSearch: false,
            });
            let appSettings = window.app || {};
            let appStorage = appSettings.storage || {};
            let token = appStorage.getItem("token");
            let siteSettings = window.site || {};
            const { assetCount, pageNumber } = this.state;
            let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
            let urlLink = `${envoiSubmissionApiBaseUrl}/channel?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&assetCount=${assetCount}&pageNumber=${pageNumber}`;

            axios({
                method: 'POST',
                url: urlLink,
                data: formvalues1
            })
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.data) {
                        self.convertChannelsToEvent(response.data.result.data)
                    }
                });
            // self.props.getAssets({ advancedSearch: formvalues, contentType: 'content', subModule: 'false', pageNo: 1, perPage: 16 });
            // self.setState({ loader: true, advancedSearch: false, pageNo: 1, perPage: 16 });
        }
    }
    channelLoadMore(number, loader) {
        let self = this;
        let pageNumber = number ? number : self.state.channelPageNumber + 1;
        let assetCount = self.state.channelPageCount;
        self.setState({
            loadbtn: !loader ? "Loading..." : this.state.loadbtn,
            loading: loader,
            channelPageNumber: pageNumber,
        });
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let url = envoiSubmissionApiBaseUrl + "/channel?appname=" + window.app.appName + "&token=" + token + "&assetcount=" + assetCount + "&pageNumber=" + pageNumber + "&env=" + window.app.appEnvName + "&schedulerProvider=scheduler";
        // let userCode = localStorage.getItem("userCode") || "";
        // if (userCode) url += "&code=" + userCode;
        axios
            .post(url)
            .then((response) => {
                self.setState({ channelsData: number ? [] : self.state.channelsData, isLoading: false });
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.data &&
                    response.data.result.data.length > 0
                ) {
                    asyncLoop(
                        response.data.result && response.data.result.data,
                        function (item, next) {
                            self.setState(
                                {
                                    channelsData: self.state.channelsData.concat(item),
                                    loading: false,
                                    loadbtn: !loader ? "Load More" : this.state.loadbtn,
                                    isLoading: false,
                                },
                                function () {
                                    this.convertChannelToEvent(self.state.channelsData)
                                    setTimeout(function () {
                                        next();
                                    }, 100);
                                }
                            );
                        }
                    );
                    let newNoOfAssets = response.data.result.data.length;
                    self.setState({
                        noOfAssets: number
                            ? newNoOfAssets
                            : self.state.noOfAssets + newNoOfAssets,
                        loading: false,
                    });
                } else {
                    self.setState({
                        assetTotal:
                            self.state.noOfAssets + response.data.result.data.length,
                        loading: false,
                    });
                }
            })
            .catch((err) => { });
    }

    handlePageChange(e) {
        console.log("e ", e)
    }

    loadMorePlaylist(number, loader) {
        let self = this;
        let pageNumber = number ? number : self.state.playlistPageNumber + 1;
        let assetCount = self.state.playlistPageCount;
        self.setState({
            loadbtn: !loader ? "Loading..." : this.state.loadbtn,
            loading: loader,
            playlistPageNumber: pageNumber,
        });
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let url = envoiSubmissionApiBaseUrl + "/PlayLists?appname=" + window.app.appName + "&token=" + token + "&assetcount=" + assetCount + "&pageNumber=" + pageNumber + "&env=" + window.app.appEnvName;
        // let userCode = localStorage.getItem("userCode") || "";
        // if (userCode) url += "&code=" + userCode;
        axios
            .post(url, { isActive: true, type: 'envoiPlaylistByUsers' })
            .then((response) => {
                self.setState({ playlistAssetsData: number ? [] : self.state.playlistAssetsData, isLoading: false });
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.data &&
                    response.data.result.data.length > 0
                ) {
                    asyncLoop(
                        response.data.result && response.data.result.data,
                        function (item, next) {
                            self.setState(
                                {
                                    playlistAssetsData: self.state.playlistAssetsData.concat(item),
                                    loading: false,
                                    loadbtn: !loader ? "Load More" : this.state.loadbtn,
                                    isLoading: false,
                                },
                                function () {
                                    self.convertAssestToEvent(self.state.playlistAssetsData)
                                    setTimeout(function () {
                                        next();
                                    }, 100);
                                }
                            );
                        }
                    );
                    let newNoOfAssets = response.data.result.data.length;
                    self.setState({
                        noOfPlaylists: number
                            ? newNoOfAssets
                            : self.state.noOfPlaylists + newNoOfAssets,
                        loading: false,
                    });
                } else {
                    self.setState({
                        playlistTotal:
                            self.state.noOfPlaylists + response.data.result.data.length,
                        loading: false,
                    });
                }
            })
            .catch((err) => { });
    }

    /**
   * when we click on event we are displaying event details
   */
    eventClick = info => {
        let self = this;
        // console.log("eventClick", info.event)

        let programtotalduration = info && info.event && info.event.extendedProps && info.event.extendedProps.programtotalduration
        let payload = { assetId: info.event.id ? info.event.id : (info.event.extendedProps || {}).envoiAssetId }
        // this.getAssetDetails(payload);
        let playlistAssets = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistAssets || info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.assets
        self.setState({ assetInfo: info.event || {}, assetInfoData: {}, isUpdateEnabled: false, programtotalduration, playlistAssets: playlistAssets }, () => {
            self.setState({ isMetaDataReady: true })
        })
        if (info && info.event && info.event.extendedProps && info.event.extendedProps.serviceProvider) {
            self.props.channelAssets(payload.assetId)
        } else {
            this.props.getPlaybackUrls(payload);
            let formData = {};
            formData["mode"] = "fetch";
            formData["type"] = "segment";
            self.props.fetchMarkers(payload.assetId, formData);
        }
        this.setState({ loader: true })

    };
    assetPopulateClick(e, item) {
        let self = this;
        // console.log("item", item,)
        // console.log("item", self.state.assetInfo,)
        let assetInfo = self.state.assetInfo
        let programtotalduration = item && item.totalduration
        let formatedduration = item && item.formatedduration
        let payload = { assetId: item.assetid ? item.assetid : (assetInfo.extendedProps || {}).envoiAssetId }
        // this.getAssetDetails(payload);
        self.setState({ assetInfo: assetInfo || {}, assetInfoData: {}, isUpdateEnabled: false, programtotalduration, formatedduration }, () => {
            self.setState({ isMetaDataReady: true })
        })
        this.props.getPlaybackUrls(payload);
        let formData = {};
        formData["mode"] = "fetch";
        formData["type"] = "segment";
        self.props.fetchMarkers(payload.assetId, formData);

        this.setState({ loader: true })
    }

    getAssetDetails(payload) {
        let self = this;
        axios.post(envoiSubmissionApiBaseUrl + '/asset?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&assetid=' + payload.assetId)
            .then((response) => {
                if (response && response.data && response.data.result) {
                    let assetInfo = ((response.data.result || {}).data || [])[0] || {};
                    self.setState({ assetInfo, isMetaDataReady: true, loader: false });
                }
            })
            .catch(error => error)
    }

    generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        this.setState({
            fileId: uuid.toUpperCase()
        });
        return uuid.toUpperCase();
    }


    eventReceive = info => {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let appStorage = appSettings.storage;
        let token = appStorage.getItem('token');
        let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
        // console.log('info', info)
        // let assetName = info.event.title;
        // let fullAssetName = assetName && assetName.split('-');
        let title = info.event.title;
        let assetId = info.event.id;
        let start = info.event.start;
        let duration;
        //  console.log("formData", self.state.formData);
        //console.log("dateValueData", self.state.dateValueData);

        if (info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.totalduration) {
            duration = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.totalduration;
        }
        let adCount = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.adcount
        let durationTime = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.durationtime;
        let playlistAssets = info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.assets;
        let serviceProvider = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.serviceprovider
        let date = new Date();
        //  console.log("date", date);
        let featuredDate = moment(date).format("YYYY-MM-DD[T]HH:mm:ss");
        // let featuredvalueDate = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityStartTime <= featuredDate;
        //     console.log("featuredDate", featuredvalueDate);
        self.setState({ playlistAssets, })
        if (playlistAssets) {
            let start = info.event.start;
            // console.log("playlistAssets", start);

            self.setState({ playlistData: info.event, startTimePopup: true, start, playlistAssets })
        }
        else if (self.state.calendarId && serviceProvider) {
            // let programtotalduration = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration
            // let convertedDateArray = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration && info.event.extendedProps.playlistData.formatedduration.split(' ');
            // var endDate;
            // if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
            //     endDate = self.endDatePrepare(convertedDateArray, info.event.start)
            // }
            if (info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityStartTime <= featuredDate) {

                // console.log("featuredvalueDate", featuredvalueDate);
                let start = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityStartTime;
                //let end = endDate;
                let end = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityEndTime;
                let assetInfo = info.event || {};
                this.setState({ channelTimePopup: true, endTimeValue: end, startTimeValue: start, assetInfo: assetInfo, serviceProvider })
            }
            else if (info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && !info.event.extendedProps.playlistData.availabilityStartTime && info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && !info.event.extendedProps.playlistData.availabilityEndTime) {
                let start = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityStartTime;
                //let end = endDate;
                let end = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityEndTime;
                // console.log("start", start, end);
                let assetInfo = info.event || {};
                this.setState({ channelTimePopup: true, start, end, assetInfo: assetInfo, serviceProvider })
            } else {
                let start = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityStartTime;
                //let end = endDate;
                let end = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.availabilityEndTime;
                let assetInfo = info.event || {};
                self.setState({ end: end, start: start, assetInfo: assetInfo, serviceProvider }, () => {
                    self.setState({ isMetaDataReady: true, assetInfoData: {}, isUpdateEnabled: false })
                })
                let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
                //let payload = { assetId: assetId }
                // let playbackUrls = true;
                self.props.channelAssets(assetId)
                // this.props.getPlaybackUrls(payload);
                // let formData = {};
                // formData["mode"] = "fetch";
                // formData["type"] = "segment";
                // self.props.fetchMarkers(payload.assetId, formData);
                let body = {
                    title: title,
                    start: start,
                    end: end,
                    // totalduration: duration,
                    // programtotalduration: formatTimeDuration,
                    // formatedduration: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration,
                    activity: "create",
                    classNames: ['standaloneAsset'],
                    extendedProps: {
                        envoiAssetId: assetId,
                        scheduleUUID: info.event.extendedProps.scheduleUUID,
                        liveChannel: true || "true",
                        type: 'Scheduler',
                        calendarId: self.state.calendarId,
                        serviceProvider: serviceProvider,
                        imageurl: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.landscape_logo_image ? info.event.extendedProps.playlistData.landscape_logo_image : info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.imageurl ? info.event.extendedProps.playlistData.imageurl : defaultLandscape,
                        award: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.award || "",
                        cast: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.cast || "",
                        crew: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.crew || "",
                        writer: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.writer || "",
                        genre: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.genre || "",
                        screening: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.screening || "",
                        tags: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.tags || "",
                        distributer: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.distributer || "",
                        studio: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.studio || "",
                        maturityrating: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.maturityrating || "",
                    },
                    durationEditable: false,

                    // channelid: self.state.calendarId,
                    //assetId: assetId,

                }
                // let body = { "activity": "create", start: new Date(start), channelid: this.state.channelId, "assetid": assetId, "image": "https://vlive.imgix.net/screening/images/portrait_logo.jpg", "assetname": assetName }
                //console.log('body', body)
                self.props.manageScheduling(body);
                let obj = this.s3CalendarObject(body);
                eventsData.push(obj);
                //   console.log('eventsData', eventsData)
                info.event.remove();
                this.setState({ activity: 'create', eventsData, eventReceived: true, eventbody: body })
            }
        }
        else if (self.state.calendarId && adCount > 0) {
            //const { calendarId } = this.state;
            //let payload1 = { "channelCode": calendarId }
            // this.props.getChannels(payload, true);
            // let url = envoiSubmissionApiBaseUrl + "/channel?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token + "&channelCode=" + self.state.calendarId
            // axios.post(url)
            //     .then((response) => {

            let adDuration = self.state.formData;
            // console.log('adDuration', adDuration)
            var seconds = adDuration.average_ad_duration * adCount; // or "2000"
            seconds = parseInt(seconds) //because moment js dont know to handle number in string format

            // let convertedDateArray = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration && info.event.extendedProps.playlistData.formatedduration.split(' ');
            let timeAddDuration = parseInt(durationTime) + seconds
            //  console.log('timeAddDuration', timeAddDuration)
            var formatTimeDuration = Math.floor(moment.duration(timeAddDuration, 'seconds').asHours()) + "h" + " " + moment.duration(timeAddDuration, 'seconds').minutes() + "m" + " " + moment.duration(timeAddDuration, 'seconds').seconds() + "s";
            // console.log('format', formatTimeDuration)
            let convertedDateArray = formatTimeDuration && formatTimeDuration.split(' ');
            var endDate;
            if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
                endDate = self.endDatePrepare(convertedDateArray, info.event.start)
            }
            let end = endDate;
            let assetInfo = info.event || {};
            self.setState({ end: end, start: start, duration: duration, assetInfo: assetInfo, programtotalduration: formatTimeDuration }, () => {
                self.setState({ isMetaDataReady: true, assetInfoData: {}, isUpdateEnabled: false })
            })
            let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
            let payload = { assetId: assetId }
            this.props.getPlaybackUrls(payload);
            let formData = {};
            formData["mode"] = "fetch";
            formData["type"] = "segment";
            self.props.fetchMarkers(payload.assetId, formData);
            let body = {
                title: title,
                start: start,
                end: end,
                classNames: ['standaloneAsset'],
                activity: "create",
                extendedProps: {
                    envoiAssetId: assetId,
                    scheduleUUID: info.event.extendedProps.scheduleUUID,

                    type: 'Scheduler',
                    calendarId: self.state.calendarId,
                    //channelid: self.state.calendarId,
                    //assetId: assetId,
                    totalduration: duration,
                    adCount: adCount,
                    programtotalduration: formatTimeDuration,
                    formattedDuration: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration,
                    imageurl: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.landscape_logo_image ? info.event.extendedProps.playlistData.landscape_logo_image : info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.imageurl ? info.event.extendedProps.playlistData.imageurl : defaultLandscape,
                    award: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.award || "",
                    cast: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.cast || "",
                    crew: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.crew || "",
                    writer: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.writer || "",
                    genre: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.genre || "",
                    screening: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.screening || "",
                    tags: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.tags || "",
                    distributer: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.distributer || "",
                    studio: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.studio || "",
                    maturityrating: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.maturityrating || "",
                },
                durationEditable: false,

            }
            // let body = { "activity": "create", start: new Date(start), channelid: this.state.channelId, "assetid": assetId, "image": "https://vlive.imgix.net/screening/images/portrait_logo.jpg", "assetname": assetName }
            // console.log('body', body)
            self.props.manageScheduling(body);
            let obj = this.s3CalendarObject(body);
            eventsData.push(obj);
            // console.log('eventsData', eventsData)
            info.event.remove();
            this.setState({ activity: 'create', eventsData, eventReceived: true, eventbody: body })

            // })
            // .catch((err) => {
            //     console.error.bind("reducer.js embedLinksData", { err });
            // })


        }
        else if (self.state.calendarId) {
            let programtotalduration = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration
            let convertedDateArray = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration && info.event.extendedProps.playlistData.formatedduration.split(' ');
            var endDate;
            if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
                endDate = self.endDatePrepare(convertedDateArray, info.event.start)
            }
            let end = endDate;
            let assetInfo = info.event || {};
            self.setState({ end: end, start: start, duration: duration, assetInfo: assetInfo, programtotalduration }, () => {
                self.setState({ isMetaDataReady: true, assetInfoData: {}, isUpdateEnabled: false })
            })
            let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
            let payload = { assetId: assetId }
            this.props.getPlaybackUrls(payload);
            let formData = {};
            formData["mode"] = "fetch";
            formData["type"] = "segment";
            self.props.fetchMarkers(payload.assetId, formData);
            let body = {
                title: title,
                start: start,
                end: end,

                classNames: ['standaloneAsset'],
                activity: "create",
                extendedProps: {
                    envoiAssetId: assetId,
                    scheduleUUID: info.event.extendedProps.scheduleUUID,
                    totalduration: duration,
                    programtotalduration: duration,
                    formattedDuration: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration,

                    type: 'Scheduler',
                    calendarId: self.state.calendarId,
                    //channelid: self.state.calendarId,
                    //assetId: assetId,
                    imageurl: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.landscape_logo_image ? info.event.extendedProps.playlistData.landscape_logo_image : info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.imageurl ? info.event.extendedProps.playlistData.imageurl : defaultLandscape,
                    award: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.award || "",
                    cast: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.cast || "",
                    crew: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.crew || "",
                    writer: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.writer || "",
                    genre: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.genre || "",
                    screening: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.screening || "",
                    tags: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.tags || "",
                    distributer: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.distributer || "",
                    studio: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.studio || "",
                    maturityrating: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.maturityrating || "",
                },
                durationEditable: false,

            }
            // let body = { "activity": "create", start: new Date(start), channelid: this.state.channelId, "assetid": assetId, "image": "https://vlive.imgix.net/screening/images/portrait_logo.jpg", "assetname": assetName }
            //console.log('body', body)
            self.props.manageScheduling(body);
            let obj = this.s3CalendarObject(body);
            eventsData.push(obj);
            // console.log('eventsData', eventsData)
            info.event.remove();
            this.setState({ activity: 'create', eventsData, eventReceived: true, eventbody: body })
        }
        else {
            // console.log("schedule required !")
            this.setState({ errorMsg: 'Schedule name required!', showAlert: true })
        }
    }
    endDatePrepare(convertedDateArray, startdate) {
        let hrs = convertedDateArray[0]
        hrs = parseInt(hrs.replace("h", ""));
        let mts = convertedDateArray[1]
        mts = parseInt(mts.replace("m", ""));
        let scnds = convertedDateArray[2]
        scnds = parseInt(scnds.replace("s", ""));
        let endDate = new Date(startdate);
        endDate.setSeconds(endDate.getSeconds() + scnds);
        endDate.setMinutes(endDate.getMinutes() + mts);
        endDate.setHours(endDate.getHours() + hrs);
        return endDate
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    searchOnChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        const { assetCount, pageNumber, playlistPageCount, playlistPageNumber, } = this.state;

        this.setState({ [name]: value });
        if (value == '') {
            if (name == 'playlistSearchInput') {
                let playlistPayload = { isActive: true, type: 'envoiPlaylistByUsers', playlistPageCount, playlistPageNumber }
                this.props.playlistAssets(playlistPayload);
            } else if (name == "channelname") {
                let channelPayload = { schedulerProvider: "scheduler" }
                this.props.channelsList(channelPayload);
            } else {
                let payload = { assetCount, pageNumber, }
                this.props.getAssets(payload);
            }
        }
    }

    handleAdvaceSearch() {
        let self = this;
        self.setState({
            isadvancedSearch: true,
        });
    }
    advancedClick() {
        let self = this;
        self.setState({
            isadvancedSearch: false,
        });
    }


    handleChannelAdvaceSearch() {
        let self = this;
        self.setState({
            isChannelAdvSearch: true,
        });
    }
    advancedChannelClick() {
        let self = this;
        self.setState({
            isChannelAdvSearch: false,
        });
    }


    searchForAssets(type) {
        const { assetCount, pageNumber, playlistPageCount, playlistPageNumber, channelPageNumber, channelPageCount } = this.state;
        if (type == 'recordings') {
            this.setState({ recordingAssets: [] });
            let payload = { search: this.state.recodingsSearchInput, assetCount, pageNumber }
            this.props.getAssets(payload);
        } else if (type == 'channels') {
            let channelPayload = { schedulerProvider: "scheduler", search: this.state.channelname, channelPageNumber, channelPageCount }
            this.props.channelsList(channelPayload);
        } else {
            this.setState({ events: [] });
            let payload = { isActive: true, type: 'envoiPlaylistByUsers', search: this.state.playlistSearchInput, playlistPageCount, playlistPageNumber }
            this.props.playlistAssets(payload);
        }

    }

    handleKeyDown(e, type) {
        if (e.key === 'Enter') {
            this.searchForAssets(type);
        }

    }


    calederDataRange = (e) => {
        if (e && e.length > 0) {
            let startDate = moment(e[0]).format('yyyy-MM-DD');
            let endDate = moment(e[1]).format('yyyy-MM-DD');
            this.setState({
                validRange: {
                    start: startDate,
                    end: endDate,
                }
            })
        } else {
            this.setState({
                validRange: {
                }
            })
        }
    }
    deleteAlert = (e) => {
        this.setState({
            showDeleteAlert: true,
            isAddScheduleOpen: false
        });

    }
    addScheduleOpen = (e) => {
        // this.setState({
        //     isAddScheduleOpen: true,
        //     formData: {},
        //     availability: {}

        // });
        // 
        window.location = "/channel"
    }
    editScheduleOpen = (e) => {
        const { calendarId } = this.state;
        //let body = { "activity": "fetch", scheduleId: calendarId }
        // this.props.manageSchedules(body);
        //let payload = { "channelCode": calendarId }
        // this.props.getChannels(payload, true);
        // this.setState({
        //     isAddScheduleOpen: true,

        // });
        window.location = `/channel?channelCode=${calendarId}`;
    }
    closeChannelPopup() {
        const { assetCount, pageNumber } = this.state;
        let type = "envoiPlaylistByUsers"
        let data = { type }
        this.props.getChannels(data, true);
        this.setState({
            isAddScheduleOpen: false,

        });
    }
    handleAvailability(data) {
        let self = this;
        //self.setState(data);
        // console.log("data", data)
        let availability = data.availability ? data.availability : self.state.availability;
        let formData = { ...self.state.formData, ...data };
        // console.log('handleAvailability in channel',getFormData)
        let preview = formData.preview ? formData.preview : self.state.preview;
        self.setState({ formData, availability, executing: false, preview });
    }


    filterChannelByCode = (channelCode) => {
        let self = this;
        const filteredChannel = self.state.schedulesData.filter(
            (channel) => ((channel.channelcode) === channelCode)
        );
        // console.log('filteredChannel', filteredChannel)
        if (filteredChannel && filteredChannel.length > 0) {
            let isMediatailorCreated = filteredChannel[0]['mediatailor'];
            console.log('isMediatailorCreated', isMediatailorCreated)
            if (isMediatailorCreated) {
                this.setState({ isMediatailorCreated, formData: filteredChannel[0], isCreateButton: true });
            } else {
                this.setState({ isMediatailorCreated, formData: filteredChannel[0], isCreateButton: false });
            }

        }
    };

    onChangeCalendarName(e) {
        let self = this;
        let catalogvalues = { ...self.state.formData };
        let name = e.target.name;
        let value = e.target.value;
        if (name == undefined) {
            const time = e;
            let startAt = time ? time.format('HH:mm:ss') : null;
            let durationStart = moment.duration(startAt)
            durationStart = durationStart._milliseconds / 1000;
            catalogvalues['average_ad_duration'] = durationStart
            this.setState({ ['average_ad_duration']: durationStart, formData: catalogvalues });
        } else {
            let errors = Object.assign({}, this.state.errors);
            delete errors[name];
            catalogvalues[name] = value;
            this.setState({ [name]: value, formData: catalogvalues, errors });
            if (name == 'type') {
                let playbackModeVal = "";
                if (value == 'STANDARD') {
                    playbackModeVal = "Linear";
                }
                catalogvalues[name] = value;
                catalogvalues["playbackmode"] = playbackModeVal;
                this.setState({ ["playbackmode"]: playbackModeVal, [name]: value });
                this.setState({ formData: catalogvalues })

            }
            if (name == 'calendarId' && value != "") {
                this.filterChannelByCode(value)
                let body = { "activity": "fetch", calendarId: value }
                this.props.manageScheduling(body);
                this.setState({ actionButton: false });

            } else {
                this.setState({ actionButton: true });
            }
        }
    }

    addSchedule = (e) => {
        let self = this;
        let formData = { ...self.state.formData }

        if (this.validate()) {
            let body;
            if (formData && formData._id) {
                formData["activity"] = "update";
                // formData["scheduleId"] = this.state.calendarId;
                // console.log('formData', formData)
                // // body = {
                // //     title: this.state.calendarName,
                // //     description: this.state.description || '',
                // //     landscape_logo_image: this.state.landscape_logo_image || siteConfig.defaultLandscape,
                // //     portrait_logo_image: this.state.portrait_logo_image || siteConfig.defaultPortrait,
                // //     logoImage: this.state.logoImage || siteSettings.logoImage,
                // //     activity: 'update'
                // // }
                // this.props.manageSchedules(formData);
                let id = formData._id;
                delete formData._id;
                //getFormData = self.state.getFormData
                // getFormData["channelname"] = self.state.channelname;
                // getFormData["serviceprovider"] = 'envoiPlaylistByUsers';
                formData["appname"] = window.app.appName;
                formData["parentAppName"] = window.app.appName;
                //formData['verticalMode'] = formData.verticalMode && formData.verticalMode == 'true' ? "On" : "Off";
                //console.log('formData123', formData)
                // console.log('else condition')
                self.props.manageChannels(formData, id);
                self.SuccessNotify();
                // self.setState({ custom: true, showMessage: "Updated Successfully" });

            } else {
                // formData["portrait_logo_image"] = formData.portrait_logo_image || siteConfig.defaultPortrait;
                // formData["landscape_logo_image"] = formData.landscape_logo_image || siteConfig.defaultLandscape;
                // formData["logoImage"] = formData.logoImage || siteSettings.logoImage;
                formData["activity"] = "create";
                // // body = {
                // //     title: this.state.calendarName,
                // //     description: this.state.description || '',
                // //     landscape_logo_image: formData.landscape_logo_image || siteConfig.defaultLandscape,
                // //     portrait_logo_image: formData.portrait_logo_image || siteConfig.defaultPortrait,
                // //     logoImage: formData.logoImage || siteSettings.logoImage,
                // //     activity: 'create'
                // // }
                // this.props.manageSchedules(formData);
                let siteSettings = window.site || {};
                let siteConfig = siteSettings.config || {};
                let email = window.localStorage.getItem("email");
                let firstname = window.localStorage.getItem("firstname");
                let lastname = window.localStorage.getItem("lastname");
                let userid = window.localStorage.getItem("user_id");
                let name = firstname + " " + lastname;
                formData["serviceprovider"] = "envoiPlaylistByUsers";
                formData["portrait_logo_image"] = formData.portrait_logo_image || siteConfig.defaultPortrait;
                formData["landscape_logo_image"] = formData.landscape_logo_image || siteConfig.defaultLandscape;
                formData["logoImage"] = formData.logoImage || siteSettings.logoImage;
                formData["appname"] = window.app.appName;
                formData["parentAppName"] = window.app.appName;
                formData["status"] = formData.status;
                formData["quickbooksProductType"] = "NonInventory";
                formData["contact"] = { email };
                formData["owner"] = { code: userid, name: name };
                formData['verticalMode'] = formData.verticalMode
                // console.log('formData', formData)
                self.props.manageChannels(formData);
                // self.props.manageChannels(getFormData, self.state.id);
                self.SuccessNotify();

                // self.setState({ custom: true, showMessage: "Updated Successfully" });

            }

            this.setState({ isAddScheduleOpen: false })
        }

    }

    validate() {
        let self = this;
        let isError = true;
        let errors = {};
        const { VodSourceName, SourceLocationName } = this.state.markerData;
        let formData = { ...self.state.formData };
        //console.log("this.state.calendarName", this.state.calendarName)
        // let availability = formData['availability'] || { ...self.state.availability } || {};
        // let buyplans = availability.buyplans || {};
        // if (!availability || ((!availability['free']) && (!availability['buyplans']) && (!availability['publicViewing']))){
        //      self.setState({ custom: true, showMessage: 'Please select Availability Plan' })
        //     //self.errorToast('Please select Availability Plan')
        //     isError = false;
        // }

        // if (buyplans && buyplans.allow == true &&
        //     ((buyplans.monthly && buyplans.monthly.contentPrice && buyplans.monthly.contentPrice.planPrice <= 0) ||
        //         (buyplans.yearly && buyplans.yearly.contentPrice && buyplans.yearly.contentPrice.planPrice <= 0) ||
        //         (buyplans.buy && buyplans.buy.allow == true && !buyplans.buy.contentPrice))) {
        //     self.errorToast('Please Enter Price')
        //     isError = false;
        // }

        if (formData && formData.average_ad_duration == undefined || formData.average_ad_duration == '') {
            isError = false;
            errors.average_ad_duration = "Average Ad Duration Required";
        }
        if (formData && formData.channelname == undefined || formData.channelname == '') {
            isError = false;
            errors.channelname = "Name Required";
        }
        if (formData && formData.status == undefined || formData.status == '') {
            isError = false;
            errors.status = "Status Required";
        }
        if (formData && formData.type == undefined || formData.type == '') {
            isError = false;
            errors.type = "Channel Type Required";
        }
        // if (formData && formData.VodSourceName == '' || formData && formData.VodSourceName == undefined) {
        //     isError = false;
        //     errors.VodSourceName = "Enter VodSourceName";
        // }
        // if (formData && formData.SourceLocationName == '' || formData && formData.SourceLocationName == undefined) {
        //     isError = false;
        //     errors.SourceLocationName = "Enter SourceLocationName";
        // }
        this.setState({ errors });
        return isError;
    }

    scheduleData() {
        let self = this;
        let id = this.state.calendarId
        let payload = {
            schedule: {
                events: self.state.activity == 'create' ? self.state.eventsData : this.state.calendarEvents
            }
        }
        this.props.scheduleEventsToS3(id, payload)
        this.setState({ isMetaDataReady: false })
    }
    onConfirm() {
        this.setState({ custom: false, showMessage: false, showalert: false, addMetadata: false, availabilityError: false, createChannelValidation: false });
    };
    SuccessNotify = () => toast.success('Updated Successfully', window.app.toastConfig)
    errorToast = (msg) => toast.error(msg, window.app.toastConfig)

    uploadS3(e, type) {
        var self = this;
        var fileData = new FormData();
        var file = e.target.files[0];
        var uploadFilePath = "";
        var filename = e.target.files[0].name;
        var s3file = e.target.files[0];
        var filekey = type[2];
        fileData.append(type[1], s3file);
        self.setState({ fileData: file, type, filekey })
        let appName = window.app.appName;
        if (self.state.formData && self.state.formData.channelname && !self.state.formData.channelname == "" || !self.state.formData.channelname == undefined || !self.state.formData.channelname == null) {
            var bucket;
            var imgHight;
            var imgWidth;

            if (type[1] == "Image") {
                if (type[0] == "1000*1294") {
                    imgHight = 1294;
                    imgWidth = 1000;
                } else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "3000*600") {
                    imgHight = 600;
                    imgWidth = 3000;
                }
                else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "175") {
                    //imgHight = 1080;
                    imgWidth = 610;
                }
                let height;
                let width;
                bucket = imageSourcebucket;
                var reader = new FileReader();
                reader.readAsDataURL(s3file);
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        height = this.height;
                        width = this.width;
                        let imagePathWidth;
                        if (imgWidth == "610") {
                            imagePathWidth = width <= imgWidth
                        } else {
                            imagePathWidth = width == imgWidth && height == imgHight
                        }
                        if (imagePathWidth) {
                            bucket = imageSourcebucket;
                            // if (width == imgWidth && height == imgHight) {
                            var path = "submission/partner";
                            let id = self.state.formData && self.state.formData._id;
                            let name = self.state.formData.channelname;
                            name = name && name.toLowerCase();
                            name = name && name.replace(/ /g, "-");
                            name = name && name.replace(/[^a-zA-Z 0-9]/g, "");
                            let folder = name;
                            uploadFilePath = appName + "/" + path + "/" + folder + "/" + removeSpecialCharecters(filename);
                            var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                            self.setState({ uploadFilePath })
                            let siteSettings = window.site;
                            let siteConfig = siteSettings.config;
                            let imagePath = siteConfig && siteConfig.appResourcesUrl;
                            axios.post(envoiSubmissionApiBaseUrl + '/uploadURL?appname=' + appName + '&token=' + token, data, { type: 'application/json' })
                                .then((response) => {
                                    if (response.data && response.data.result) {
                                        var url = response.data.result;
                                        let config = {
                                            onUploadProgress: function (progressEvent) {
                                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                                self.setState({ [type[3]]: percentCompleted });
                                            }
                                        };
                                        axios.put(url, file, config, {
                                            "headers": {
                                                "Content-Type": "multipart/form-data",
                                                "Accept": "/",
                                                "Cache-Control": "no-cache",
                                                "Accept-Encoding": "gzip, deflate",
                                                "Connection": "keep-alive",
                                                "cache-control": "no-cache"
                                            }
                                        })
                                            .then((response) => {
                                                if (response && response.status === 200) {
                                                    var filename = self.state.uploadFilePath;
                                                    let imageUploadPath = imagePath + filename;

                                                    if (imageUploadPath.includes(appName + "/" + appName)) {
                                                        imageUploadPath = imageUploadPath.replace(appName + "/" + appName, appName);
                                                    }
                                                    self.SuccessNotify();
                                                    let obj = {
                                                        [self.state.filekey]: imageUploadPath
                                                    }
                                                    //   console.log('obj', obj.landscape_logo_image)

                                                    if (id) {
                                                        //  console.log('id', id)
                                                        self.props.manageChannels(obj, id);
                                                    } else {
                                                        //console.log('id123123', self.state.formData)
                                                        self.state.formData["landscape_logo_image"] = obj.landscape_logo_image || siteConfig.defaultLandscape;
                                                        //self.props.manageChannels(obj);
                                                    }
                                                    //  self.setState(obj);
                                                }
                                            })
                                            .catch((err) => {
                                                console.error.bind(err);
                                            })
                                    }
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })
                            // } else {
                            //     self.setState({ addMetadata: true, alertMsg: 'Please select image(' + type[0] + ')' });
                            // }
                        } else {
                            let showAlertMsg = 'Please select image(' + type[0] + ')'
                            // self.errorToast(showAlertMsg);
                            self.setState({ addMetadata: true, alertMsg: 'Please select image(' + type[0] + ')' });
                        }
                    };
                }
            } else {
                bucket = assetSourcebucket;

            }
        } else {
            self.setState({ addMetadata: true, alertMsg: '"Please Enter (Title required)' });
        }
    }
    deleteImageFiles(e, item, type) {
        var self = this;
        let selectedType = { "imageType": type }
        this.setState({ assetFile: true, deleteItem: item, selectedType });
    }
    onCancelDelete() {
        this.setState({ assetFile: false, });
    }
    onConfirmDelete() {
        var self = this;
        let imageType = this.state.selectedType && this.state.selectedType.imageType ? this.state.selectedType.imageType : 'none'
        let siteSettings = window.site;
        let siteConfig = siteSettings.config || {};
        let id = self.state.formData && self.state.formData._id;
        // if (self.state.deleteItem && self.state.deleteItem == "landscape_logo_image") {
        //     self.setState({ landscape_logo_image: undefined, })
        //     toast.success('Deleted Successfully', window.app.toastConfig)
        // } else if (self.state.deleteItem && self.state.deleteItem == "portrait_logo_image") {
        //     self.setState({ portrait_logo_image: undefined })
        //     toast.success('Deleted Successfully', window.app.toastConfig)
        // }
        // else {
        //     self.setState({
        //         logoImage: '',
        //     })
        //     toast.success('Deleted Successfully', window.app.toastConfig)

        // }
        let formData = {};
        if (self.state.deleteItem && self.state.deleteItem == "landscape_logo_image") {
            formData["landscape_logo_image"] = siteConfig.defaultLandscape + siteConfig.imageSuffix;
            self.setState({
                custom: true, isAddScheduleOpen: false, showMessage: "Deleted Successfully"
            })
            //toast.success('Deleted Successfully', window.app.toastConfig)
        } else if (self.state.deleteItem && self.state.deleteItem == "portrait_logo_image") {
            formData["portrait_logo_image"] = siteConfig.defaultPortrait + siteConfig.imageSuffix;
            self.setState({
                custom: true, isAddScheduleOpen: false, showMessage: "Deleted Successfully"
            })
            // toast.success('Deleted Successfully', window.app.toastConfig)
        }
        else {
            formData["logoImage"] = '';
            self.setState({
                custom: true, isAddScheduleOpen: false, showMessage: "Deleted Successfully"
            })
            //toast.success('Deleted Successfully', window.app.toastConfig)
        }
        self.setState({
            assetFile: false,
            [imageType]: 0
        });
        self.props.manageChannels(formData, id);
    }


    handlePlayistByIdExpand(uuid) {
        let self = this;
        self.setState({ [uuid]: !self.state[uuid] });
    }

    playerTimeChanged = (currentSeektime) => {
        let self = this;
        self.setState({ currentSeektime: currentSeektime });
    }
    closePlayerClick() {
        let self = this;
        if (player) {
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            playerDestroy();
            player = null;
            self.setState({ isOpen: false });
        }
    }
    handleChange = (e) => {
        let self = this;
        const { start, end, extendedProps } = this.state.assetInfo;
        // console.log("extendedProps", extendedProps, this.state.assetInfo)
        let assetInfoData = this.state.assetInfoData;

        // console.log("assetInfoData ", assetInfoData)
        assetInfoData[e.target.name] = e.target.value;
        let calendarEvents = self.state.calendarEvents
        // console.log("calendarEvents ", calendarEvents)
        let id = extendedProps && extendedProps.playlistData && extendedProps.playlistData.id;
        let filteredAsset = calendarEvents.filter(function (item) {
            return item['extendedProps']["envoiAssetId"] == extendedProps["envoiAssetId"] || item['extendedProps']["envoiAssetId"] == id
        });
        // console.log("self.state.programtotalduration ", self.state.programtotalduration)

        // let convertedDateArray = filteredAsset && filteredAsset.length > 0 && filteredAsset[0] && filteredAsset[0].extendedProps && filteredAsset[0].extendedProps.formattedDuration && filteredAsset[0].extendedProps.formattedDuration.split(' ');
        // console.log("convertedDateArray", convertedDateArray, e.target.value)
        let convertedDateArray = self.state.programtotalduration && self.state.programtotalduration.split(' ') ? self.state.programtotalduration.split(' ') : extendedProps && extendedProps.formattedDuration && extendedProps.formattedDuration.split(' ')
        // console.log("convertedDateArray", convertedDateArray)
        let endDate = self.endDatePrepare(convertedDateArray, e.target.value)
        let endd = endDate;
        assetInfoData["end"] = moment(endd).format("YYYY-MM-DD[T]HH:mm:ss")
        // console.log("endd ", endd)
        self.setState({ assetInfoData, isUpdateEnabled: true });

    }

    handleEndDateChange = (e) => {
        let self = this;
        let assetInfoData = this.state.assetInfoData;
        assetInfoData[e.target.name] = e.target.value;
        self.setState({ assetInfoData, isUpdateEnabled: true });
    }
    importStartTimeChange(e) {
        let self = this;
        self.setState({ importStartTimeValue: e.target.value });
    }
    importStartTimeOpen() {
        this.setState({ importStartTimePopup: !this.state.importStartTimePopup })
    }
    startTimeChange(e) {
        let self = this;
        let dateValueData = this.state.dateValueData;
        dateValueData[e.target.name] = e.target.value;
        // console.log("dateValueData",dateValueData.start)
        let playlistValue = new Date(dateValueData.start.toString())
        self.setState({ startTimeValue: dateValueData.start, playlistValue, start: dateValueData.start, dateValueData });
    }
    endTimeChange(e) {
        let self = this;
        let dateValueData = this.state.dateValueData;
        dateValueData[e.target.name] = e.target.value;
        self.setState({ endTimeValue: dateValueData.end, dateValueData });
    }
    playlistEventData() {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let appStorage = appSettings.storage;
        let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
        const { playlistData, startTimeValuePlaylist, playlistValue } = self.state;
        //  console.log("startTimeValuePlaylist", startTimeValuePlaylist);
        // console.log("playlistData start", playlistData);
        //let startChangeValue;
        let startDate;
        if (playlistValue) {
            startDate = playlistValue
            //  console.log("startChangeValue", startChangeValue);
        } else {
            startDate = playlistData.start;
        }


        let playlistAssets = playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.assets && playlistData.extendedProps.playlistData.assets[0];
        let startDateEvent = new Date(startDate.toString())
        // console.log("startDateEvent1234566", startDateEvent);
        let durationTime = playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.totalAssetsduration
        let title = playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.title
        let imageurl = playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.imageurl
        //let created = playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.created
        let uuid = playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.uuid
        let body = {
            title: title,
            start: new Date(startDate.toString()),
            classNames: ['standaloneAsset'],
            activity: "create",
            extendedProps: {
                envoiAssetId: uuid,
                scheduleUUID: playlistData.extendedProps && playlistData.extendedProps && playlistData.extendedProps.scheduleUUID,
                type: 'Scheduler',
                calendarId: self.state.calendarId,
                imageurl: imageurl ? imageurl : defaultLandscape,
                formattedDuration: durationTime,
                playlistDuration: durationTime,
                playlistSchedule: true,
                assets: playlistData.extendedProps && playlistData.extendedProps.playlistData && playlistData.extendedProps.playlistData.assets && playlistData.extendedProps.playlistData.assets
            },
            durationEditable: false,
        }
        //body["start"] = startDateEvent
        //  console.log('startDateEvent', startDateEvent)
        let convertedDateArray = durationTime && durationTime.split(' ');
        //  console.log('convertedDateArray', convertedDateArray)
        let endDateValue = startDateEvent;
        // console.log('endDate', endDateValue)

        if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
            let hrs = convertedDateArray[0]
            hrs = parseInt(hrs.replace("h", ""));
            let mts = convertedDateArray[1]
            mts = parseInt(mts.replace("m", ""));
            let scnds = convertedDateArray[2]
            scnds = parseInt(scnds.replace("s", ""));
            endDateValue.setSeconds(endDateValue.getSeconds() + scnds);
            endDateValue.setMinutes(endDateValue.getMinutes() + mts);
            endDateValue.setHours(endDateValue.getHours() + hrs);
            // console.log('endDateValue', endDateValue)
        }
        let end = endDateValue;
        // console.log('end', end)
        body["end"] = end
        // console.log('body', body)
        let assetInfo = playlistData || {};
        self.setState({ end: end, assetInfo: assetInfo, programtotalduration: durationTime, formattedDuration: durationTime, startTimeValuePlaylist }, () => {
            self.setState({ isMetaDataReady: true, assetInfoData: {}, isUpdateEnabled: false, startTimePopup: false })
        })
        //let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
        let payload = { assetId: playlistAssets.assetid }
        this.props.getPlaybackUrls(payload);
        let formData = {};
        formData["mode"] = "fetch";
        formData["type"] = "segment";
        self.props.fetchMarkers(payload.assetId, formData);

        let eventsApi = {
            events: [body]
        }
        // self.props.manageScheduling(body);
        this.setState({ eventReceived: true, eventbody: body })
        let calendarId = playlistData && playlistData.extendedProps && playlistData.extendedProps.calendarId || "";
        let scheduleId = playlistData && playlistData.extendedProps && playlistData.extendedProps.scheduleUUID || "";
        let url = envoiSubmissionApiBaseUrl + "/scheduleimport?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
        calendarId ? (url += `&calendarId=${calendarId}`) : null;
        scheduleId ? (url += `&scheduleId=${scheduleId}`) : null;

        axios.post(url, eventsApi, { type: 'application/json' })
            .then((response) => {
                if (response.data && response.data.statusCode == "200") {
                    let playlistBody = { "activity": "fetch", calendarId }
                    this.props.manageScheduling(playlistBody);
                }
            })

            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
        // let obj = this.s3CalendarObject(body);
        // eventsData.push(obj);
        // console.log('eventsData', eventsData)
        // info.event.remove();
        //  this.setState({ activity: 'create', eventReceived: true, eventbody: body })
    }
    addEventData() {
        let self = this;
        let date = new Date();
        //  console.log("startTimeValue", self.state.startTimeValue);
        let featuredDate = moment(date).format("YYYY-MM-DD");
        let dateValue = moment(self.state.startTimeValue).format("YYYY-MM-DD");
        let info = this.state.assetInfo;
        let dateValueData = this.state.dateValueData;

        if (dateValue < featuredDate) {
            this.setState({ channelTimePopup: true })
            self.errorToast("Note: Start date must be greater than to current date");
        }
        else if (!dateValueData.start) {
            this.setState({ channelTimePopup: true })
            self.errorToast("Note: Availability start and end dates is not avaialble. please add availability dates");
        }
        else {
            this.setState({ channelTimePopup: false })
            let title = info.title;
            let assetId = info.id;
            let start = dateValueData.start;
            //let end = endDate;
            let end = dateValueData.end;
            //let assetInfo = info.event || {};
            self.setState({ end: end, start: start, assetInfo: info }, () => {
                self.setState({ isMetaDataReady: true, assetInfoData: {}, isUpdateEnabled: false })
            })
            let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
            //let payload = { assetId: assetId }
            //let playbackUrls = true;
            self.props.channelAssets(assetId)
            // this.props.getPlaybackUrls(payload);
            // let formData = {};
            // formData["mode"] = "fetch";
            // formData["type"] = "segment";
            // self.props.fetchMarkers(payload.assetId, formData);
            let body = {
                title: title,
                start: start,
                end: end,
                // totalduration: duration,
                // programtotalduration: formatTimeDuration,
                // formatedduration: info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration,
                classNames: ['standaloneAsset'],
                extendedProps: {
                    envoiAssetId: assetId,
                    scheduleUUID: info.extendedProps.scheduleUUID,
                    serviceProvider: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.serviceProvider,
                    imageurl: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.landscape_logo_image ? info.extendedProps.playlistData.landscape_logo_image : info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.imageurl ? info.extendedProps.playlistData.imageurl : defaultLandscape,
                    award: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.award || "",
                    cast: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.cast || "",
                    crew: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.crew || "",
                    writer: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.writer || "",
                    genre: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.genre || "",
                    screening: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.screening || "",
                    tags: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.tags || "",
                    distributer: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.distributer || "",
                    studio: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.studio || "",
                    maturityrating: info && info.extendedProps && info.extendedProps.playlistData && info.extendedProps.playlistData.maturityrating || "",

                    type: 'Scheduler',
                    calendarId: self.state.calendarId,
                    liveChannel: true || "true"
                },
                durationEditable: false,
                activity: "create",
                //channelid: self.state.calendarId,
                //assetid: assetId,

            }
            // // let body = { "activity": "create", start: new Date(start), channelid: this.state.channelId, "assetid": assetId, "image": "https://vlive.imgix.net/screening/images/portrait_logo.jpg", "assetname": assetName }
            // //console.log('body', body)
            self.props.manageScheduling(body);
            let obj = this.s3CalendarObject(body);
            eventsData.push(obj);
            //  console.log('eventsData', eventsData)
            info.remove();
            this.setState({ activity: 'create', eventsData })
        }

    }
    filterScheduleByUUID = (scheduleId) => {
        let self = this;
        const filteredSchedule = self.state.allSchedulesData.filter(
            (schedule) => ((schedule.extendedProps && schedule.extendedProps.scheduleUUID) === scheduleId)
        );
        if (filteredSchedule && filteredSchedule.length > 0) {
            let scheduleId = filteredSchedule[0]['_id']
            return scheduleId;
        }


    };

    updateScheduleData = () => {
        let self = this;
        let eventPopDataFlag;
        // console.log("asset info....", this.state.assetInfo)
        const { start, end, extendedProps } = this.state.assetInfo;
        let eventReceivedFlag = this.state.eventReceived
        // console.log("extendedProps....", extendedProps, eventReceivedFlag)
        if (self.state.eventPopData) {
            eventPopDataFlag = [...this.state.eventPopData]

        }

        //  console.log("extendedProps....", extendedProps, eventReceivedFlag)
        //  console.log("eventPopDataFlag....", eventPopDataFlag)
        let assetInfoData = this.state.assetInfoData;
        //  console.log("assetInfoData....", this.state.calendarEvents, this.state.eventsData)
        let scheduleId;
        let scheduleUUID = this.filterScheduleByUUID(extendedProps.scheduleUUID || extendedProps.scheduleId)
        if (extendedProps.playlistData && extendedProps.playlistData.uuid) {
            scheduleId = extendedProps && extendedProps.playlistData && extendedProps.playlistData.uuid ? extendedProps.playlistData.uuid : extendedProps && extendedProps.scheduleId
        } else {
            scheduleId = extendedProps && extendedProps.playlistData && extendedProps.playlistData.scheduleId ? extendedProps.playlistData.scheduleId : extendedProps && extendedProps.scheduleId
        }

        let body = {
            start: assetInfoData.start ? assetInfoData.start : start,
            end: assetInfoData.end ? assetInfoData.end : end ? end : this.state.end,
            activity: "update",
            scheduleId: scheduleId ? scheduleId : scheduleUUID,
            calendarId: extendedProps.calendarId,
        }

        if (eventReceivedFlag == true) {
            body["scheduleId"] = eventPopDataFlag[0]._id
        }
        // console.log("body....", body)
        this.setState({ activity: 'update', eventReceived: false }, () => {
            self.props.manageScheduling(body);
        })
    }

    deleteSchedule = () => {
        this.setState({ showDeleteEventAlert: true, })
    }

    onConfirmScheduleDelete = () => {
        let self = this;
        const { extendedProps } = self.state.assetInfo;
        //  console.log('extendedProps',extendedProps)
        let scheduleUUID = this.filterScheduleByUUID(extendedProps.scheduleUUID || extendedProps.scheduleId)
        //console.log('scheduleUUID',scheduleUUID)
        let body = {
            activity: "delete",
            scheduleId: extendedProps.scheduleId ? extendedProps.scheduleId : scheduleUUID,
            calendarId: extendedProps.calendarId,
        }
        this.setState({ showDeleteEventAlert: false, isMetaDataReady: false, activity: 'delete' }, () => {
            self.props.manageScheduling(body);
        })

        self.state.assetInfo && self.state.assetInfo.remove();
    }
    minutesPrepare(time) {
        let convertedDateArray = time && time.split(' ')
        // console.log("time ", convertedDateArray)
        let hrs = convertedDateArray[0]
        hrs = parseInt(hrs.replace("h", ""));
        let mts = convertedDateArray[1]
        mts = parseInt(mts.replace("m", ""));
        let scnds = convertedDateArray[2]
        scnds = parseInt(scnds.replace("s", ""));
        let hrsmin = hrs * 60
        let scndsmin = scnds / 60
        // console.log("hrsmin ", hrsmin)
        // console.log("scndsmin ", scndsmin)
        // console.log("mts ", mts)
        let totalmts = hrsmin + mts
        totalmts = totalmts + scndsmin
        // console.log("totalmts ", Math.round(totalmts))
        return Math.round(totalmts)
    }






    createMediatailor() {
        let self = this;
        let totalMinutes = 0;
        let user_id = localStorage.getItem('user_id');
        let siteSettings = window.site;
        let siteConfig = siteSettings && siteSettings.config;
        let payload
        let assetsData = this.props.manageSchedulingData || [];
        assetsData.forEach(item => {
            if (item && item.extendedProps && item.extendedProps.formattedDuration) {
                totalMinutes = totalMinutes + self.minutesPrepare(item.extendedProps.formattedDuration)

            }
        });
        console.log("self.state.formData", self.state.formData)
        // self. minutesPrepare
        //   console.log('assetsData', assetsData)
        // const timeString = assetsData[0] && assetsData[0].totalduration;
        // const totalMinutes = timeToMinutes(timeString);
        // console.log("Total minutes:", totalMinutes);
        // var minutes = assetsData[0] && assetsData[0].totalduration % 60
        //  console.log('assetsData', minutes)
        // console.log('assetsData', assetsData)
        // console.log('this.state.calendarId', this.state.calendarId)
        // console.log('self.state.formData.status', self.state.formData.status)
        // self.validate() && this.state.calendarId && assetsData.length > 0 && self.state.formData && self.state.formData.status != "Inactive"
        if (this.state.calendarId && assetsData.length > 0 && self.state.formData) {
            let scheduleAssetDuration;
            if (totalMinutes && totalMinutes.toString() != "0") {
                scheduleAssetDuration = totalMinutes && totalMinutes.toString()
            }
            if (self.state.formData && self.state.formData.type == "STANDARD") {
                payload = {
                    channelCode: this.state.calendarId,
                    // assetId: assetsData[0].extendedProps && assetsData[0].extendedProps.envoiAssetId,
                    // proxyPath: siteConfig && siteConfig.proxiesCloudFront ? siteConfig.proxiesCloudFront : '',
                    tier: self.state.formData && self.state.formData.type,
                    playbackMode: self.state.formData && self.state.formData.playbackmode,
                    Tags: {
                        channelId: this.state.calendarId,
                        // calendarId: this.state.calendarId,
                        scheduleId: assetsData[0].extendedProps && assetsData[0].extendedProps.scheduleUUID,
                        scheduleDuration: scheduleAssetDuration,
                        averageAd: self.state.formData && self.state.formData.average_ad_duration,
                        appName: siteSettings && siteSettings.appname,
                        userId: user_id

                    },
                    FillerSlate: {
                        SourceLocationName: self.state.formData && self.state.formData.SourceLocationName,
                        VodSourceName: self.state.formData && self.state.formData.VodSourceName
                    },
                }
                //  console.log('payload', payload)
                this.props.createMediatailorChannel(payload)
            } else {
                if (self.state.formData && self.state.formData.playbackmode != "LOOP") {
                    payload = {
                        channelCode: this.state.calendarId,
                        tier: self.state.formData && self.state.formData.type,
                        playbackMode: self.state.formData && self.state.formData.playbackmode,
                        Tags: {
                            channelId: this.state.calendarId,
                            // calendarId: this.state.calendarId,
                            scheduleId: assetsData[0].extendedProps && assetsData[0].extendedProps.scheduleUUID,
                            scheduleDuration: scheduleAssetDuration,
                            averageAd: self.state.formData && self.state.formData.average_ad_duration,
                            appName: siteSettings && siteSettings.appname,
                            userId: user_id
                        },
                        FillerSlate: {
                            SourceLocationName: self.state.formData && self.state.formData.SourceLocationName,
                            VodSourceName: self.state.formData && self.state.formData.VodSourceName
                        }
                    }
                } else {
                    payload = {
                        channelCode: this.state.calendarId,
                        tier: self.state.formData && self.state.formData.type,
                        playbackMode: self.state.formData && self.state.formData.playbackmode,
                        Tags: {
                            channelId: this.state.calendarId,
                            // calendarId: this.state.calendarId,
                            scheduleId: assetsData[0].extendedProps && assetsData[0].extendedProps.scheduleUUID,
                            scheduleDuration: scheduleAssetDuration,
                            averageAd: self.state.formData && self.state.formData.average_ad_duration,
                            appName: siteSettings && siteSettings.appname,
                            userId: user_id
                        },
                    }
                }

                console.log('payload', payload)
                this.props.createMediatailorChannel(payload)
            }
            // this.props.createMediatailorChannel(payload)
        } else {
            self.setState({
                createChannelValidation: true

            })

        }



    }
    videoClose() {
        var self = this;
        self.setState({
            playVideoTab: !self.state.playVideoTab

        })
        this.killplayer();
    }
    killplayer() {
        if (player) {
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            playerDestroy();
            player = null;
        }
    }
    mediaTailorStartButton(e, mtType, ChannelName) {
        let self = this;
        const { assetCount, pageNumber } = this.state;
        let type;
        self.setState({ loader: true });
        if (mtType) {
            type = mtType
        } else {
            type = "stop"
        }
        //let channelAssetData = self.state.partnerData || {};
        let token = appStorage.getItem('token');
        axios.post(envoiSubmissionApiBaseUrl + '/mtChannel?appname=' + window.app.appName + '&token=' + token + '&env=' + window.app.appEnvName + '&channelcode=' + this.state.calendarId + '&mtChannelName=' + ChannelName + '&mtType=' + type)
            .then((response) => {
                if (response && response.data && response.data.result) {
                    self.setState({ isMediatailorCreated: response.data.result, loader: false });
                }
                let type = "envoiPlaylistByUsers"
                let data = { type }
                self.props.getChannels(data, false);
            })
            .catch(error => error)
    }
    openSegmentPopup() {
        this.setState({ isSegmentPopup: true })
    }
    openDownloadPopup() {
        this.setState({ isDownloadPopup: !this.state.isDownloadPopup })
    }
    openStartTimePopup() {
        this.setState({ startTimePopup: !this.state.startTimePopup })
        this.playlistEventData()
    }
    playlistStartTime() {
        this.setState({ startTimePopup: true })
    }
    navToEditPlaylist(obj) {
        // window.location = `/newplaylist/${obj.uuid}`;
        window.location = `/newplaylist/?id=${obj.uuid}`;
    }
    openChannelTimePopup() {
        this.setState({ channelTimePopup: !this.state.channelTimePopup })
    }
    downloadJson(e) {
        e.preventDefault()
        const myData = this.state.eventsData;
        const fileName = "events";
        const json = JSON.stringify(myData);
        const blob = new Blob([json], { type: 'application/json' })
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    downloadCsv(e) {
        e.preventDefault()
        const myData = this.state.eventsData;
        let headers = [Object.keys(myData[0])]
        // Convert events data to a csv
        let eventsCsv = myData.reduce((acc, item) => {
            let { title, start, end, classNames, extendedProps, durationEditable } = item

            if (typeof extendedProps == 'object') {
                var str = '';
                //console.log("Object.keys(extendedProps).length ", extendedProps)
                // console.log("Object.keys(extendedProps).length ", Object.keys(extendedProps).length)
                Object.keys(extendedProps).forEach(key => {
                    var line = '';
                    if (extendedProps[key]) {
                        line += key + " : " + extendedProps[key] + ";  ";
                    }
                    str += line;
                });
                extendedProps = str
            }
            //  console.log("extendedProps", extendedProps)

            acc.push([title, start, end, classNames[0], extendedProps, durationEditable].join(','))
            return acc
        }, [])
        let data = [...headers, ...eventsCsv].join('\n')

        const blob = new Blob([data], { type: 'text/csv' })
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = "events.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    setStartOpen = ({ open }) => {
        let self = this;
        this.setState({ isStartOpen: open });
    };

    setEndOpen = ({ open }) => {
        let self = this;
        this.setState({ isEndOpen: open });
    };


    onChangeSegment(e) {
        const name = e.target && e.target.name;
        const value = e.target && e.target.value;
        let segment = this.state.segmentData;
        if (name == undefined) {
            const time = e;
            let startAt = time ? time.format('HH:mm:ss') : null;
            let durationStart = moment.duration(startAt)
            durationStart = durationStart._milliseconds / 1000;
            segment['segmentDuration'] = durationStart
            this.setState({ ['segmentDuration']: durationStart, segmentData: segment });
        } else {
            if (!!this.state.errors[name]) {
                let errors = Object.assign({}, this.state.errors);
                delete errors[name];
                segment[name] = value
                // console.log('segment121',segment)
                this.setState({
                    [name]: value,
                    segmentData: segment,
                    errors
                });
            } else {
                segment[name] = value
                //  console.log('segment',segment)
                this.setState({ [name]: value, segmentData: segment });
            }
        }
    }
    onChangeMarker(e) {
        const name = e.target.name;
        const value = e.target.value;

        let marker = this.state.markerData;
        if (!!this.state.errors[name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[name];
            marker[name] = value
            this.setState({
                [name]: value,
                markerData: marker,
                errors
            });
        } else {
            marker[name] = value
            this.setState({ [name]: value, markerData: marker });
        }
    }


    onStartTimeChange = (time) => {
        let self = this;
        const { duration } = this.state.currentSegment;
        const { durationInTime } = this.state;
        let errorsObj = { ...self.state.errors };
        let marker = this.state.markerData;
        let startAt = time ? time.format('HH:mm:ss') : null;
        let durationStart = moment.duration(startAt)
        durationStart = durationStart._milliseconds / 1000;
        marker["start"] = time;
        marker["seektime"] = durationStart;
        this.setState({ marker, errors: errorsObj })
    }

    onEndTimeChange = (time) => {
        let self = this;
        const { duration } = this.state.currentSegment;
        const { durationInTime } = this.state;
        let errorsObj = { ...self.state.errors };
        let marker = this.state.markerData;
        let endAt = time ? time.format('HH:mm:ss') : null;
        let durationEndAt = moment.duration(endAt)
        durationEndAt = durationEndAt._milliseconds / 1000;
        marker["end"] = time;
        marker["endtime"] = durationEndAt;
        this.setState({ marker, errors: errorsObj })
    }

    handleChangeComplete(color) {
        let self = this;
        let markerValue = color.hex;
        let markerData = Object.assign({}, this.state.markerData);
        markerData["markerColor"] = markerValue;
        self.setState({ markerData, markerColor: markerValue });
    };

    validateMarker() {
        let isError = false;
        const { title, duration, comment, end, start, VodSourceName, SourceLocationName } = this.state.markerData;
        let errors = {};

        if (title === '' || title === undefined) {
            isError = true;
            errors.title = "Enter Name";
        }
        if (duration === '' || duration === undefined) {
            isError = true;
            errors.duration = "Enter Duration";
        }

        if (comment === '' || comment === undefined) {
            isError = true;
            errors.comment = "Enter Comment";
        }

        if (start === '' || start === undefined) {
            isError = true;
            errors.start = "Enter start time";
        }
        if (end === '' || end === undefined) {
            isError = true;
            errors.end = "Enter end time";
        }
        if (VodSourceName === '' || VodSourceName === undefined) {
            isError = true;
            errors.VodSourceName = "Enter VodSourceName";
        }
        if (SourceLocationName === '' || SourceLocationName === undefined) {
            isError = true;
            errors.SourceLocationName = "Enter SourceLocationName";
        }


        this.setState({ errors });
        return isError;
    };

    validateSegment() {
        let isError = false;
        let errors = {};
        const { title, duration } = this.state.segmentData;
        if (title === '' || title === undefined) {
            isError = true;
            errors.title = "Enter Name";
        }
        if (duration === '' || duration === undefined) {
            isError = true;
            errors.duration = "Enter Duration";
        }
        this.setState({ errors });
        return isError;
    };
    addSegment() {
        let self = this;
        let segment = this.state.segmentData || {};
        let assetInfo = this.state.assetInfo || {}
        //  console.log('segment',segment)
        if (!this.validateSegment()) {
            let payload = {
                mode: 'insert',
                type: 'segment',
                title: segment.title,
                duration: segment.duration,
                start: segment.start
            }
            // console.log('payload', payload)
            // console.log('assetInfo',assetInfo)
            let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
            self.props.inserAssetMarkers(assetId, payload);
            this.setState({ isSegmentPopup: false, isSementsLoading: true })

        }
    }

    collapseAndExpand = (index) => {
        this.setState({ ['isExpand' + index]: !this.state['isExpand' + index] })
    }

    openMarkerPopup = (segment) => {
        //   console.log("segment", segment)
        // let formattedDuration = segment.duration && moment.duration(segment.duration, 'seconds');
        // let durationAt = formattedDuration && formattedDuration.format("HH:mm:ss", { trim: false });
        // durationAt = durationAt && moment(durationAt, 'HH:mm:ss');
        // durationAt = durationAt && durationAt._i;
        this.setState({ isMarkerPopup: true, segmentId: segment._id, currentSegment: segment })
    }

    addMarker() {
        let self = this;
        let marker = this.state.markerData || {};
        if (!this.validateMarker()) {
            let payload = {
                mode: 'insert',
                title: marker.title,
                seektime: this.state.currentSeektime || marker.seektime,
                start: marker.seektime,
                end: marker.endtime || "1",
                duration: marker.duration,
                comment: marker.comment,
                segmentId: this.state.segmentId
            }
            let assetInfo = this.state.assetInfo || {}
            let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
            self.props.inserAssetMarkers(assetId, payload);
            this.setState({ isMarkerPopup: false, isSementsLoading: true })
        }
    }

    deleteSegmentORMarker = (id, type) => {
        this.setState({ showDeletePopup: true, deleteMarkerId: id, deleteMarkerType: type }, () => {

        })
    }

    onConfirmDeleteMarker() {
        let payload = {
            mode: "delete",
            markerid: this.state.deleteMarkerId
        }
        let assetInfo = this.state.assetInfo || {}
        let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
        this.props.deleteAssetMarkers(assetId, payload);
        this.setState({ showDeletePopup: false })
    }
    onConfirmDeleteChannel() {
        let token = appStorage.getItem('token');

        let channelid = this.state.formData.channelcode
        let url = envoiSubmissionApiBaseUrl + "/scheduleChannelDelete?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
        channelid ? (url += `&channelid=${channelid}`) : null;
        // console.log("url ", url)

        axios.post(url, { type: 'application/json' })
            .then((response) => {
                // console.log("response ", response.data)
                this.setState({ showDeleteAlert: false })
                if (response.data && response.data.statusCode == "200") {
                    this.setState({ showDeleteAlert: false, showAlert: true, errorMsg: "Channel Deleted" })

                } else {

                    this.setState({ showAlert: true, errorMsg: response.data.error })
                }
            })
            .catch((err) => {
                console.error.bind("schedule channel delete ", { err });
                this.setState({ showAlert: true, errorMsg: err })
            })

    }
    successPopup() {
        let self = this;
        const { assetCount, pageNumber } = this.state;
        console.log("errorMsg", self.state.errorMsg)
        if (self.state.errorMsg == 'Mediatailor channel created successfully') {
            self.setState({ errorMsg: "", showAlert: false, isCreateButton: true })
        } else {
            self.setState({ errorMsg: "", showAlert: false, isCreateButton: false })
        }
        // 
        let type = "envoiPlaylistByUsers"
        let data = { type }
        this.props.getChannels(data, true);
    }
    // successPopup1() {
    //     let self = this;
    //    // const { assetCount, pageNumber } = this.state;
    //     self.setState({ errorMsg: "", showAlertMessage: false })
    //     // let type = "envoiPlaylistByUsers"
    //     // let data = { type }
    //     // this.props.getChannels(data, true);
    // }



    async handleFiles(event) {
        var self = this;
        var file = event[0];
        var fileExt = file && file.name && file.name.split('.').pop();
        var reader = new FileReader();
        reader.onload = function (event) {
            let assetsData;
            let data = event.target.result;
            switch (fileExt) {
                case 'csv':
                    csv({})
                        .fromString(data)
                        .then((csvRow) => {
                            assetsData = csvRow;
                            self.setState({ loader: true, fileType: fileExt });
                            self.handleDisplay(assetsData)
                        })
                    break;
                case 'xlsx':
                case 'xls':
                    let workbook = XLSX.read(data, { type: "binary" });
                    workbook.SheetNames.forEach(sheet => {
                        assetsData = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
                        self.setState({ loader: true, fileType: fileExt });
                        self.handleDisplay(assetsData)
                    });
                    break;
                case 'json':
                    assetsData = data;
                    self.setState({ loader: true, fileType: fileExt });
                    self.handleDisplay(assetsData)
                    break;
            }
        };
        if (fileExt == 'json') {
            reader.readAsText(file);
        } else {
            reader.readAsBinaryString(file);
        }
    }


    handleDisplay(assetsList) {
        let self = this;
        //console.log("assetsList", assetsList)
        self.setState({ assetsList, loader: false });
        let importAsset = JSON.stringify(assetsList)
        //console.log("importAsset", importAsset)
        if (assetsList && assetsList.length > 0) {
            let eventList = [];
            // let startDateEvent = start;
            let startDateSelectedPopup = new Date(self.state.importStartTimeValue)
            assetsList.map((element, i) => {
                let obj = Object.assign({});
                let dur = element && element.formattedDuration && element.formattedDuration.split(' ') || 0
                if (i == 0) {
                    //console.log("startDateSelectedPopup i ", startDateSelectedPopup)

                    let endDate = self.endDatePrepare(dur, startDateSelectedPopup)
                    element["start"] = startDateSelectedPopup
                    element["end"] = endDate
                    startDateSelectedPopup = endDate
                } else {
                    //console.log("startDateSelectedPopup i ",i, startDateSelectedPopup)
                    let endDate = self.endDatePrepare(dur, startDateSelectedPopup)
                    element["start"] = startDateSelectedPopup
                    element["end"] = endDate
                    startDateSelectedPopup = endDate
                }

                //console.log("elementee ", element)

                obj = {
                    title: element.title,
                    start: element.start,
                    end: element.end,

                    classNames: ['standaloneAsset'],
                    extendedProps: {
                        envoiAssetId: element.assetid,
                        scheduleUUID: element.scheduleId,
                        calendarId: this.state.calendarId,
                        // channelid: this.state.calendarId
                        formattedDuration: element.formattedDuration || 0,
                        type: 'Scheduler',
                    },
                    durationEditable: false,
                    activity: "create"


                    // assetid: element.assetid
                };

                eventList.push({ ...obj })
            })

            let eventsApi = {
                events: eventList
            }


            //     events = events[events]
            //   ]
            let token = appStorage.getItem('token');
            let calendarId = this.state.calendarId || "";
            let scheduleId = this.state.scheduleUUID || "";
            let url = envoiSubmissionApiBaseUrl + "/scheduleimport?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
            calendarId ? (url += `&calendarId=${calendarId}`) : null;
            scheduleId ? (url += `&scheduleId=${scheduleId}`) : null;

            axios.post(url, eventsApi, { type: 'application/json' })
                .then((response) => {
                    self.importStartTimeOpen()
                    if (response.data && response.data.statusCode == "200") {
                        let body = { "activity": "fetch", calendarId }
                        this.props.manageScheduling(body);
                    }
                })

                .catch((err) => {
                    console.error.bind("reducer.js embedLinksData", { err });
                })
        }

    }


    handlePaste() {
        let self = this;
        const { start, end, extendedProps } = this.state.assetInfo;
        //console.log("extendedProps", extendedProps)
        let assetInfoData = self.state.assetInfoData;

        // console.log("assetInfoData ", assetInfoData)
        assetInfoData["start"] = self.state.copytext;

        let calendarEvents = self.state.calendarEvents
        //console.log("calendarEvents ", calendarEvents)
        let id = extendedProps && extendedProps.playlistData && extendedProps.playlistData.id;
        let filteredAsset = calendarEvents.filter(function (item) {
            return item['extendedProps']["envoiAssetId"] == extendedProps["envoiAssetId"] || item['extendedProps']["envoiAssetId"] == id
        });
        // console.log("filteredAsset ", filteredAsset)

        // let convertedDateArray = filteredAsset && filteredAsset.length > 0 && filteredAsset[0] && filteredAsset[0].extendedProps && filteredAsset[0].extendedProps.formattedDuration && filteredAsset[0].extendedProps.formattedDuration.split(' ');
        // console.log("convertedDateArray", convertedDateArray, self.state.copytext)
        let convertedDateArray = self.state.programtotalduration && self.state.programtotalduration.split(' ') || extendedProps && extendedProps.formattedDuration.split(' ');
        let endDate = self.endDatePrepare(convertedDateArray, self.state.copytext)
        // console.log("convertedDateArray", convertedDateArray, self.state.copytext)
        // let convertedDateArray = extendedProps && extendedProps.formattedDuration && extendedProps.formattedDuration.split(' ');
        // console.log("convertedDateArray", convertedDateArray)
        // convertedDateArray = convertedDateArray ? convertedDateArray : (this.state.duration && this.state.duration.split(' '));
        // let hrs = convertedDateArray[0]
        // hrs = parseInt(hrs.replace("h", ""));
        // let mts = convertedDateArray[1]
        // mts = parseInt(mts.replace("m", ""));
        // let scnds = convertedDateArray[2]
        // scnds = parseInt(scnds.replace("s", ""));
        // var endDate;
        // endDate = new Date(this.state.text);
        // endDate.setSeconds(endDate.getSeconds() + scnds);
        // endDate.setMinutes(endDate.getMinutes() + mts);
        // endDate.setHours(endDate.getHours() + hrs);
        let endd = endDate;
        assetInfoData["end"] = moment(endd).format("YYYY-MM-DD[T]HH:mm:ss")

        self.setState({ assetInfoData, isUpdateEnabled: true });
    }
    handleCopy(text) {
        //console.log('text', text)
        this.textArea.select();
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        document.execCommand('copy');
        textField.remove();
        this.setState({ copytext: text });
        console.log('textField', textField)
        console.log('text', text)
        //let assetInfo = text
        //this.setState({ start:text });

    }
    onChangeStatus = (e) => {
        var self = this;
        let formData = self.state.formData
        let checked = e.target.checked;
        if (checked) {

            formData['verticalMode'] = checked;
            //formData['ispublished'] = 'active';

        } else {
            if (!checked) {
                formData['verticalMode'] = checked;
                //['ispublished'] = 'inActive';
            }
        }
        this.setState({ formData: formData });
    }
    playVideo(event) {
        //console.log(payload, "payload")
        let payload = { assetId: event.id ? event.id : event.assetid ? event.assetid : (event.extendedProps || {}).envoiAssetId }
        // console.log(payload, "payload")
        this.setState({ isOpen: true });

        // self.setState({ assetInfo: info.event || {}, assetInfoData: {}, isUpdateEnabled: false, programtotalduration }, () => {
        //     self.setState({ isMetaDataReady: true })
        // })
        this.props.getPlaybackUrls(payload);
        let formData = {};
        formData["mode"] = "fetch";
        formData["type"] = "segment";
        this.props.fetchMarkers(payload.assetId, formData);
        this.setState({ loader: true });



    }
    playlistPreview(event) {
        // console.log(event, "event")
        let self = this;
        // self.fetchPlaylist(event)

        self.setState({
            playVideoTab: !self.state.playVideoTab, item: event
        })
    }
    render() {
        let self = this;
        let partnerMenuId = 'partner';
        let creatorMenuId = 'creator';
        let artistMenu = 'Creator';
        let partnerMenu = 'Partner';
        let channelMenu = 'Channel';
        let brandMenu = 'Brand';
        let productMenu = 'Product';
        let assetMenu = 'Asset';
        const { currentSeektime, seektime, updateForm, assetData, schedulesData, recordingAssets, isLoading, noOfAssets, assetTotal, loadbtn, assetInfo, noOfPlaylists, playlistTotal, assetInfoData, isUpdateEnabled, markerData, segmentData, isMediatailorCreated, calendarEvents, channelAssets, channelAssetTotal, actionButton, isCreateButton, playlistAssets, advancedAssetPayload, menusData, formvalues, genreData, formvalues1, userData, userSearch, isUserLoading, errors } = self.state;
        const { genre, carousel, maturityrating, released, tags, cast, crew, writer, distributer, award, studio } = assetData
        let { ratingData } = this.state;
        let userid = window.localStorage.getItem("user_id") || localStorage.getItem("user_id");
        console.log('copytext', this.state.copytext)
        // console.log("actionButton", !actionButton)
        // console.log("self.state.assetcount", self.state.assetCount.length)
        // console.log("self.state.assettotal", self.state.assetTotal.length)
        if (ratingData && ratingData.TVPG && formvalues && formvalues.assetType && formvalues.assetType.length > 0 && formvalues.assetType.includes('Episodic')) {
            ratingData = ratingData.TVPG;
        } else if (ratingData && ratingData.MPAA) {
            ratingData = ratingData.MPAA;
        }
        let siteSettings = window.site;
        let siteConfig = siteSettings && siteSettings.config;
        let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
        // let fullTilte = assetInfo && assetInfo.title && assetInfo.title.split('-');
        let eventTitle = assetInfo && assetInfo.title
        let runtime = assetInfo && assetInfo.extendedProps && assetInfo.extendedProps.playlistData && assetInfo.extendedProps.playlistData.formatedduration
        let programDuration = assetInfo && assetInfo.extendedProps && assetInfo.extendedProps.playlistData && assetInfo.extendedProps.playlistData.totalduration
        let startDate;
        let endDate;
        let playlistUserid
        if (isUpdateEnabled && assetInfoData) {
            startDate = moment((assetInfoData && assetInfoData.start) ? assetInfoData.start : assetInfo && assetInfo.start ? assetInfo.start : self.state.start).format("YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment((assetInfoData && assetInfoData.end) ? assetInfoData.end : assetInfo && assetInfo.end ? assetInfo.end : self.state.end).format("YYYY-MM-DD[T]HH:mm:ss");
            // console.log('startDate',startDate)
        } else if (assetInfo && assetInfo.extendedProps && assetInfo.extendedProps.playlistData && assetInfo.extendedProps.playlistData.serviceprovider) {
            startDate = moment(self.state.start).format("YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment(assetInfo && assetInfo.end ? assetInfo.end : self.state.end).format("YYYY-MM-DD[T]HH:mm:ss");
        }
        else if (self.state.playlistAssets) {
            startDate = moment(self.state.start ? self.state.start : assetInfo && assetInfo.start).format("YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment(assetInfo && assetInfo.end ? assetInfo.end : self.state.end).format("YYYY-MM-DD[T]HH:mm:ss");
            //  console.log('startDate1231222311212',startDate,self.state.start)
        } else {
            // console.log('assetInfo',assetInfo)
            startDate = moment(assetInfo && assetInfo.start ? assetInfo.start : self.state.start).format("YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment(assetInfo && assetInfo.end ? assetInfo.end : self.state.end).format("YYYY-MM-DD[T]HH:mm:ss");
            //  console.log('startDate1231222311212',startDate)
        }
        let metadataResult = assetInfo && assetInfo.extendedProps && assetInfo.extendedProps.playlistData || assetInfo && assetInfo.extendedProps;
        // console.log('assetInfo', metadataResult)
        let startTime = segmentData && segmentData.startTime ? moment(segmentData.startTime).format("MM-DD-YYYY[T]HH:mm:ss") : startDate;
        var date;
        userType = localStorage.getItem('usertype');
        // console.log('startTime',startTime)
        let slotDuration = siteConfig && siteConfig.envoiScheduler && siteConfig.envoiScheduler.slotDuration ? siteConfig.envoiScheduler.slotDuration : "00:30:00"
        let snapDuration = siteConfig && siteConfig.envoiScheduler && siteConfig.envoiScheduler.snapDuration ? siteConfig.envoiScheduler.snapDuration : '00:01:00'
        let slickSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 8,
            slidesToScroll: 8,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 8
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }
            ]
        };
        var now = new Date(),
            minDate = now.toISOString().substring(0, 10);
        return (
            <React.Fragment>
                <Header />
                <div className="animated fadeIn demo-app scheduling">
                    <div className='top_bar'>
                        <div className='top_bar_wrp'>
                            <div className='dsp_flex'>
                                <div className='select_chnl'>
                                    <select className="caleder_select" value={this.state.calendarId} placeholder="Calendar" name="calendarId" onChange={e => this.onChangeCalendarName(e)}>
                                        <option value="">SELECT SCHEDULE</option>
                                        {schedulesData && schedulesData.length > 0 && schedulesData.map(function (schedule, i) {

                                            return (
                                                <option key={i} value={schedule.channelcode}>{schedule.channelname}</option>
                                            )
                                        })}
                                    </select>
                                    <span className="material-icons arrow"> arrow_drop_down </span>
                                </div>
                                {actionButton &&
                                    <button onClick={(e) => this.addScheduleOpen(e)} type="button" className="add"><span className="material-symbols-outlined">
                                        add
                                    </span>New</button>}
                                {self.state.calendarId && !actionButton &&
                                    <button onClick={(e) => this.editScheduleOpen(e)} className="add"><span className="material-symbols-outlined">
                                        edit
                                    </span>Edit</button>}
                                {self.state.id && !actionButton && (userType == "admin" || "Admin") &&
                                    <button type="button" className="add" onClick={e => self.deleteAlert(e)} > <span className="material-symbols-outlined">delete</span>Delete</button>}
                                {!actionButton &&

                                    <button type="button" className="add" onClick={(e) => this.importStartTimeOpen(e)} ><span className="material-symbols-outlined" >
                                        upload_file
                                    </span><span>Upload</span></button>
                                }


                                {(self.state.calendarId && self.state.allSchedulesData && self.state.allSchedulesData.length > 0) && !actionButton &&
                                    <button type="button" className="add" onClick={e => self.openDownloadPopup(e)} disabled={self.state.executing}><span class="material-symbols-outlined">file_save</span><span>{self.state.submitText ? "Saving..." : "Download"}</span></button>}


                                {(self.state.calendarId && self.state.allSchedulesData && self.state.allSchedulesData.length > 0) && !actionButton && !isCreateButton ?
                                    <button type="button" className="add" onClick={() => self.createMediatailor()}><span className="material-symbols-outlined">
                                        add_to_queue
                                    </span>Create Channel
                                        {/* {this.state.isMediatailorCreated && !actionButton ? 'Save' : 'Create Channel'} */}
                                    </button> : null}
                                {isMediatailorCreated && !actionButton &&
                                    <button type="button" className="add" onClick={e => self.mediaTailorStartButton(e, isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "start" : "stop", isMediatailorCreated.ChannelName)}>

                                        {isMediatailorCreated && isMediatailorCreated.ChannelState != "STOPPED" ? <span className="material-symbols-outlined">
                                            stop_circle
                                        </span> : <span className="material-symbols-outlined">
                                            start
                                        </span>}

                                        {isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "Start" : "Stop"}</button>}
                            </div>


                        </div>
                    </div>

                    <div className='fc_col'>
                        <div
                            id="external-events"
                            style={{
                                padding: "10px",
                                width: "80%",
                                height: "auto",
                                maxHeight: "-webkit-fill-available"
                            }}
                        >
                            <CardHeader className="sub_tab">
                                <Nav className="nav-fill card-header-tabs" tabs>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '1' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}>
                                            Programs
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '2' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}>
                                            Playlists
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '3' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('3');
                                            }}>
                                            Channels
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={self.state.activeTab}>
                                <TabPane tabId="1" >
                                    <CardBody>
                                        <div className="search_block">

                                            <div className="lf_search">
                                                <input type="search" className="input" name='recodingsSearchInput' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'recordings')}
                                                    value={this.state.recodingsSearchInput}
                                                    placeholder="Search"
                                                />

                                                <button type="button" className="icon" onClick={() => self.searchForAssets('recordings')} ><span className="material-icons">
                                                    search
                                                </span></button>

                                            </div>
                                            <button type="button" onClick={() => self.handleAdvaceSearch()} className="icon advsrh_btn"
                                            ><span class="material-symbols-outlined">
                                                    find_in_page
                                                </span></button>
                                        </div>
                                        {this.props.loading && <Loader />}
                                        {!this.props.loading &&
                                            recordingAssets &&
                                            Array.isArray(recordingAssets) &&
                                            recordingAssets &&
                                            recordingAssets.length > 0 ? (
                                            recordingAssets.map((event, i) => (
                                                // console.log('event', event)
                                                imgUrl = event.imageurl,
                                                date = moment(event && event.created).format("MM-DD-YYYY"),
                                                eventFormatDuration = event.formatedduration ? event.formatedduration : '',
                                                <div
                                                    className="fc-event drag_item"
                                                    title={event && event.title}
                                                    data={event && event.id}
                                                    data-for={JSON.stringify(event)}
                                                    key={i}
                                                >
                                                    <div className='img' onClick={() => this.playVideo(event)}>
                                                        <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                        <div className='sdl_duration'>{eventFormatDuration}</div>
                                                    </div>

                                                    <div className='channel_title'>
                                                        {event && event.title}
                                                    </div>

                                                    <label className="form-label created_date">{date}</label>
                                                </div>
                                            ))

                                        ) : (
                                            isLoading ? <Loader /> :
                                                <React.Fragment>
                                                    <div className="warning-wrapper empty-result">
                                                        <div className="error-text">
                                                            <h2 className="text-center">Sorry !</h2>
                                                            <p>There are no assets available</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        )}
                                        {/* {noOfAssets < assetTotal && (
                                            <div className="text-center clearfix">
                                                <div
                                                    onClick={() => this.loadMore(undefined, false)}
                                                    className="btn_load"
                                                    type="button"
                                                    id="myButton"
                                                    value=" "
                                                >
                                                    {loadbtn}
                                                </div>
                                            </div>
                                        )} */}
                                        {recordingAssets &&
                                            recordingAssets.length > 10 &&
                                            <Pagination
                                                activePage={self.state.pageNumber}
                                                itemsCountPerPage={self.state.assetCount}
                                                totalItemsCount={self.state.assetTotal}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => this.loadMore(e, false)}
                                            //         handlePaginationData={self.handlePaginationData}
                                            // handleChangePages={self.handleChangePages}
                                            />}
                                    </CardBody>

                                </TabPane>
                                <TabPane tabId="2" >
                                    <CardBody>

                                        <div className="lf_search">
                                            <input type="search" className="input" name='playlistSearchInput' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'playlists')}
                                                value={this.state.playlistSearchInput}
                                                placeholder="Search"
                                            />
                                            <button type="button" className="icon" onClick={() => self.searchForAssets('playlists')} ><span className="material-icons">
                                                search
                                            </span></button>
                                        </div>
                                        {this.props.loading && <Loader />}
                                        {!this.props.loading &&
                                            this.state.events &&
                                            Array.isArray(this.state.events) &&
                                            this.state.events && this.state.events.length > 0 ? (this.state.events && this.state.events.map((event, i) => (
                                                // console.log('event', event)
                                                
                                                imgUrl = event.imageurl,
                                                    
                                                <div key={i}>
                                                    <div className=" fc-event drag_item  pl_item" title={event && event.title} data={event && event.id}
                                                        data-for={JSON.stringify(event)} >
                                                        <div className='icon' onClick={() => this.handlePlayistByIdExpand(event.uuid)}><span style={{ cursor: 'pointer' }} className="material-icons">{self.state[event.uuid] ? 'expand_less' : 'expand_more'}</span></div>

                                                        <div className="img" >
                                                            <div className=" Play_overlay" onClick={() => self.playlistPreview(event)} style={{ cursor: 'pointer' }}><span className="material-icons play">play_arrow</span></div>
                                                            <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                            <div className="sdl_duration">{event && event.totalAssetsduration}</div>
                                                        </div>
                                                        <div className="channel_title">{event && event.title}</div>
                                                        <label className="form-label created_date">Asset Count:{event && event.assetcount}</label>
                                                        {/* <div className="channel_title">Asset Count:{event && event.assetcount}</div> */}
                                                        {/* <button type="button" className="icon channel_pvbtn" onClick={() => self.playlistStartTime()}><span className="material-icons">
                                                            play_arrow
                                                        </span></button> */}

                                                        {(userid === event.userid) && <a class="publish-btn selected rd_edit" title='edit playlist' onClick={() => self.navToEditPlaylist(event)}><span class="material-symbols-outlined"> edit </span></a>}

                                                    </div>
                                                    {self.state[event.uuid] && <div className='pl_sub'>
                                                        {event && event.assets.map((asset, j) => (

                                                            eventFormatDuration = asset.totalduration ? asset.totalduration : asset.formatedduration,
                                                            date = moment(event && event.created).format("MM-DD-YYYY"),

                                                            <div key={j} className="fc-event drag_item pl_item"
                                                                title={asset.assetname}
                                                                data={asset && asset.id}
                                                                data-for={JSON.stringify(asset)}
                                                            >
                                                                <div className='icon'>
                                                                    {/* <span assName`?="material-icons"> add_circle_outline</span> */}
                                                                </div>
                                                                <div className="img" onClick={() => this.playVideo(asset)} style={{ cursor: 'pointer' }}>
                                                                    <img src={asset && asset.landscape_logo_image ? asset.landscape_logo_image + siteConfig.imageSuffix : ""} />
                                                                    <div className="sdl_duration">{asset.formatedduration}</div>
                                                                </div>
                                                                <div className="channel_title">{asset.assetname}</div>

                                                                <label className="form-label created_date">{date}</label>
                                                            </div>))}
                                                    </div>}
                                                </div>
                                            ))
                                        ) : (
                                            isLoading ? <Loader /> :
                                                <React.Fragment>
                                                    <div className="warning-wrapper empty-result">
                                                        <div className="error-text">
                                                            <h2 className="text-center">Sorry !</h2>
                                                            <p>There are no playlists available</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        )}
                                        {/* {noOfPlaylists < playlistTotal && (
                                            <div className="text-center clearfix">
                                                <div
                                                    onClick={() => this.loadMorePlaylist(undefined, false)}
                                                    className="btn_load"
                                                    type="button"
                                                    id="myButton"
                                                    value=" "
                                                >
                                                    {loadbtn}
                                                </div>
                                            </div>
                                        )} */}
                                        {this.state.events && this.state.events.length > 10 &&
                                            self.state.playlistTotal > self.state.playlistPageCount &&
                                            <Pagination
                                                activePage={self.state.playlistPageNumber}
                                                itemsCountPerPage={self.state.playlistPageCount}
                                                totalItemsCount={self.state.playlistTotal}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => this.loadMorePlaylist(e, false)}
                                            />}


                                    </CardBody>
                                </TabPane>
                                <TabPane tabId="3" >
                                    <CardBody>
                                        <div className="search_block">
                                            <div className="lf_search">
                                                <input type="search" className="input" name='channelname' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'channels')}
                                                    value={this.state.channelname}
                                                    placeholder="Search"
                                                />
                                                <button type="button" className="icon" onClick={() => self.searchForAssets('channels')} ><span className="material-icons">
                                                    search
                                                </span></button>
                                            </div>
                                            <button type="button" onClick={() => self.handleChannelAdvaceSearch()} className="icon advsrh_btn"
                                            ><span class="material-symbols-outlined">
                                                    find_in_page
                                                </span></button>
                                        </div>
                                        {this.props.loading && <Loader />}
                                        {!this.props.loading &&
                                            channelAssets &&
                                            Array.isArray(channelAssets) &&
                                            channelAssets &&
                                            channelAssets.length > 0 ? (
                                            channelAssets.map((event, i) => (
                                                // console.log('event', event)
                                                imgUrl = event.imageurl,
                                                date = moment(event && event.created).format("MM-DD-YYYY"),
                                                //eventFormatDuration = event.formatedduration ? event.formatedduration : '',
                                                <div
                                                    className="fc-event drag_item"
                                                    title={event && event.title}
                                                    data={event && event.id}
                                                    data-for={JSON.stringify(event)}
                                                    key={i}
                                                >
                                                    <div className='img'>
                                                        <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                    </div>

                                                    <div className='channel_title'>
                                                        {event && event.title}
                                                    </div>
                                                    {/* <button type="button" className="icon channels_pvbtn"><span className="material-icons">play_arrow</span></button> */}
                                                    <label className="form-label created_date">{date}</label>
                                                    {/* <div className='sdl_duration'>{eventFormatDuration}</div> */}
                                                </div>
                                            ))

                                        ) : (
                                            isLoading ? <Loader /> :
                                                <React.Fragment>
                                                    <div className="warning-wrapper empty-result">
                                                        <div className="error-text">
                                                            <h2 className="text-center">Sorry !</h2>
                                                            <p>There are no channels available</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        )}
                                        {/* {noOfAssets < channelAssetTotal && (
                                            <div className="text-center clearfix">
                                                <div
                                                    onClick={() => this.channelLoadMore(undefined, false)}
                                                    className="btn_load"
                                                    type="button"
                                                    id="myButton"
                                                    value=" "
                                                >
                                                    {loadbtn}
                                                </div>
                                            </div>
                                        )} */}
                                        {channelAssets &&
                                            channelAssets.length > 10 && self.state.channelTotal > self.state.channelPageCount &&
                                            <Pagination
                                                activePage={self.state.channelPageNumber}
                                                itemsCountPerPage={self.state.channelPageCount}
                                                totalItemsCount={self.state.channelTotal}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => this.channelLoadMore(e, false)}
                                            />}
                                    </CardBody>
                                </TabPane>
                            </TabContent>
                        </div>
                        <div className="demo-app-calendar" id="mycalendartest">
                            {/* <DateRangePicker onChange={this.calederDataRange} /> */}
                            <FullCalendar
                                // timeZone='America/New_York'
                                id='fullCalendar'
                                defaultView="timeGridDay"
                                selectable={true}

                                slotDuration={slotDuration}
                                snapDuration={snapDuration}
                                // defaultDate='2019-05-21'
                                header={{
                                    left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                    center: "title",
                                    right: "prev,next today"

                                }}
                                rerenderDelay={10}
                                eventDurationEditable={false}
                                // eventTimeFormat={{
                                //     hour: '2-digit',
                                //     minute: '2-digit',
                                //     // second: '2-digit',
                                //     hour12: false
                                // }}
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hourCycle: 'h23'
                                }}

                                editable={true}
                                droppable={true}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={this.calendarComponentRef}
                                // weekends={this.state.calendarWeekends}
                                events={this.state.calendarEvents}
                                // eventDragStop={this.remove}
                                // select={this.add}
                                eventDrop={this.eventDrop}
                                eventReceive={this.eventReceive}
                                eventClick={this.eventClick}
                                validRange={{ start: new Date() }}
                                allDay={false}
                                //allDaySlot={false}
                                eventOverlap={true}
                                eventOrder="start,end"

                            />


                            <div className="cl_buttons">


                                {/* <button type="button" className="btn btn-primary" onClick={e => self.getGooglesheetInfo(e)} disabled={self.state.executing}><span>{self.state.submitText ? "Saving..." : "Export Schedule from Googlesheet"}</span></button>&nbsp;&nbsp;&nbsp; */}

                                {/* {isMediatailorCreated && 
                                    <button type="button" className="jr-btn btn btn-primary" onClick={e => self.mediaTailorStartButton(e, isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "start" : "stop", isMediatailorCreated.ChannelName)}> {isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "Start" : "Stop"}</button>} */}

                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.isOpen}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isOpen: false }) }}
                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup_hed'>
                                <h2>&nbsp;
                                </h2>
                                <span className="popup-close" onClick={(e) => { this.closePlayerClick() }}></span>
                            </div>
                            <div className='metadata'>

                                {this.state.playbackUrlsData && <VideoPlayer
                                    videoStream={this.state.playbackURL || ''}
                                    eventData={this.state.playbackUrlsData}
                                    playerType="bitmovin"
                                    item={this.item || {}}
                                    title={this.title || ''}
                                    closePopup={this.closePopup}
                                    playerTimeChanged={this.playerTimeChanged}
                                />
                                }
                            </div>
                        </Modal>
                        <Modal isOpen={this.state.playVideoTab} className="custom_modal u-conf-popup preview_play">

                            <ModalHeader ><span className="popup-close" onClick={(e) => { this.videoClose() }}></span></ModalHeader>
                            <ModalBody className="u-expand u-modal-body">
                                <Player isPopup={true} startPlayer={true} item={self.state.item} />
                            </ModalBody>
                        </Modal>
                        <Modal
                            isOpen={this.state.isAddScheduleOpen}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isAddScheduleOpen: false }) }}
                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-channel'>
                                <div className='popup_hed'>
                                    <h2>{self.state.formData && self.state.formData._id ? "EDIT CHANNEL" : "ADD CHANNEL"}</h2>
                                    <a href="#" onClick={e => self.closeChannelPopup(e)} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="add_calender">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Title</p>
                                                    <input type="text" placeholder='Enter Title' label='channel-title' name="channelname" value={self.state.formData && self.state.formData.channelname} onChange={e => self.onChangeCalendarName(e)} />
                                                    <span className="errormsg">{this.state.errors.channelname}</span>
                                                </div>
                                                <div className="inputgroup">
                                                    <p className='label'>Status</p>
                                                    <select className="custom-select form-control" name="status" value={self.state.formData && self.state.formData.status} onChange={e => self.onChangeCalendarName(e)}>
                                                        <option value="">Select</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                    <span className="errormsg">{this.state.errors.status}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className='label'>Channel Type</p>
                                                    <select className="custom-select form-control" name="type" value={self.state.formData && this.state.formData.type} onChange={e => this.onChangeCalendarName(e)}>
                                                        <option value=" ">Select</option>
                                                        <option value="BASIC">Basic</option>
                                                        <option value="STANDARD">Standard</option>
                                                    </select>
                                                    <span className="errormsg">{this.state.errors.type}</span>
                                                </div>

                                                <div className='inputgroup'>
                                                    <p className='label'>Average Ad Duration</p>
                                                    <input type="text" placeholder='Average Ad Duration' name="average_ad_duration" value={self.state.formData && self.state.formData.average_ad_duration} onChange={e => self.onChangeCalendarName(e)} />
                                                    <span className="errormsg">{this.state.errors.average_ad_duration}</span>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Description</p>
                                                    <textarea className='channel_description' rows={"4"} placeholder="Description" name="description" value={self.state.formData && self.state.formData.description} onChange={e => self.onChangeCalendarName(e)}></textarea>
                                                </div>
                                            </div>
                                            {
                                                self.state.formData && self.state.formData.type &&
                                                <div className="col-md-12">
                                                    <div className="inputgroup">
                                                        <p className='label'>Duration</p>
                                                        <select className="form-control u-form-control" name="duration" value={self.state.formData.duration} onChange={e => self.onChangeCalendarName(e)}>
                                                            <option value="">Select</option>
                                                            <option value="60">1 hour</option>
                                                            <option value="240">4 hours</option>
                                                            <option value="480">8 hours</option>
                                                            <option value="1440">24 hours</option>
                                                            <option value="4320">3 days</option>
                                                            <option value="10080">7 days</option>
                                                            <option value="20160">14 days</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                self.state.formData && self.state.formData.type &&
                                                <div className="col-md-12">
                                                    <div className="inputgroup">
                                                        <p className='label'>Playback mode</p>
                                                        <p>Determine's the channel playback behaviour</p>
                                                        <select className="form-control u-form-control" name="playbackmode" value={self.state.formData.playbackmode} defaultValue={self.state.formData.playbackmode} disabled={self.state.formData.type == 'STANDARD'} onChange={e => self.onChangeCalendarName(e)}>
                                                            <option value="">Select</option>
                                                            {
                                                                self.state.playbackmodeObj[self.state.formData.type].map((item) => (<option value={item} >{item}</option>))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                self.state.formData && self.state.formData.playbackmode == "LINEAR" &&
                                                <div className='col-md-6'>
                                                    <div className="inputgroup">
                                                        <p className="label">Source Location Name</p>
                                                        <select id="SourceLocationName" name="SourceLocationName" value={self.state.formData.SourceLocationName} onChange={e => self.onChangeCalendarName(e)}>
                                                            <option value="">Select</option>
                                                            {this.props.sourceLocationData && [this.props.sourceLocationData].length > 0 && [this.props.sourceLocationData].map((item, index) => { return (<option key={index} value={item}>{item}</option>) })}
                                                        </select>
                                                        <span className="errormsg">{this.state.errors.SourceLocationName}</span>
                                                    </div>

                                                </div>}
                                            {
                                                self.state.formData && self.state.formData.playbackmode == "LINEAR" &&
                                                <div className='col-md-6'>
                                                    <div className="inputgroup">
                                                        <p className="label">Vod Source Name</p>

                                                        <select id="VodSourceName" name="VodSourceName" value={self.state.formData.VodSourceName} onChange={e => self.onChangeCalendarName(e)}>
                                                            <option value="">Select</option>
                                                            {this.state.vodSourcesData && this.state.vodSourcesData.length > 0 && this.state.vodSourcesData.map((item, index) => {
                                                                return (<option key={index} value={item.VodSourceName}>{item.assetname || item.VodSourceName}</option>)
                                                            })}


                                                        </select>
                                                        <span className="errormsg">{this.state.errors.VodSourceName}</span>
                                                    </div>

                                                </div>}
                                            <div className='col-md-12'>
                                                <h3 className='sub_title'>Thumbnail</h3>
                                                <div className='uploadPics'>
                                                    <div className='upload'>
                                                        <div className="img_block">
                                                            <img src={self.state.formData && self.state.formData.landscape_logo_image ? self.state.formData.landscape_logo_image + siteConfig.imageSuffix : defaultLandscape + siteConfig.imageSuffix} />
                                                            <div className="upload_btns">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="landscape_logo_image" className="udisplay-none" onChange={self.state.landscape_logo_image ? e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"]) : e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.landscape_logo_image ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "landscape_logo_image", "landscapeLogoProgress")}>delete</span> </a> : null}
                                                            </div>
                                                        </div>
                                                        <div className="size">Landscape  (1920*1080)</div>


                                                    </div>
                                                    <div className='upload portrait'>
                                                        <div className="img_block u-potrait">
                                                            <img className="portait_img" src={self.state.formData && self.state.formData.portrait_logo_image ? self.state.formData.portrait_logo_image + siteConfig.imageSuffix : siteConfig.defaultPortrait + siteConfig.imageSuffix} />
                                                            <div className="upload_btns">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="portrait_logo_image" className="udisplay-none " onChange={self.state.portrait_logo_image ? e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"]) : e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.portrait_logo_image ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "portrait_logo_image", "portraitLogoProgress")}>delete</span></a> : null}
                                                            </div>
                                                        </div>
                                                        <div className="size">Portrait (1000*1294)</div>
                                                    </div>
                                                    <div className='upload up-logo'>
                                                        <div className="img_block u-potrait u-bg-black">
                                                            <img className="new-user-logo-u" src={self.state.formData && self.state.formData.logoImage ? self.state.formData.logoImage + siteConfig.imageSuffix : siteSettings.logoImage + siteConfig.imageSuffix} />
                                                            <div className="upload_btns pt-15">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="logoImage" className="udisplay-none " onChange={self.state.logoImage ? e => self.uploadS3(e, ["175", "Image", "logoImage", "logoImageProgress"]) : e => self.uploadS3(e, ["175", "Image", "logoImage", "logoImageProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.logoImage ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "logoImage", "logoImageProgress")}>delete</span><i  ></i></a> : null}

                                                            </div>
                                                        </div>
                                                        <div className="size">Logo</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <input type="text" className="input" name='calendarName' onChange={e => this.onChangeCalendarName(e)} value={this.state.calendarName} placeholder="Enter Name" />
                                        <span className="errormsg" >{this.state.errors.calendarName}</span> */}
                                        <div className='panel_content mb-5'>

                                            {/* <h1>kiran</h1> */}
                                            <Availability
                                                handleAvailability={self.handleAvailability} availability={self.state.availability}
                                                donation={self.state.donation} voting={self.state.voting} likeDislike={self.state.likeDislike}
                                                embed={self.state.embed} preview={self.state.preview} timezoneData={self.state.timezoneData} formData={self.state.formData}
                                                assetType={self.state.assetType} assetid={self.state.assetid ? self.state.assetid : ""} serviceProvider={self.state.serviceProvider} />
                                        </div>
                                        <div className='switch_btn'>
                                            <span>Vertical Mode</span>
                                            <label className="switch"> <input type="checkbox" name="verticalMode" checked={self.state.formData && self.state.formData.verticalMode} onChange={e => self.onChangeStatus(e)} id="verticalMode" /> <span className="slider"></span> </label>
                                        </div>
                                        <div className='footer_btn'>
                                            <button type="button" className="add" onClick={e => self.addSchedule(e)} > <span className="material-symbols-outlined">add</span> {self.state.formData && self.state.formData._id ? "Update" : "Add"}</button>
                                            &nbsp;&nbsp;


                                        </div>

                                    </div>

                                </div>
                                <SweetAlert show={this.state.addMetadata}
                                    custom
                                    confirmBtnText="Ok"
                                    confirmBtnBsStyle="primary"
                                    title={this.state.alertMsg}
                                    onConfirm={e => self.setState({ alertMsg: '', addMetadata: false })}
                                >
                                </SweetAlert>
                                <SweetAlert show={this.state.assetFile}
                                    custom
                                    showCancel
                                    confirmBtnText="Ok"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="default"
                                    title={"Are you sure to delete this Asset File ?"}
                                    onConfirm={e => this.onConfirmDelete()}
                                    onCancel={e => this.onCancelDelete()}
                                >
                                </SweetAlert>
                            </div>

                        </Modal>
                        <SweetAlert show={this.state.showAlert}
                            custom
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={self.state.errorMsg}
                            onConfirm={e => self.successPopup(e)}
                        >
                        </SweetAlert>
                        {/* <SweetAlert show={this.state.showAlertMessage}
                            custom
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={self.state.errorMsg}
                            onConfirm={e => self.successPopup1(e)}
                        >
                        </SweetAlert> */}


                        <SweetAlert show={this.state.showDeleteEventAlert}
                            custom
                            showCancel
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title="Remove from Schedule ?"
                            onConfirm={e => self.onConfirmScheduleDelete(e)}
                            onCancel={e => self.setState({ showDeleteEventAlert: false })}
                        >
                        </SweetAlert>


                        <SweetAlert show={this.state.showDeletePopup}
                            custom
                            showCancel
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={"Are you sure want to delete this " + this.state.deleteMarkerType}
                            onConfirm={e => self.onConfirmDeleteMarker()}
                            onCancel={e => self.setState({ showDeletePopup: false })}
                        >
                        </SweetAlert>

                        <SweetAlert show={this.state.showDeleteAlert}
                            custom
                            showCancel
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={"Delete Channel?"}
                            onConfirm={e => self.onConfirmDeleteChannel()}
                            onCancel={e => self.setState({ showDeleteAlert: false, })}
                        >
                        </SweetAlert>
                        {/*-------------------- Download Popup -------------------- */}
                        <Modal
                            isOpen={this.state.isDownloadPopup}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={(e) => this.openDownloadPopup(e)}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup download_pop">
                            <div className="url_content">
                                <a href="#" onClick={(e) => this.openDownloadPopup(e)} className='close material-symbols-outlined'>close </a>
                                <h2>Select Download</h2>
                                <p>
                                    <button type="button" className="btn" onClick={e => this.downloadJson(e)} >JSON</button>
                                    <button type="button" className="btn fill_btn" onClick={e => this.downloadCsv(e)}  >CSV</button>
                                </p>
                            </div>

                        </Modal>
                        <Modal
                            isOpen={this.state.startTimePopup}
                            style={customStyles}
                            ariaHideApp={false}
                            // onRequestClose={(e) => this.openStartTimePopup(e)}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup download_pop start_time">
                            <div className="url_content">
                                <a href="#" onClick={(e) => this.openStartTimePopup(e)} className='close material-symbols-outlined'>close </a>
                                {/* <h1>playlist asset</h1> */}
                                <h2>Start Time</h2>
                                <input type="datetime-local" className='input' name='start' onChange={e => this.startTimeChange(e)} value={startDate} step="1" />
                                <p>
                                    <button type="button" className="btn" onClick={(e) => this.playlistEventData(e)} >Add</button>
                                </p>
                            </div>
                        </Modal>


                        <Modal
                            isOpen={this.state.importStartTimePopup}
                            style={customStyles}
                            ariaHideApp={false}
                            contentLabel="Import Start Time"
                            className="custom_modal u-conf-popup download_pop start_time">
                            <div className="url_content">
                                <a href="#" onClick={(e) => this.importStartTimeOpen(e)} className='close material-symbols-outlined'>close </a>
                                {/* <h1>playlist asset</h1> */}
                                <h2>Start Time</h2>
                                <input type="datetime-local" className='input' name='start' onChange={e => this.importStartTimeChange(e)} value={this.state.importStartTimeValue} step="1" />
                                <p>
                                    <ReactFileReader handleFiles={e => this.handleFiles(e)} fileTypes={[".xlsx", ".xls", ".csv", ".json"]}>
                                        <button type="button" className="btn add" ><span className="material-symbols-outlined">
                                            upload_file
                                        </span><span>Import</span></button>
                                    </ReactFileReader>
                                </p>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.channelTimePopup}
                            style={customStyles}
                            ariaHideApp={false}
                            // onRequestClose={(e) => this.openChannelTimePopup(e)}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup download_pop start_time">

                            <div className="url_content">
                                <a href="#" onClick={(e) => this.openChannelTimePopup(e)} className='close material-symbols-outlined'>close </a>
                                <h2>Start Time</h2>
                                <input type="datetime-local" className='input' name='start' onChange={e => this.startTimeChange(e)} value={self.state.startTimeValue} step="1" />
                                <h2>End Time</h2>
                                <input type="datetime-local" className='input' name='end' value={self.state.endTimeValue} onChange={e => this.endTimeChange(e)} step="1" />
                                <p>
                                    <button type="button" className="btn" onClick={(e) => this.addEventData(e)}>Add</button>
                                </p>
                            </div>

                        </Modal>
                        {/*-------------------- Add Segment -------------------- */}

                        <Modal
                            isOpen={this.state.isSegmentPopup}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isSegmentPopup: false }) }}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-channel add-Segment'>
                                <div className='popup_hed'>
                                    <h2> Add Segment</h2>
                                    <a href="#" onClick={() => { this.setState({ isSegmentPopup: false }) }} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="add_calender">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Segment Name</p>
                                                    <input type="text" placeholder='Enter Name' name='title' value={segmentData.title} onChange={(e) => this.onChangeSegment(e)} />
                                                    <span className="errormsg">{this.state.errors.title}</span>
                                                </div>
                                                <div className='inputgroup'>
                                                    <p className='label'>Start Time</p>
                                                    <input type="datetime-local" className='input' name='start' onChange={e => this.onChangeSegment(e)} value={segmentData.start ? segmentData.start : startDate} step="1" />
                                                    {/* <span className="errormsg">{this.state.errors.title}</span> */}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className='label'>Duration (In Seconds)</p>
                                                    {/* <TimePicker id="duration" name='segmentDuration' value={segmentData.segmentDuration} onChange={e => this.onChangeSegment(e)} placeholder="Duration" open={this.state.isDurationOpen} onOpen={this.setDurationOpen} onClose={this.setDurationOpen} focusOnOpen /> */}
                                                    <input type="number" name="duration" value={segmentData.duration} onChange={e => this.onChangeSegment(e)} placeholder="Duration" />
                                                    <span className="errormsg">{this.state.errors.duration}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='footer_btn'>
                                            <button type="button" className="add" onClick={e => self.addSegment(e)} > <span className="material-symbols-outlined">add</span> {"Add"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* -------------------- Add Marker -------------------- */}
                        <Modal
                            isOpen={this.state.isMarkerPopup}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isMarkerPopup: false }) }}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-channel add-Segment'>
                                <div className='popup_hed'>
                                    <h2> Add Marker</h2>
                                    <a href="#" onClick={() => { this.setState({ isMarkerPopup: false }) }} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="add_calender">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Title</p>
                                                    <input type="text" placeholder="Name" name="title" value={markerData.title} onChange={e => this.onChangeMarker(e)} />
                                                    <span className="errormsg">{this.state.errors.title}</span> </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Duration (In Seconds)</p>
                                                    {/* <input type="number" name="duration" value={markerData.duration} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <select className="form-control u-form-control" name="duration" value={markerData.duration} onChange={e => self.onChangeMarker(e)}>
                                                        <option value="">Select</option>
                                                        <option value="05"> 05 seconds</option>
                                                        <option value="10">10 seconds</option>
                                                        <option value="15">15 seconds</option>
                                                        <option value="30">30 seconds</option>
                                                        <option value="60">60 seconds</option>
                                                        <option value="90"> 90 seconds</option>
                                                        <option value="120"> 120 seconds</option>

                                                    </select>
                                                    <span className="errormsg">{this.state.errors.duration}</span>

                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Start Time</p>
                                                    <TimePicker id="start" name='start' value={markerData.start}
                                                        // onChange={e => this.onChangeMarker(
                                                        //     {
                                                        //         target: {
                                                        //             name: 'start', value: e
                                                        //         }
                                                        //     }
                                                        // )} 
                                                        onChange={e => this.onStartTimeChange(e)}
                                                        placeholder="Start" open={this.state.isStartOpen} onOpen={this.setStartOpen} onClose={this.setStartOpen} focusOnOpen />
                                                    {/* <input type="text" placeholder={currentSeektime ? null : "Player Initializing..."} name="start" value={updateForm ? this.props.msTimeMilliseconds(seektime) : currentSeektime ? this.props.msTimeMilliseconds(currentSeektime) : "00.00.00"} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <span className="errormsg">{this.state.errors.start}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">End Time</p>
                                                    <TimePicker id="end" name='end' value={markerData.end}

                                                        // onChange={e => this.onChangeMarker({
                                                        //     target: {
                                                        //         name: 'end', value: e
                                                        //     }
                                                        // })} 
                                                        onChange={e => this.onEndTimeChange(e)}

                                                        placeholder="End" open={this.state.isEndOpen} onOpen={this.setEndOpen} onClose={this.setEndOpen} focusOnOpen />
                                                    {/* <input type="text" placeholder={currentSeektime ? null : "Player Initializing..."} name="end" value={updateForm ? this.props.msTimeMilliseconds(seektime) : currentSeektime ? this.props.msTimeMilliseconds(currentSeektime) : "00.00.00"} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <span className="errormsg">{this.state.errors.end}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-12'>
                                                <div className="inputgroup">
                                                    <p className="label">Comments</p>
                                                    <textarea rows="2" cols="50" name="comment" value={markerData.comment} placeholder="Add Comment" onChange={e => this.onChangeMarker(e)} ></textarea>
                                                    <span className="errormsg">{this.state.errors.comment}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Source Location Name</p>
                                                    <select id="SourceLocationName" name="SourceLocationName" value={markerData.SourceLocationName} onChange={e => self.onChangeMarker(e)}>
                                                        <option value="">Select</option>
                                                        {this.props.sourceLocationData && [this.props.sourceLocationData].length > 0 && [this.props.sourceLocationData].map((item, index) => { return (<option key={index} value={item}>{item}</option>) })}
                                                    </select>
                                                    <span className="errormsg">{this.state.errors.SourceLocationName}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Vod Source Name</p>
                                                    {/* <input type="text" name="VodSourceName" value={markerData.VodSourceName} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <select id="VodSourceName" name="VodSourceName" value={markerData.VodSourceName} onChange={e => self.onChangeMarker(e)}>
                                                        <option value="">Select</option>
                                                        {this.state.vodSourcesData && this.state.vodSourcesData.length > 0 && this.state.vodSourcesData.map((item, index) => {
                                                            return (<option key={index} value={item.VodSourceName}>{item.assetname || item.VodSourceName}</option>)
                                                        })}


                                                    </select>
                                                    <span className="errormsg">{this.state.errors.VodSourceName}</span>
                                                </div>

                                            </div>

                                            {/* <div className='col-md-12'>
                                            <h3 className='sub_title'>Options</h3>
                                            <label className="font-white">Marker Color </label>
                                            <div className="u-checkmark">
                                                <CompactPicker
                                                    color={this.state.markerColor}
                                                    onChangeComplete={()=>this.handleChangeComplete()}
                                                />

                                                <span className="errormsg">{this.state.errors.markerColor}</span>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className='footer_btn'>
                                            <button type="button" className="add" onClick={e => self.addMarker(e)} > <span className="material-symbols-outlined">add</span> {"Add"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {self.state.isMetaDataReady && <div className='side_popup pd' style={{ zIndex: '1111' }}>
                            <div className="popup_hed"><h2>Program details</h2><a href="#" onClick={e => self.setState({ isMetaDataReady: false })} className="close material-symbols-outlined">close </a></div>
                            <div className='popup_content'>
                                <div className='pd_block'>
                                    <div className='field preview_block'>
                                        <label>Preview</label>
                                        <div className='video' style={{ width: '100%', height: '50%' }}>
                                            {this.state.playbackUrlsData && <VideoPlayer
                                                videoStream={this.state.playbackURL || ''}
                                                eventData={this.state.playbackUrlsData}
                                                playerType="bitmovin"
                                                item={this.item || {}}
                                                title={this.title || ''}
                                                closePopup={this.closePopup}
                                                playerTimeChanged={this.playerTimeChanged}
                                            />}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className='dsp_flx'>{eventTitle ? eventTitle : assetInfo.title}  </label>
                                        <p>{assetInfo.extendedProps.description}</p>
                                        {/* <p className='dsp_flx'>Jessy_Terrero<span className="material-icons"> content_copy </span></p> */}
                                    </div>
                                    <div className="field">
                                        {/* <a href="#" className='icon_flx'>
                                            <span className="material-icons"> calendar_month </span> <span className="edit"> Sun, Apr 2, 2023 <span className="material-icons"> edit </span></span>
                                        </a>
                                        <div className='icon_flx2'>
                                            <span className="material-icons"> schedule </span>
                                            <span className="edit"> 06:00:00 AM - 07:15:00 AM <span className="material-icons"> edit </span></span>
                                        </div> */}
                                        {/* <div className='dsp_flex'> */}
                                        <div className='time'>
                                            <label>Start</label>
                                            <input type="datetime-local" className='input' name='start' onChange={e => this.handleChange(e)} value={startDate} step="1" />

                                            {self.state.copytext ? <a href="javascript:void(0)" className="time_ico" onClick={e => this.handlePaste()} title="Paste"><span className="material-icons">content_paste</span></a> : <a href="javascript:void(0)" className="time_ico" title="Paste"><span className="material-icons">content_paste</span></a>}

                                            {/* <p className='hours'>HH : MM : SS</p> */}
                                        </div>
                                        <div className='time time_end' style={{ marginTop: '20px' }}>
                                            <label>End</label>
                                            <input type="datetime-local" className='input' name='end' value={endDate} onChange={e => this.handleEndDateChange(e)} step="1" ref={(endDate) => this.textArea = endDate} />

                                            <a href="javascript:void(0)" className="time_ico" onClick={e => this.handleCopy(endDate)} title="Copy"><span className="material-icons">file_copy</span></a>


                                            {/* <p className='input_value'>{assetInfo.start}</p> */}
                                        </div>
                                        {assetInfo && assetInfo.extendedProps && (assetInfo.extendedProps.scheduleId || assetInfo.extendedProps.scheduleUUID) && <div className="cl_buttons">
                                            <div className="form_buttons">
                                                {/* <button onClick={(e) => this.updateScheduleData(e)} className="add">Update</button> */}
                                                <button style={{
                                                    height: '30px',
                                                    marginTop: '22px',
                                                }} type="button" className="btn" onClick={e => self.updateScheduleData(e)} >{self.state.submitText ? "Updating..." : "Update"}</button>
                                            </div>
                                        </div>}
                                        {/* </div> */}
                                    </div>
                                    {/* <div className="field prv_next">
                                        <div className='item'>
                                            <div className='arrow'><span className="material-icons"> navigate_before </span>Previous Program</div>
                                            <div className='title'>envoi_channel_185668_5699_Live Stream  </div>
                                            <div className='time'> <span>Start</span> : 25-04-2023 04:10:14</div>
                                            <div className='time'> <span>End</span> :  25-04-2023 05:59:59</div>
                                        </div>
                                        <div className='item'>

                                            <div className='arrow next'>Next Program <span className="material-icons"> navigate_next </span></div>
                                            <div className='title'>envoi_channel_185668_5699_Live Stream  </div>
                                            <div className='time'> <span>Start</span> : 25-04-2023 07:16:00</div>
                                            <div className='time'> <span>End</span> : 25-04-2023 07:30:22</div>
                                        </div>
                                    </div> */}
                                    {/* {!self.state.serviceProvider && */}
                                    <div className="field">
                                        {/* <div className='column'>
                                            <div> <label className='dsp_flx'>Ad Duration </label></div>
                                            <div> <p >00:23:09</p></div>
                                        </div> */}
                                        <div className='column'>
                                            <div> <label className='dsp_flx'>Asset Duration </label></div>
                                            <div> <p >{runtime ? runtime : self.state.formattedDuration ? self.state.formattedDuration : (assetInfo.extendedProps || {}).formattedDuration}</p></div>

                                        </div>
                                        <div className='column pdd'>
                                            <div> <label className='dsp_flx'>Program Duration </label></div>
                                            <div> <p >{self.state.programtotalduration ? self.state.programtotalduration : (assetInfo.extendedProps || {}).formattedDuration}</p></div>
                                        </div>
                                    </div>
                                    {/* }  */}


                                </div>
                                <div className='side_tab'>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Schedule </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Metadata</a>
                                        </li>

                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                            <div className='mrk_block'>
                                                <a href="#" className='add_segment' onClick={() => this.openSegmentPopup()}> <span className="material-icons"> add_circle_outline </span> Add Segment</a>


                                                {/* {this.state.markersData&& this.state.markersData.length>0 ?} */}
                                                {/* <div className='mrk_short'>Start Time <span> (Duration) </span></div> */}
                                                {/* <div className='mrk_short_hed'>
                                                    <div className='left'>
                                                        <div>6 Segment(s)</div>
                                                        <div className='dot'></div>
                                                        <div>8 Break(s)</div>
                                                    </div>
                                                    <div className='right'>
                                                        06:00:00 AM <span>(01:15:00)</span>
                                                    </div>
                                                </div> */}
                                                {this.state.isSementsLoading && <Loader />}
                                                {this.state.markersData && this.state.markersData.length > 0 ?
                                                    <> <div className="sd_items">
                                                        {this.state.markersData.map((segment, index) => (
                                                            <div className="sd_item" key={index}>
                                                                <div className='sd_item_wrp'>
                                                                    <div className="icon" style={(segment.markers && segment.markers.length > 0) ? { cursor: 'pointer' } : disabledStyle}>
                                                                        {segment.markers && segment.markers.length > 0 && <span className="material-icons" onClick={e => { this.collapseAndExpand(index); }}>{this.state['isExpand' + index] ? 'remove_circle_outline' : 'add_circle_outline'}</span>}
                                                                    </div>
                                                                    <div className="item_title">
                                                                        <div className='icon'>
                                                                            <span className="material-symbols-outlined"> image </span>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='title'>{segment.title} </p>
                                                                            <a href="#" className="material-symbols-outlined delete" onClick={() => this.deleteSegmentORMarker(segment._id, 'segment')}>delete</a>
                                                                            <div className='time_block'>
                                                                                <a href="#" className='add' onClick={() => this.openMarkerPopup(segment)}> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                                <div className="right">
                                                                                    {segment && segment.startTime && <div className='blk'>  startTime: <span>{segment.startTime}</span></div>}
                                                                                    <div className='blk'>  Duration: <span>{segment.duration}</span></div>
                                                                                    {/* <div className='blk'>  Start: <span>06:10</span></div>
                                                                                <div className='blk'> End : <span>06:50</span></div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                {this.state['isExpand' + index] && <div className='main_sub'>
                                                                    {segment.markers && segment.markers.length > 0 && segment.markers.map((marker, markerIndex) => (
                                                                        <div className='main_item'>
                                                                            <div className='icon'>
                                                                                <span className="material-symbols-outlined"> image </span>
                                                                            </div>
                                                                            <div className='content'>
                                                                                <p className='title'>{marker.title}</p>
                                                                                <a href="#" className="material-symbols-outlined delete" onClick={() => this.deleteSegmentORMarker(marker._id, 'break')}>delete</a>
                                                                                <div className="right">
                                                                                    <div className='blk'>  Duration: <span>{marker.duration}</span></div>
                                                                                    <div className='blk'>  Start: <span>06:10</span></div>
                                                                                    <div className='blk'> End : <span>06:50</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>))}
                                                                    {/* <div className='main_item'>
                                                                        <div className='icon'>
                                                                            <span className="material-symbols-outlined"> image </span>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='title'>REVOLT Mixtape Ad Slate: 120 Seconds</p>
                                                                            <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                            <div className="right">
                                                                                <div className='blk'>  Duration: <span>00:40</span></div>
                                                                                <div className='blk'>  Start: <span>06:10</span></div>
                                                                                <div className='blk'> End : <span>06:50</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='main_item'>
                                                                        <div className='icon'>
                                                                            <span className="material-symbols-outlined"> image </span>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='title'>REVOLT Mixtape Ad Slate: 120 Seconds</p>
                                                                            <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                            <div className="right">
                                                                                <div className='blk'>  Duration: <span>00:40</span></div>
                                                                                <div className='blk'>  Start: <span>06:10</span></div>
                                                                                <div className='blk'> End : <span>06:50</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>}
                                                            </div>))}

                                                    </div>


                                                        {/* <div className="sd_items">
                                                    <div className="sd_item">
                                                        <div className="icon">
                                                            <span className="material-icons">add_circle_outline</span>
                                                        </div>
                                                        <div className="item_title">
                                                            <div className='icon'>
                                                                <span className="material-symbols-outlined"> image </span>
                                                            </div>
                                                            <div className='content'>
                                                                <p className='title'>The Barber of Birmingham ( Film) Abby Ginberg </p>
                                                                <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                <div className='time_block'>
                                                                    <a href="#" className='add'> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                    <div className="right">
                                                                        <div className='blk'>  Duration: <span>00:40</span></div>
                                                                        <div className='blk'>  Start: <span>06:10</span></div>
                                                                        <div className='blk'> End : <span>06:50</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sd_items">
                                                    <div className="sd_item">
                                                        <div className="icon">
                                                            <span className="material-icons">add_circle_outline</span>
                                                        </div>
                                                        <div className="item_title">
                                                            <div className='icon'>
                                                                <span className="material-symbols-outlined"> image </span>
                                                            </div>
                                                            <div className='content'>
                                                                <p className='title'>The Barber of Birmingham ( Film) Abby Ginberg </p>
                                                                <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                <div className='time_block'>
                                                                    <a href="#" className='add'> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                    <div className="right">
                                                                        <div className='blk'>  Duration: <span>00:40</span></div>
                                                                        <div className='blk'>  Start: <span>06:10</span></div>
                                                                        <div className='blk'> End : <span>06:50</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sd_items">
                                                    <div className="sd_item">
                                                        <div className="icon">
                                                            <span className="material-icons">add_circle_outline</span>
                                                        </div>
                                                        <div className="item_title">
                                                            <div className='icon'>
                                                                <span className="material-symbols-outlined"> image </span>
                                                            </div>
                                                            <div className='content'>
                                                                <p className='title'>The Barber of Birmingham ( Film) Abby Ginberg </p>
                                                                <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                <div className='time_block'>
                                                                    <a href="#" className='add'> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                    <div className="right">
                                                                        <div className='blk'>  Duration: <span>00:40</span></div>
                                                                        <div className='blk'>  Start: <span>06:10</span></div>
                                                                        <div className='blk'> End : <span>06:50</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                    </> :
                                                    <div className='no_segment'>
                                                        <span className="material-symbols-outlined"> event_busy </span>
                                                        <p>No Segments Found</p>
                                                    </div>
                                                }
                                            </div>


                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="home-tab">

                                            <div className='meta_right'>
                                                <div className="field">
                                                    {/* <div className='column'>
                                                        <div> <label className='dsp_flx'>Type </label></div>
                                                        <div> <p >Longform</p></div>
                                                    </div> */}
                                                    <div className='column'>
                                                        <div> <label className='dsp_flx'>Genre</label></div>
                                                        <div> <p >{metadataResult && metadataResult.genre && metadataResult.genre.toString()}</p></div>
                                                    </div>
                                                    {/* <div className='column'>
                                                        <div> <label className='dsp_flx'>On-Air Date</label></div>
                                                        <div> <p >--</p></div>
                                                    </div> */}
                                                    <div className='column'>
                                                        <div> <label className='dsp_flx'>Rating</label></div>
                                                        <div> <p >{metadataResult && metadataResult.maturityrating && metadataResult.maturityrating.toString()}</p></div>
                                                    </div>
                                                    {/* <div className="inputgroup">
                                                        <p className="label">External ID(s)</p>
                                                        <select id="markerType" name="markerType">
                                                            <option value="">1 Available</option>
                                                            <option value="Ad Marker">Ad Marker</option>
                                                            <option value="Chapter Marker">Chapter Marker</option>
                                                            <option value="Subclips">Subclips</option>
                                                            <option value="Web Link">Web Link</option>
                                                            <option value="Media Tailor">Media Tailor</option>
                                                        </select>

                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Content Right(s)</p>
                                                        <select id="markerType" name="markerType">
                                                            <option value="">1 Available</option>
                                                            <option value="Ad Marker">Ad Marker</option>
                                                            <option value="Chapter Marker">Chapter Marker</option>
                                                            <option value="Subclips">Subclips</option>
                                                            <option value="Web Link">Web Link</option>
                                                            <option value="Media Tailor">Media Tailor</option>
                                                        </select>

                                                    </div> */}
                                                </div>
                                                {/* <div className="field">
                                                    <label>Tags and Keywords</label>
                                                    <div className='tags'>
                                                        <span className='tag'>No Sleeping In The Room</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <a href="#" className='add'>Add</a>

                                                    </div>
                                                </div> */}
                                                <div >
                                                    <div className="inputgroup">
                                                        <p className="label">Cast</p>
                                                        {/* {cast && <SelectionsExample options={[]} className="custom-select form-control" selected={cast || []} placeholder='Cast' labelKey='cast' isView={true} />} */}
                                                        <p> <span>
                                                            {metadataResult && metadataResult.cast &&
                                                                metadataResult.cast.length > 0 && metadataResult.cast.map(function (item, i) {
                                                                    if (i < 3) {
                                                                        let cast = item && item.cast || item.castrole || '';
                                                                        cast = cast.split(',').splice(0, 3).join(", ");
                                                                        return (
                                                                            <span key={i} className="u-light-text">
                                                                                {cast}{!(i == metadataResult.cast.length - 1) && ","} &nbsp;
                                                                            </span>
                                                                        )
                                                                    }
                                                                })}
                                                        </span></p>
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Crew</p>
                                                        {/* {crew && <SelectionsExample options={[]} className="custom-select form-control" selected={crew || []} placeholder='Crew' labelKey='crew' isView={true} />} */}
                                                        {metadataResult && metadataResult.cast &&
                                                            metadataResult.crew.length > 0 && metadataResult.crew.map(function (item, i) {
                                                                if (i < 3) {
                                                                    let crew = item && item.crew || item.crewrole || '';
                                                                    crew = crew.split(',').splice(0, 3).join(", ");
                                                                    return (
                                                                        <span key={i} className="u-light-text">
                                                                            {crew}{!(i == metadataResult.crew.length - 1) && ","} &nbsp;
                                                                        </span>
                                                                    )
                                                                }
                                                            })}
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Writer</p>
                                                        {/* {writer && <SelectionsExample options={[]} className="custom-select form-control" selected={writer || []} placeholder='Writer' labelKey='item' isView={true} />} */}
                                                        <p className="details-tags">{metadataResult && metadataResult.writer && metadataResult.writer.toString()}</p>
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Distributer</p>
                                                        {/* {distributer && <SelectionsExample options={[]} className="custom-select form-control" selected={distributer || []} placeholder='Distributer' labelKey='item' isView={true} />} */}
                                                        <p className="details-tags">{metadataResult && metadataResult.distributer && metadataResult.distributer.toString()}</p>
                                                    </div>

                                                    <div className="inputgroup">
                                                        <p className="label">Awards</p>
                                                        {/* {award && <SelectionsExample options={[]} className="custom-select form-control" selected={award || []} placeholder='Awards' labelKey='item' isView={true} />} */}
                                                        <p className="details-tags">{metadataResult && metadataResult.award && metadataResult.award.toString()}</p>
                                                    </div>

                                                    <div className="inputgroup">
                                                        <p className="label">Studio</p>
                                                        <p className="details-tags">{metadataResult && metadataResult.studio && metadataResult.studio.toString()}</p>
                                                        {/* {assetData && assetData.studio && assetData.studio.toString()}
                                                        {studio && <SelectionsExample options={[]} className="custom-select form-control" selected={studio || []} placeholder='Studio' labelKey='item' isView={true} />} */}
                                                    </div>

                                                    <div className='inputgroup'>
                                                        <p className="label"><span>Tags</span></p>
                                                        {/* {tags && <SelectionsExample options={[]} className="custom-select form-control" selected={tags || []} placeholder='Tags' labelKey='item' isView={true} />} */}
                                                        <p className="details-tags">{metadataResult && metadataResult.tags && metadataResult.tags.toString()}</p>
                                                    </div>
                                                </div>
                                                {/* <div className='mrk_block'>
                                                    <div className="sd_items">
                                                        <div className="sd_item">
                                                            <div className="icon">
                                                                <span className="material-icons">add_circle_outline</span>
                                                            </div>
                                                            <div className="item_title">
                                                                <div className='left'>
                                                                    <div>Series</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="sd_items">
                                                        <div className="sd_item">
                                                            <div className="icon">
                                                                <span className="material-icons">add_circle_outline</span>
                                                            </div>
                                                            <div className="item_title">
                                                                <div className='left'>
                                                                    <div>Season</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sd_items">
                                                        <div className="sd_item">
                                                            <div className="icon">
                                                                <span className="material-icons">remove_circle_outline</span>
                                                            </div>
                                                            <div className="item_title">
                                                                <div className='left'>
                                                                    <div>Episode</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='main_sub'>
                                                        <div className="inputgroup">
                                                            <p className="label">Episode name</p>
                                                            <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" value="No Sleeping in the Trophy Room" />
                                                            <span className="errormsg"></span>
                                                        </div>
                                                        <div className="inputgroup">
                                                            <p className="label">Episode genre</p>
                                                            <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" value="No Sleeping in the Trophy Room" />
                                                            <span className="errormsg"></span>
                                                        </div>
                                                        <div className="inputgroup">
                                                            <p className="label">Episode short description</p>
                                                            <textarea className="channel_description" rows="4" placeholder="Description" name="description"></textarea>
                                                        </div>

                                                    </div>
                                                </div> */}



                                                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Metadata</a>
                                            </li> */}
                                                {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pattern</a>
                                        </li> */}
                                                {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Linked Collection</a>
                                        </li> */}
                                                {/* </ul> */}
                                                {/* <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div className="inputgroup">
                                                    <p className="label">Cast</p>
                                                    {cast && <SelectionsExample options={[]} className="custom-select form-control" selected={cast || []} placeholder='Cast' labelKey='cast' isView={true} />}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className="label">Crew</p>
                                                    {crew && <SelectionsExample options={[]} className="custom-select form-control" selected={crew || []} placeholder='Crew' labelKey='crew' isView={true} />}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className="label">Writer</p>
                                                    {writer && <SelectionsExample options={[]} className="custom-select form-control" selected={writer || []} placeholder='Writer' labelKey='item' isView={true} />}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className="label">Distributer</p>
                                                    {distributer && <SelectionsExample options={[]} className="custom-select form-control" selected={distributer || []} placeholder='Distributer' labelKey='item' isView={true} />}
                                                </div>

                                                <div className="inputgroup">
                                                    <p className="label">Awards</p>
                                                    {award && <SelectionsExample options={[]} className="custom-select form-control" selected={award || []} placeholder='Awards' labelKey='item' isView={true} />}
                                                </div>

                                                <div className="inputgroup">
                                                    <p className="label">Studio</p>
                                                    {studio && <SelectionsExample options={[]} className="custom-select form-control" selected={studio || []} placeholder='Studio' labelKey='item' isView={true} />}
                                                </div>

                                                <div className='inputgroup'>
                                                    <p className="label"><span>Tags</span></p>
                                                    {tags && <SelectionsExample options={[]} className="custom-select form-control" selected={tags || []} placeholder='Tags' labelKey='item' isView={true} />}
                                                </div> */}
                                                {/* <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div> */}
                                                {/* </div> */}
                                                {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                        </div> */}
                                                {/* <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                        </div> */}
                                                {/* </div> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {(assetInfo && assetInfo.extendedProps) && (assetInfo.extendedProps.scheduleId || assetInfo.extendedProps.scheduleUUID) && <div className='pop_footer'>
                                    {/* <p className='note'>All fields marked with * are required</p> */}
                                    <div className='btns'>
                                        {/* <button className='btn' onClick={e => self.setState({ isMetaDataReady: false })}>Cancel</button> */}
                                        <button className='btn submit' onClick={e => self.deleteSchedule(e)} >Remove</button>
                                    </div>
                                </div>}
                                {/* <div className='pop_footer'>
                                    <div className='btns'>
                                        <button className='btn' onClick={e => self.setState({ isMetaDataReady: false })}>Cancel</button>
                                        <button className='btn submit' onClick={e => self.scheduleData(e)} >Schedule</button>
                                    </div>
                                </div> */}
                            </div>
                            {playlistAssets && playlistAssets.length > 8 ?
                                <div className='assets_list'>

                                    <div className='assets_thumbnail'>
                                        <h6>Assets</h6>

                                        <div className='slick_thumbs'>

                                            <Slider {...slickSettings}>
                                                {playlistAssets.map((item, i) => {
                                                    //console.log('item',item)
                                                    return (
                                                        <div>
                                                            <img src={item && item.landscape_logo_image} onClick={e => self.assetPopulateClick(e, item)} />
                                                            <p>{item && item.assetname}</p>
                                                        </div>)
                                                }
                                                )}
                                            </Slider>
                                        </div>
                                    </div>
                                </div> : playlistAssets && playlistAssets.length > 0 &&
                                <div className='assets_list'>

                                    <div className='assets_thumbnail'>
                                        <h6>Assets</h6>

                                        <div className='thumbnail_block'>

                                            {playlistAssets.map((item, i) => {
                                                return (
                                                    <div className='thumbnail-item'>
                                                        <img src={item && item.landscape_logo_image} onClick={e => self.assetPopulateClick(e, item)} />
                                                        <p>{item && item.assetname}</p>
                                                    </div>)
                                            }
                                            )}
                                        </div>
                                    </div>

                                </div>}
                        </div>}
                    </div>

                </div>
                <Footer />
                <SweetAlert className="success" show={this.state.custom}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={this.state.showMessage}
                    onConfirm={e => this.onConfirm()}
                ></SweetAlert>
                <SweetAlert className="success" show={this.state.availabilityError}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={"Note: Availability start and end dates is not avaialble. please add availability dates"}
                    onConfirm={e => this.onConfirm()}
                ></SweetAlert>


                <Modal
                    isOpen={this.state.isadvancedSearch}
                    style={customStyles}
                    ariaHideApp={false}

                    // shouldCloseOnOverlayClick={true}
                    contentLabel="Example Modal"
                    className="custom_modal u-conf-popup assetadv_srh">
                    <div className='popup add-channel add-Segment'>
                        <div className='popup_hed'>
                            <h2>Advance Search</h2>
                            <a href="#" onClick={e => self.advancedClick()} className='close material-symbols-outlined'>close </a>
                        </div>
                        <div className='popup_content'>
                            <div className="add_calender">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="inputgroup">
                                            <p className="label">Category</p>
                                            {menusData ? <SelectionsExample options={menusData} onChange={self.handleMultiSelect}
                                                selected={formvalues.carousel}
                                                placeholder='Category' /> :
                                                <select disabled={true} className="custom-select form-control">
                                                    <option value="" >No Data</option>
                                                </select>}
                                            <span className="errormsg">{this.state.errors.type}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            <p className='label'>Genre</p>
                                            {/* <label className="form-label form-label">Genre</label> */}
                                            {genreData ? <SelectionsExample options={genreData} onChange={self.handleMultiSelect} selected={formvalues.genre} placeholder='Genre' /> :
                                                <select disabled={true} className="custom-select form-control">
                                                    <option value="" >No Data</option>
                                                </select>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            {/* <p className='label'>Owner</p> */}
                                            <p className="label">Rating</p>
                                            {ratingData ? <SelectionsExample options={ratingData} onChange={self.handleMultiSelect} selected={formvalues.maturityrating} placeholder='Rating' /> :
                                                <select disabled={true} className="custom-select form-control">
                                                    <option value="" >No Data</option>
                                                </select>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            <p className="label">Creator</p>
                                            <SelectionsExampleSearch userType={creatorMenuId} isLoading={self.state.isCreatorLoading} disabled={false} isActive={false} _handleSearch={self._handleSearch} onChange={self.handleMultiSelect} placeholder='Creator' labelKey='name' options={self.state.creatorOptions} selected={formvalues.creator} multiple={true} allowNew={false} />
                                            <span className="errormsg">{this.state.errors.title}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            <p className="label">Released Between</p>
                                            <input className="form-control u-calendar" name="releasedateFrom" type="date" value={formvalues.releasedateFrom} onChange={e => self.handleChangeAsset(e)} placeholder="Release Date" />
                                            <span className="errormsg">{errors && errors.releasedateTo}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup no_label'>
                                            <input className="form-control u-calendar" name="releasedateTo" type="date" value={formvalues.releasedateTo} onChange={e => self.handleChangeAsset(e)} placeholder="Release Date" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>

                                            <p className="label">Availablity Between</p>
                                            <input className="form-control u-calendar" name="availableFrom" type="date" value={formvalues.availableFrom} onChange={e => self.handleChangeAsset(e)} placeholder="Availablity From" />
                                            <span className="errormsg">{errors && errors.availableTo}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup no_label'>
                                            <input className="form-control u-calendar" name="availableTo" type="date" value={formvalues.availableTo} onChange={e => self.handleChangeAsset(e)} placeholder="Availablity To" />
                                        </div>
                                    </div>
                                </div>
                                <div className='footer_btn'>
                                    <button type="button" className="add" onClick={e => self.handleClear(e)} >clear</button>
                                    <button type="button" className="add ms-2" onClick={e => self.handleSearch(e)} >search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.isChannelAdvSearch}
                    style={customStyles}
                    ariaHideApp={false}

                    // shouldCloseOnOverlayClick={true}
                    contentLabel="Example Modal"
                    className="custom_modal u-conf-popup assetadv_srh">
                    <div className='popup add-channel add-Segment'>
                        <div className='popup_hed'>
                            <h2>Advance Search</h2>
                            <a href="#" onClick={e => self.advancedChannelClick()} className='close material-symbols-outlined'>close </a>
                        </div>
                        <div className='popup_content'>
                            <div className="add_calender">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="inputgroup">
                                            <p className='label'>Service provider</p>
                                            <select className="custom-select form-control" placeholder="Select Service Provider" onChange={e => this.handleChangeChannel(e)} value={formvalues1.serviceprovider} name="serviceprovider">
                                                <option value="">Select One</option>
                                                <option value="envoiPlaylistByURL">Playlist By URL</option>
                                                <option value="awsInteractiveVideo">AWS Interactive Video Service</option>
                                                <option value="awsMediaLive">AWS Media Live</option>
                                                <option value="awsMediaTailor">AWS Media Tailor</option>
                                                <option value="awsMediaPackage">AWS Media Package</option>
                                                <option value="googleLiveStream">Google Live Streaming</option>
                                                <option value="vimeoLivestream">Vimeo Live Streaming</option>
                                            </select>
                                            <span className="errormsg">{this.state.errors.type}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            <p className='label'>Creator</p>
                                            {/* <label className="label">Creator</label> */}
                                            <SelectionsExampleSearch isLoading={self.state.isCreatorLoading} disabled={false} isActive={false} _handleSearch={self._handleSearchChannel} onChange={self.handleMultiSelectChannel} placeholder='Creator' labelKey='name' options={self.state.creatorOptions} selected={formvalues1.creator} multiple={true} allowNew={false} />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            {/* <p className='label'>Owner</p> */}
                                            <p className="label">Owner</p>
                                            <SelectionsExampleSearch disabled={false} isLoading={isUserLoading} _handleSearch={self._handleSearchChannelOwner} isActive={false} options={userData} onChange={self.handleMultiSelect} placeholder={'Owner'} labelKey='emailid' selected={formvalues1.owner} multiple={false} allowNew={false} />
                                            {/* <input type="text" placeholder='Search For a Owner' name='title' value={segmentData.title} onChange={(e) => this.onChangeSegment(e)} /> */}
                                            <span className="errormsg">{this.state.errors.title}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup'>
                                            {/* <p className='label'>Channel Code</p> */}
                                            {/* <input type="text" placeholder='Channel Code' name='title' value={segmentData.title} onChange={(e) => this.onChangeSegment(e)} /> */}

                                            <p className="label">Channel Code</p>
                                            {/* <h3 className="card-heading"><span>Channel Code</span></h3> */}
                                            <input className="form-control channel_code" name="channelcode" type="text" value={formvalues1.channelcode} onChange={e => self.handleChangeChannel(e)} placeholder="Channel Code" />
                                            <span className="errormsg">{this.state.errors.title}</span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='inputgroup'>

                                            <p className="label">Created Between</p>
                                            <input className="form-control u-calendar" name="createdFrom" type="date" value={formvalues1.createdFrom} onChange={e => self.handleChangeChannel(e)} placeholder="Created From" />
                                            <span className="errormsg">{errors && errors.createddateTo}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputgroup no_label'>
                                            <input className="form-control u-calendar" name="createdTo" type="date" value={formvalues1.createdTo} onChange={e => self.handleChangeChannel(e)} placeholder="Created To" />
                                        </div>
                                    </div>
                                </div>
                                <div className='footer_btn'>
                                    <button type="button" className="add" onClick={e => self.handleChannelClear(e)} >clear</button>
                                    <button type="button" className="add ms-2" onClick={e => self.handleSearchChannel(e)} >search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <SweetAlert className="success" show={this.state.createChannelValidation}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={"Please add metadata and channel status active"}
                    onConfirm={e => this.onConfirm()}
                ></SweetAlert>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
            </React.Fragment >
        )

    }
}

const mapState = ({ loading, manageSchedulingData, manageSchedulesData, assets, playlistAssetsData, scheduleEventsData, playbackUrls, channels, mt_channelsdata, manageChannelsData, mediatailorChannelData, markers, insertMarkerSuccess, updateMarkerSuccess, vodSourcesData, sourceLocationData, channelList, channelAssetsProps, categoryInfo, genereInfo }) => ({ loading, manageSchedulingData, manageSchedulesData, assets, playlistAssetsData, scheduleEventsData, playbackUrls, channels, mt_channelsdata, manageChannelsData, mediatailorChannelData, markers, insertMarkerSuccess, updateMarkerSuccess, vodSourcesData, sourceLocationData, channelList, channelAssetsProps, categoryInfo, genereInfo });
const mapDispatch = { manageScheduling, manageSchedules, getAssets, playlistAssets, scheduleEventsToS3, getPlaybackUrls, manageChannels, getChannels, createMediatailorChannel, fetchMarkers, inserAssetMarkers, msTimeMilliseconds, getVodSources, getSourceLocation, deleteAssetMarkers, channelsList, channelAssets, getCategory, getLookup };
export default withRouter(connect(mapState, mapDispatch)(Scheduling));