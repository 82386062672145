import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from "react-router";
import { managePlayList } from '../../redux/reducers/reducer';
import SweetAlert from 'react-bootstrap-sweetalert';
import { removeSpecialCharecters, location } from '../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Loader";

import Modal from 'react-modal';
import axios from 'axios';
import ReactTooltip from 'react-tooltip'
import NewManagePlaylist from './NewManagePlaylist';
let siteSettings = window.site;
let siteConfig = siteSettings.config;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let { appName } = window.app;
let urlParams = location("id");
const sideMenuList = [
    {
        id: '1',
        name: 'info',
        labelName: 'Playlist Info'
    },
    // {
    //     id: '2',
    //     name: 'playlist_add_check_circle',
    //     labelName: 'Scheduling Info'
    // },
    {
        id: '3',
        name: 'playlist_add_check_circle',
        labelName: 'Playlist Assets'
    },
    
]

class NewPlaylist extends Component {
    constructor() {
        super();
        this.state = {
            errors: {},
            privacy: false,
            playlistname: '',
            buttonClass: "btn btn-green jr-btn un-btn",
            buttonClassGray: "btn btn-gray jr-btn un-btn",
            projectinfo: {},
            showalert: false,
            deleteEvent: false,
            showalertmsg: "",
            updateform: false,
            isActiveId: sideMenuList[0].id,
            loading: false,
            addMetadata: false,
            successPopup: false
        }


    }

    componentDidMount() {
        let self = this;
        // let id=this.props?.match?.params?.id;
        // if (id) {
        //     this.setState({ id: id });
        //     var playlistbody = { activity: "fetch", }
        //     self.props.managePlayList(playlistbody, id);

        // }
        if (urlParams) {
            this.setState({ id: urlParams });
            var playlistbody = { activity: "fetch", }
            let id = urlParams;
            self.props.managePlayList(playlistbody, id);

        }
    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.manageplaylistsData !== this.props.manageplaylistsData) {
            let manageplaylistsData = this.props.manageplaylistsData;
            if (manageplaylistsData && manageplaylistsData.playlistId) {
                window.location = '/newplaylist/?id=' + manageplaylistsData.playlistId
                this.setState({
                    updateform: false, loading: false,
                });
            } else if (this.props.manageplaylistsData && this.props.manageplaylistsData.statusCode == 200) {
                // window.location = '/playlistasset/?id=' + self.state.id
                var playlistbody = { activity: "fetch", }

                self.props.managePlayList(playlistbody, self.state.id);
                this.setState({
                    updateform: false, loading: false, successPopup: true
                });
            } else {
                let playlistAssetData = manageplaylistsData
                //((manageplaylistsData || {}).data || [])[0] || {}
                this.setState({
                    projectinfo: playlistAssetData, updateform: false, loading: false, privacy: playlistAssetData.public, playlistname: playlistAssetData.playlistname
                });
            }
        }
    }
    SuccessNotify = () => toast.success('Updated Successfully', window.app.toastConfig)
    errorToast = (msg) => toast.error(msg, window.app.toastConfig)

    backToChannels(){
        // browserHistory.push('/home');
        window.location = '/scheduler'
    }
    handleFormChange(e) {
        let self = this;
        let projectinfo = self.state.projectinfo;
        // console.log('projectinfo before', self.state.projectinfo)
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            this.setState({
                [e.target.name]: e.target.value,
                errors
            });
        } else {
            const name = e.target.name;
            var value = e.target.value;
            // console.log('nameeeeee', name)
            // console.log('value', value)
            if (name == "privacy") {
                value = JSON.parse(value)
            }
            projectinfo[name] = value;
            this.setState({ [name]: value, projectinfo });
        }
        // console.log('projectinfo after', projectinfo)
        // console.log('self.state.getFormData', self.state.getFormData)
    }
    onClickMenu(id) {
        let self = this;
        self.setState({ isActiveId: id })
    }
    formValidation() {
        let errors = {};

        let formIsValid = true;
        // console.log('this.state.playlistname', this.state.playlistname)
        // console.log('this.state.projectinfo', this.state.projectinfo)
        // console.log('this.state.playlistname',this.state.playlistname)
        if (this.state.playlistname == undefined || this.state.playlistname == "") {
            formIsValid = false;
            // errors.playlistname = "Enter Playlist";
            this.errorToast('Enter Playlist');
        }
        // this.setState({ errors: errors });

        return formIsValid;
    }
    handlePlayListSubmit() {
        let self = this;
        const { privacy, landscapeImage, portraitImage } = this.state;
        // console.log('privacyyy handle play submit', privacy)
        let projectinfo = this.state.projectinfo;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;

        // let logoImage = siteSettings && siteSettings.logoImage;
        if (self.formValidation()) {
            // const id2 = toast.loading("Saving...")
            // self.setState({ updateform: true, loading: true, });
            let playlistbody = {
                playlistname: projectinfo.playlistname,
                public: projectinfo.privacy,
                activity: self.state.id ? "update" : "create",
                landscape_logo_image: projectinfo && projectinfo.landscape_logo_image || landscapeImage || siteConfig.defaultLandscape,
                portrait_logo_image: projectinfo && projectinfo.portrait_logo_image || portraitImage || siteConfig.defaultPortrait,
                type: "playlist",
                playlistids: self.state.id ? [self.state.id] : []
            }
            if (self.state.id) {

                this.props.managePlayList(playlistbody, self.state.id);
                // this.setState({
                //     updateform: false, loading: false, successPopup: true
                // });
                self.SuccessNotify()
                // toast.update(id2, { render: "Updated Successfully", type: "success", isLoading: false, autoClose: 2000, });
            } else {
                this.props.managePlayList(playlistbody);
                // toast.update(id2, { render: "please check & try again", type: "error", isLoading: false, autoClose: 2000, });
            }

        }
    }
    onConfirm() {
        this.setState({ custom: false, showMessage: false, showalert: false, addMetadata: false, successPopup: false });
    };
    onConfirmButton() {
        let self = this;
        this.setState({ successPopup: false });
        var playlistbody = { activity: "fetch", }
        self.props.managePlayList(playlistbody, self.state.id);
    }
    uploadS3(e, type) {
        var self = this;
        var fileData = new FormData();
        var file = e.target.files[0];
        var uploadFilePath = "";
        var filename = e.target.files[0].name;
        var s3file = e.target.files[0];
        var filekey = type[2];
        fileData.append(type[1], s3file);
        self.setState({ fileData: file, type, filekey })
        if (self.state.projectinfo && self.state.projectinfo.playlistname && !self.state.projectinfo.playlistname == "" || !self.state.projectinfo.playlistname == undefined || !self.state.projectinfo.playlistname == null) {
            var bucket;
            var imgHight;
            var imgWidth;
            if (type[1] == "Image") {
                if (type[0] == "1000*1294") {
                    imgHight = 1294;
                    imgWidth = 1000;
                } else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "3000*600") {
                    imgHight = 600;
                    imgWidth = 3000;
                }
                else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "175") {
                    //imgHight = 1080;
                    imgWidth = 610;
                }
                let height;
                let width;
                bucket = imageSourcebucket;
                var reader = new FileReader();
                reader.readAsDataURL(s3file);
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        height = this.height;
                        width = this.width;
                        let imagePathWidth;
                        if (imgWidth == "610") {
                            imagePathWidth = width <= imgWidth
                        } else {
                            imagePathWidth = width == imgWidth && height == imgHight
                        }
                        if (imagePathWidth) {
                            const id2 = toast.loading("Saving...")

                            bucket = imageSourcebucket;
                            var path = "submission/partner";
                            // let id = self.state.getFormData && self.state.getFormData._id;
                            let name = self.state.projectinfo && self.state.projectinfo.playlistname;
                            name = name && name.toLowerCase();
                            name = name && name.replace(/ /g, "-");
                            name = name && name.replace(/[^a-zA-Z 0-9]/g, "");
                            let folder = name;
                            uploadFilePath = appName + "/" + path + "/" + folder + "/" + removeSpecialCharecters(filename);
                            var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                            self.setState({ uploadFilePath })
                            let siteSettings = window.site;
                            let siteConfig = siteSettings.config;
                            let imagePath = siteConfig && siteConfig.appResourcesUrl;
                            axios.post(envoiSubmissionApiBaseUrl + '/uploadURL?appname=' + appName + '&token=' + token, data, { type: 'application/json' })
                                .then((response) => {
                                    if (response.data && response.data.result) {
                                        var url = response.data.result;
                                        let config = {
                                            onUploadProgress: function (progressEvent) {
                                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                                self.setState({ [type[3]]: percentCompleted });
                                            }
                                        };
                                        axios.put(url, file, config, {
                                            "headers": {
                                                "Content-Type": "multipart/form-data",
                                                "Accept": "/",
                                                "Cache-Control": "no-cache",
                                                "Accept-Encoding": "gzip, deflate",
                                                "Connection": "keep-alive",
                                                "cache-control": "no-cache"
                                            }
                                        })
                                            .then((response) => {
                                                if (response && response.status === 200) {
                                                    var filename = self.state.uploadFilePath;
                                                    let imageUploadPath = imagePath + filename;
                                                    if (imageUploadPath.includes(appName + "/" + appName)) {
                                                        imageUploadPath = imageUploadPath.replace(appName + "/" + appName, appName);
                                                    }
                                                    let playlistData = {
                                                        [self.state.filekey]: imageUploadPath,
                                                    }
                                                    // self.setState({
                                                    //     custom: true,
                                                    //     showMessage: "Updated Successfully",
                                                    //     channelData
                                                    // })
                                                    self.setState({
                                                        landscapeImage: playlistData.landscape_logo_image, portraitImage: playlistData.portrait_logo_image
                                                    })
                                                    toast.update(id2, { render: "Updated Successfully", type: "success", isLoading: false, autoClose: 2000, });
                                                    // if (id) {
                                                    //     self.props.manageChannels(channelData, id);
                                                    // } else {
                                                    //     self.props.manageChannels(channelData);
                                                    // }
                                                    // this.props.managePlayList(channelData);
                                                }
                                            })
                                            .catch((err) => {
                                                console.error.bind(err);
                                            })
                                    }
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })
                        } else {
                            // self.setState({ showalert: true, showalertmsg: 'Please select image(' + type[0] + ')' });
                            self.errorToast('Please select image(' + type[0] + ')')
                        }
                    };
                }
            } else {
                bucket = assetSourcebucket;
            }
        } else {
            self.setState({ addMetadata: true });
        }
    }
    render() {
        let self = this;
        const { } = this.props;
        const { updateform, loading, isActiveId } = this.state;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        // console.log('self.state.project info render', self.state.projectinfo)
        return (
            <React.Fragment>
                <div className="edit-channel">
                    <header className="fixed-top live-header">
                        <div className="container-fluid">
                            <div className="sec-header"><a onClick={() => self.backToChannels()}><span className="material-symbols-outlined"> arrow_back </span></a>
                                {/* <h3>{self.state.getFormData && self.state.getFormData.channelname}sfdg</h3> */}
                                <h3 className='text-center'> {self.state.id ? self.state.projectinfo && self.state.projectinfo.playlistname : ''}</h3>


                                <a className=""></a>
                            </div>
                        </div>
                    </header>
                    <div className="envlive_wrapper minbody-ht">
                        <div className="dashboard_panel">
                            <div className='panel_menu'>

                                <ul>
                                    {sideMenuList.map(eachItem => (
                                        <li key={eachItem.id}><a href='#' className={`material-icons ${eachItem.id === isActiveId ? 'active' : ''}`} onClick={() => self.onClickMenu(eachItem.id)} ref={ref => this.fooRef = ref} data-tip={eachItem.labelName}>{eachItem.name}</a>   <ReactTooltip /></li>
                                    ))}
                                    {/* <p ref={ref => this.fooRef = ref} data-tip='tooltip'>hellloooooo</p> */}

                                </ul>

                            </div>
                            {isActiveId === '1' &&
                                <div className='panel_content'>

                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div className='form_section about_playlist'>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <h4 className='mb-3'> Playlist Info</h4>
                                                        <div className='inputgroup'>
                                                            <p className='label'>Playlist name</p>
                                                            <input type="text" placeholder="Enter PlayList Name" onChange={e => this.handleFormChange(e)} value={self.state.playlistname} name="playlistname" />
                                                            <span className="errormsg" >{this.state.errors.playlistname}</span>
                                                        </div>
                                                        <div className="inputgroup mb-0">
                                                            <p className='label'>Privacy</p>
                                                            <select name="privacy" value={self.state.privacy} onChange={e => this.handleFormChange(e)} >
                                                                <option value={false}>Private</option>
                                                                <option value={true}>Public</option>
                                                            </select>
                                                            {/* <div><span className="errormsg" >{errors && errors.privacy}</span></div> */}

                                                        </div>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <h4 className='mb-3'>Playlist Branding</h4>
                                                        <div className='uploadPics'>
                                                            <div className='upload'>
                                                                <div className="img_block">
                                                                    <img src={self.state.projectinfo && self.state.projectinfo.landscape_logo_image ? self.state.projectinfo.landscape_logo_image + siteConfig.imageSuffix : siteConfig.defaultLandscape + siteConfig.imageSuffix} />
                                                                    <div className="upload_btns">
                                                                        <div className={self.state.landscapeLogoProgress && self.state.landscapeLogoProgress == 100 ? self.state.buttonClass : self.state.buttonClassGray} value={self.state.landscapeLogoProgress}><input type="file" accept="image/png, image/jpeg , image/*" name="landscape_logo_image" className="udisplay-none" onChange={self.state.landscape_logo_image && self.state.landscape_logo_image ? e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"]) : e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                            {"Upload"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="size">Landscape  (1920*1080)</div>
                                                            </div>
                                                            <div className='upload portrait'>
                                                                <div className="img_block u-potrait">
                                                                    <img className="portait_img" src={self.state.projectinfo && self.state.projectinfo.portrait_logo_image ? self.state.projectinfo.portrait_logo_image + siteConfig.imageSuffix : siteConfig.defaultPortrait + siteConfig.imageSuffix} />
                                                                    <div className="upload_btns">
                                                                        <div className={self.state.portraitLogoProgress && self.state.portraitLogoProgress == 100 ? self.state.buttonClass : self.state.buttonClassGray} value={self.state.portraitLogoProgress}><input type="file" accept="image/png, image/jpeg , image/*" name="portrait_logo_image" className="udisplay-none " onChange={self.state.portrait_logo_image && self.state.portrait_logo_image ? e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"]) : e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                            {"Upload"}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="size">Portrait (1000*1294)</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-3'></div>
                                        {/* <MiniPlayer getFormData={self.state.getFormData}/> */}
                                        {/* <div className='col-md-3'>
                                            <div className='video_preview'>
                                                <div className='video' onClick={() => self.playerClick(self.state.getFormData, "playMovie")} id="playMovie">
                                                    <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a>
                                                    {!popupPlayer &&
                                                        <img src='https://vlive.imgix.net/screening/images/landscape_logo.jpg?auto=compress,format' />}
                                                </div>
                                                <div className='video_info'> */}
                                        {/* {self.state.getFormData && self.state.getFormData.created && <div className='date'>Created: {self.state.getFormData.created}</div>} */}
                                        {/* {self.state.getFormData && self.state.getFormData.formatedduration &&
                                                        <div className='drs'>{self.state.getFormData.formatedduration}</div>}
                                                </div>
                                            </div>

                                        </div> */}
                                        {/* <div className='col-md-3'>
                                 <div className='video_preview'>
                                    <div className='video'>
                                        <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a>
                                  
                                        <img src='https://vlive.imgix.net/screening/images/landscape_logo.jpg?auto=compress,format' />
                                    </div>
                                    <div className='video_info'>
                                        <div className='date'>Created: 08-11-2022</div>
                                        <div className='drs'>5:25</div>
                                    </div>
                                 </div>
                                    
                                </div> */}

                                    </div>
                                </div>
                            }
                            {isActiveId === '2' &&
                                <div className='panel_content'>

                                    coming soon..
                                </div>
                            }
                            
                            {isActiveId === '3' &&
                                <div className='panel_content'>
                                    <NewManagePlaylist />
                                </div>
                            }


                        </div>
                    </div>
                    <div className="form_footer form_footer1">
                        <div></div>

                        <div className="form_buttons">
                            {isActiveId === '1' && <>
                                <button type="button" onClick={e => this.backToChannels(e)} className="btn btn-link">cancel</button>
                                <button type="button" onClick={e => this.handlePlayListSubmit(e)} className="btn btn-primary">{updateform ? "Saving...." : "Save"}</button>
                            </>
                            }
                        </div>

                    </div>
                    <footer className="footer">
                        <ToastContainer position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light" />
                        <div className="container-fluid"><p className="footer-para">  &copy; 2022 Envoi Live. All rights reserved. </p></div>
                    </footer>
                </div>
                <SweetAlert show={this.state.showalert}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={this.state.showalertmsg}
                    onConfirm={e => this.onConfirm('imageError')}
                >
                </SweetAlert>
                <SweetAlert show={this.state.addMetadata}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={"Please Enter (Playlist Name required)"}
                    onConfirm={e => this.onConfirm()}
                >
                </SweetAlert>
                <SweetAlert show={this.state.successPopup}
                    custom
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="primary"
                    title={"Updated Successfully"}
                    onConfirm={e => this.onConfirmButton()}
                >
                </SweetAlert>
            </React.Fragment>
        )

    }
}

const mapState = ({ manageplaylistsData, loading }) => ({ manageplaylistsData, loading });
const mapDispatch = { managePlayList, };
export default connect(mapState, mapDispatch)(NewPlaylist);