import React, { Component } from 'react';
import { connect } from 'react-redux';
import { managePlayList } from '../../redux/reducers/reducer';
import { withRouter } from 'react-router-dom';
import Loader from "../Loader";
import axios from "axios";
import { Modal, ModalBody, ModalHeader, CardBody, TabContent } from 'reactstrap';
// import { isArray } from 'util';
let bitmovininterval;
let playerStream = 'hls';
let playlist = [];
let player;
let playlistCursor;
let playlistShouldAutoPlay = true;
let playlistShouldResetOnFinish = true;
let isPaused = false;
let playlistShouldRepeat = true;
let manageUserActivityInterval = 10000;
let sendMarkersCurrenttime = 100;
let manageUserActivityTimeinterval;
let previewStartTime = 0;
let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
let siteConfig = siteSettings && siteSettings.config || {};
let sitePlayerConfig = siteConfig && siteConfig.player || {};
// Bitmovin Player variables - for legacy use
let sitePlayerBitmovinConfig = sitePlayerConfig && sitePlayerConfig.bitmovin || {};
let bitmovinPlayerKey = sitePlayerBitmovinConfig && sitePlayerBitmovinConfig.playerKey;
let bitmovinAnalyticsKey = sitePlayerBitmovinConfig && sitePlayerBitmovinConfig.analyticsKey;
let config = {
    "key": bitmovinPlayerKey
};
let configWithAnalytics = Object.assign({}, config, {
    analytics: {
        key: bitmovinAnalyticsKey,
    },
    playback: {
        autoplay: false
    }
});
var locationObj = JSON.parse(localStorage.getItem("loc"));
if (locationObj) {
    let locationData = locationObj.headers || {}
    let geoLocation = {}
    if (Object.keys(locationData).length) {
        geoLocation['city'] = locationData['cloudfront-viewer-city'] && locationData['cloudfront-viewer-city'][0] && locationData['cloudfront-viewer-city'][0].value || '';
        geoLocation['region'] = locationData['cloudfront-viewer-country-region'] && locationData['cloudfront-viewer-country-region'][0] && locationData['cloudfront-viewer-country-region'][0].value || '';
        geoLocation['country'] = locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value || '';
        geoLocation['timezone'] = locationData['cloudfront-viewer-time-zone'] && locationData['cloudfront-viewer-time-zone'][0] && locationData['cloudfront-viewer-time-zone'][0].value || '';
    }
    locationUrl = '&country=' + geoLocation.country || ""
    locationUrl += '&region=' + geoLocation.region || ""
    locationUrl += '&city=' + geoLocation.city || ""
    locationUrl += '&timezone=' + geoLocation.timezone || ""
}
class ChannelPlayer extends Component {
    constructor(props) {
        super(props);
        // console.log("playerStart",props.startPlayer)
        this.videoElement = React.createRef();
        this.state = {
            item: props.item,
            startPlayer: props.startPlayer || false,
            loader: false,
            playerMessage: false
        };
    }


    componentDidMount() {
        let self = this;
        let item = self.state.item || {};
        self.setState({ loader: true })
        // self.props.channelAssets(item.channelcode);
        let playlistbody = {
            activity: "fetch",
            playBackUrls: true
        }
        //self.props.managePlayList(playlistbody, item.uuid)
        let appSettings = window.app
        let appStorage = appSettings.storage;
        let token = appStorage.getItem('token');
        let url = envoiScreeningApiBaseUrl + '/playlistPreview?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName + '&id=' + item.uuid + '&scrubbing=true' + '&playlistPreview=true';
        axios.post(url + locationUrl, playlistbody)

            .then((response) => {
                if (response && response.data && response.data.result) {
                    let channelAssetsAray = [];
                    let channelAssetsData = response.data.result || {};
                    console.log('channelAssetsData', channelAssetsData)
                    if (channelAssetsData && channelAssetsData.assetData && channelAssetsData.serviceprovider == "awsInteractiveVideo") {
                        this.mediaPlayerScriptLoaded(channelAssetsData.assetData, "channel");
                        // self.setState({ channelIvsPlayerData : channelAssetsData[0], loader: false });
                    }
                    else if (channelAssetsData && channelAssetsData.assetData) {
                        let channelassets = channelAssetsData.assetData;
                        self.setState({ channelassets })
                        channelassets && channelassets.length > 0 && channelassets.forEach((element) => {
                            let obj = {
                                assetname: element.assetname,
                                assetid: element.assetid,
                                m3u8_Url: element.playerurls && element.playerurls.m3u8_Url || element.playerurls.m3u8_url
                            }
                            channelAssetsAray.push(obj);
                            if (obj && obj.m3u8_Url == "") {
                                self.setState({ playerMessage: true });
                            } else {
                                self.setState({ channelAssetsAray: channelAssetsAray, channelassets })
                            }
                            // self.bitmovinplayer(channelAssetsAray)

                        });
                        // console.log("channelassets",channelassets)
                        playlist = [];
                        playlistCursor = 0;
                        self.fetchPlaylist(channelassets)
                        self.loadBitmovin();

                    } else {
                        self.setState({ playerMessage: true });
                    }
                    self.setState({ channelAssetsData, loader: false });
                }

            })
            .catch(error => error)

    }
    componentWillReceiveProps(nextProps) {
        var self = this;
        if (nextProps.managePlaylistData !== this.props.managePlaylistData) {
            console.log('nextProps.managePlaylistData', nextProps.managePlaylistData)
            let channelAssetsAray = [];
            let channelAssetsData = nextProps.managePlaylistData || {};
            console.log('channelAssetsData', channelAssetsData)
            if (channelAssetsData && channelAssetsData.assetData && channelAssetsData.serviceprovider == "awsInteractiveVideo") {
                this.mediaPlayerScriptLoaded(channelAssetsData.assetData, "channel");
                // self.setState({ channelIvsPlayerData : channelAssetsData[0], loader: false });
            }
            else if (channelAssetsData && channelAssetsData.assetData) {
                let channelassets = channelAssetsData.assetData;
                channelassets && channelassets.length > 0 && channelassets.forEach((element) => {
                    let obj = {
                        assetname: element.assetname,
                        assetid: element.assetid,
                        m3u8_Url: element.playerurls && element.playerurls.m3u8_Url
                    }
                    channelAssetsAray.push(obj);
                    if (obj && obj.m3u8_Url == "") {
                        self.setState({ playerMessage: true });
                    } else {
                        self.setState({ channelAssetsAray: channelAssetsAray })
                    }
                    // self.bitmovinplayer(channelAssetsAray)

                });
                // console.log("channelassets",channelassets)
                playlist = [];
                playlistCursor = 0;
                self.fetchPlaylist(channelassets)
                self.loadBitmovin();

            } else {
                self.setState({ playerMessage: true });
            }
            self.setState({ channelAssetsData, loader: false });
        }

    }
    mediaPlayerScriptLoaded(channelIvsData, type) {

        // switch(this.props.playerType) {
        //   case 'bitmovin':
        //     return this.mediaPlayerScriptLoadedBitmovinPlayer();

        //   case 'AWS Interactive Video Player':
        //     return this.mediaPlayerScriptLoadedIvsPlayer();

        //   default:
        //     return;
        // }
        const mediaPlayerScript = document.createElement("script");
        mediaPlayerScript.src = 'https://player.live-video.net/1.18.0/amazon-ivs-player.min.js';
        mediaPlayerScript.async = true;
        mediaPlayerScript.onload = () => this.mediaIvsPlayerScriptLoaded(channelIvsData, type);
        document.body.appendChild(mediaPlayerScript);

    }
    mediaIvsPlayerScriptLoaded(assets, type) {
        let self = this;
        // const {assets} = self.state;
        if (assets && assets.length > 0) {
            assets.forEach(function (asset, i) {
                if (!asset) {
                    return;
                }
                let playSource = {}
                let playerUrls = asset.playerurls || {};
                let playSourceUrl = (playerUrls.m3u8_Url || playerUrls.m3u8_url || playerUrls.safestreamUrl || playerUrls.vdms_url || (playerUrls.iconik_proxy_url || playerUrls.iconik_proxies_url) || playerUrls.Mp4_proxy_URL || playerUrls.eluvio_url || playerUrls.ipfs_url || playerUrls.source_Url || '');
                //  console.log("playSourceUrl123456", playSourceUrl)
                if (playSourceUrl && jQuery.type(playSourceUrl) === "string" && playSourceUrl != "") {
                    playerStream = playSourceUrl.includes('.m3u8') ? 'hls' : 'progressive';
                    playSource[playerStream] = playSourceUrl
                }
                self.mediaPlayerScriptLoadedIvsPlayer(playSource.hls);
            })
        }

    }
    mediaPlayerScriptLoadedIvsPlayer(playSourceUrl) {
        let self = this;
        // console.log("thataji", playSourceUrl)
        // This shows how to include the Amazon IVS Player with a script tag from our CDN
        // If self hosting, you may not be able to use the create() method since it requires
        // that file names do not change and are all hosted from the same directory.

        const MediaPlayerPackage = window.IVSPlayer;
        //  console.log('MediaPlayerPackage',MediaPlayerPackage)
        const playerOverlay = document.getElementById("overlay");
        const btnPlay = document.getElementById("play");
        const btnMute = document.getElementById("mute");

        // First, check if the browser supports the Amazon IVS player.
        if (!MediaPlayerPackage.isPlayerSupported) {
            console.warn("The current browser does not support the Amazon IVS player.");
            return;
        }

        const PlayerState = MediaPlayerPackage.PlayerState;
        const PlayerEventType = MediaPlayerPackage.PlayerEventType;

        // Initialize player
        const player = MediaPlayerPackage.create();
        player.attachHTMLVideoElement(document.getElementById("video-player"));
        //   console.log('player',player)
        // Attach event listeners
        player.addEventListener(PlayerState.PLAYING, function () {
            if (config.DEBUG) console.log("Player State - PLAYING");
        });
        player.addEventListener(PlayerState.ENDED, function () {
            if (config.DEBUG) console.log("Player State - ENDED");
        });
        player.addEventListener(PlayerState.READY, function () {
            if (config.DEBUG) console.log("Player State - READY");
        });
        player.addEventListener(PlayerEventType.ERROR, function (err) {
            if (config.DEBUG) console.warn("Player Event - ERROR:", err);
            // console.warn("Player Event - ERROR:", err)
            let assetStatusMessage = "Invalid Streaming";
            self.setState({
                showmessage: true, assetStatusMessage
            })

        });
        player.addEventListener(PlayerEventType.DURATION_CHANGED, function (cue) {
            console.log('player duration', player.getDuration())
        });

        player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, function (cue) {
            const metadataText = cue.text;
            const position = player.getPosition().toFixed(2);
            if (config.DEBUG) console.log(
                `Player Event - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`
            );
            // console.log('metadataText',metadataText)
        });

        // Setup stream and play
        player.setAutoplay(true);
        player.load(playSourceUrl);
        // console.log('player.load',player.load)
        // Setvolume
        player.setVolume(0.1);

        // if (playerOverlay) {
        //     // Show/Hide player controls
        //     playerOverlay.addEventListener(
        //         "mouseover",
        //         function (e) {
        //             playerOverlay.classList.add("overlay--hover");
        //         },
        //         false
        //     );
        //     playerOverlay.addEventListener("mouseout", function (e) {
        //         playerOverlay.classList.remove("overlay--hover");
        //     });
        // }

        // Controls events
        // Play/Pause
        if (btnPlay) {
            btnPlay.addEventListener(
                "click",
                function (e) {
                    if (btnPlay.classList.contains("player-btn--play")) {
                        // change to pause
                        btnPlay.classList.remove("player-btn--play");
                        btnPlay.classList.add("player-btn--pause");
                        player.pause();
                    } else {
                        // change to play
                        btnPlay.classList.remove("player-btn--pause");
                        btnPlay.classList.add("player-btn--play");
                        player.play();
                        console.log('btnPlay', btnPlay)
                    }
                },
                false
            );
        }

        // Mute/Unmute
        if (btnMute) {
            btnMute.addEventListener(
                "click",
                function (e) {
                    if (btnMute.classList.contains("player-btn--mute")) {
                        btnMute.classList.remove("player-btn--mute");
                        btnMute.classList.add("player-btn--unmute");
                        player.setMuted(1);
                    } else {
                        btnMute.classList.remove("player-btn--unmute");
                        btnMute.classList.add("player-btn--mute");
                        player.setMuted(0);
                    }
                },
                false
            );

        }
    }
    loadBitmovin() {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = siteSettings && siteSettings.config || {};
        let sitePlayerConfig = siteConfig && siteConfig.player || {};
        // Bitmovin Player variables - for legacy use
        let sitePlayerBitmovinConfig = sitePlayerConfig && sitePlayerConfig.bitmovin || {};
        let bitmovinPlayerKey = sitePlayerBitmovinConfig && sitePlayerBitmovinConfig.playerKey;
        let bitmovinAnalyticsKey = sitePlayerBitmovinConfig && sitePlayerBitmovinConfig.analyticsKey;
        //   siteSettings.bitmovinPlayerKey = bitmovinPlayerKey;
        //   siteSettings.bitmovinAnalyticsKey = bitmovinAnalyticsKey;
        let config = {
            "key": bitmovinPlayerKey
        };
        let configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitmovinAnalyticsKey,
            },
            playback: {
                autoplay: false
            }
        });
        let container = document.getElementById("player");
        player = new bitmovin.player.Player(container, configWithAnalytics);
        self.setState({ bitmovinReady: true }, () => {
            self.loadBitmovinInterval();
        });

    }

    loadBitmovinInterval() {
        let self = this;
        clearInterval(bitmovininterval);
        bitmovininterval = setInterval(function () {
            if (self.state.bitmovinReady && bitmovin) {
                self.playItem(0);
            }
            clearInterval(bitmovininterval);
        }, 1000);
    }
    fetchPlaylist(assets) {
        let self = this;
        console.log('assets', assets)
        // This would be a result from a get play list API call
        // let playlist = [];
        if (assets && assets.length > 0) {
            assets.forEach(function (asset, i) {
                console.log('asset1234', asset)
                if (asset) {
                    let playSource = {};
                    if (asset && asset.assetid) {
                        playSource['assetid'] = asset.assetid
                    }
                    if (asset && asset.assetname) {
                        playSource['title'] = asset.assetname
                    }
                    if (asset && asset.markers) {
                        playSource['markers'] = asset.markers
                    }
                    if (asset.landscape_logo_image) {
                        playSource['poster'] = asset.landscape_logo_image
                    }
                    // else {
                    //     playSource['poster'] = dummy
                    // }
                    // if (asset.preview && asset.preview.enable && !asset.buy && !asset.rent) {
                    //     playSource['options'] = {
                    //         startTime: previewStartTime
                    //     }
                    // }
                    if (asset.subtitles) {
                        playSource['subtitles'] = asset.subtitles
                    }
                    if (asset.viewduration) {
                        playSource['viewduration'] = asset.viewduration
                    }
                    let playerUrl = (asset && asset.playerurls && asset.playerurls.m3u8_url);
                    console.log('playerUrl', playerUrl)
                    if (asset && asset.playerurls && (asset.playerurls.m3u8_url)) {
                        if (asset.playerurls.m3u8_url.includes(".m3u8")) {
                            playerStream = "hls";
                        } else {
                            playerStream = "progressive";
                        }
                        playSource[playerStream] = asset.playerurls.m3u8_url

                    } else {
                        if (playerUrl && playerUrl.includes(".m3u8")) {
                            playerStream = "hls";
                        } else {
                            playerStream = "progressive";
                        }
                        playSource[playerStream] = playerUrl;

                    }
                    console.log('playSource', playSource)
                    if (playSource[playerStream])
                        playlist.push(playSource);
                }
            })
        }
    }

    playItem(playListIndex) {
        let self = this;

        if (!player) {
            // console.log("player", player)
            ; return;
        }
        if (playlist && !playlist.length) {
            // console.log("playlist", playlist);
            return;
        }
        // console.log('playListIndex', playListIndex)
        let playlistMaxIndex = playlist.length - 1;
        // console.log('playlistMaxIndex', playlistMaxIndex)
        let shouldPlay = true;
        if (playListIndex)
            playlistCursor = playListIndex;
        if (playlistCursor === -1) {
            // We only want to play if autoplay isn't already set on the player
            let playerConfig = player.getConfig();
            let playerIsSetToAutoPlay = playerConfig.playback.autoplay;
            shouldPlay = playlistShouldAutoPlay && !playerIsSetToAutoPlay;
            // Start over!
            // playlistCursor = 0;
        }
        if (playlistCursor <= playlistMaxIndex) {
            // We aren't at the end yet
            playlistCursor++;
        } else if (playlistCursor > playlistMaxIndex) {
            // We've reached the end of the playlist
            // If we don't want to repeat we at least want to load the first element
            if (!playlistShouldRepeat) {
                if (playlistShouldResetOnFinish) {
                    shouldPlay = false;
                } else {
                    return;
                }
            }
            // Start over!
            playlistCursor = 0;
        }
        // console.log("playlistCursor!!!!", playlistCursor);
        let source = playlist[playlistCursor];
        // console.log('source', source)
        let startPlay, isPlaystarted, livePlayerStartTime;
        // console.log("source", source)
        player.load(source)
            .then(() => {
                if (shouldPlay) {
                    player.play();
                }
                // self.stateUpdate("playStart", true);
                startPlay = true;
                // if (playlist[playlistCursor] && playlist[playlistCursor].subtitles && playlist[playlistCursor].subtitles.length > 0) {
                //     self.addSubTitles(playlist[playlistCursor].subtitles)
                // }
                //let seekStart = playlist[playlistCursor].viewduration || 0

                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('t') == true) {
                    let seek = urlParams.get('t');
                    // console.log('seek ,', seek)
                    player.seek(seek)
                }
                //player.seek(seekStart)
            });
        player.on(bitmovin.player.PlayerEvent.SourceLoaded, function () {
            // self.stateUpdate("playStart", true);
            startPlay = true;
            if (self.state.isPreview) {
                $(".bmpui-controlbar-top").css("display", "none");
            } else {
                $(".bmpui-controlbar-top").css("display", "");
            }
        });
        player.on(bitmovin.player.PlayerEvent.StallStarted, function () {
            isPaused = true;
        });
        player.on(bitmovin.player.PlayerEvent.StallEnded, function () {
            isPaused = false;
        });
        player.on(bitmovin.player.PlayerEvent.TimeChanged, function () {
            if (self.state.isPreview) {
                if (startPlay) {
                    isPlaystarted = true;
                }
                let previewRemaining = previewTimeleft - parseInt(player.getCurrentTime() - previewStartTime)
                //self.progress(previewRemaining, previewTimeleft, $('#previewProgressBar'));

                if (isPlaystarted && startPlay) {
                    livePlayerStartTime = Math.round(player.getCurrentTime());
                    isPlaystarted = false;
                    let previewTimeleft2 = previewTimeleft;
                    if (previewProgressInt) {
                        clearInterval(previewProgressInt);
                    }
                    previewProgressInt = setInterval(function () {
                        if (!isPaused) {
                            previewTimeleft2 = previewTimeleft2 - 1;
                            if (previewTimeleft2 <= 0) {
                                // self.stateUpdate("ramainingCountdown", "");
                                clearInterval(previewProgressInt);
                            } else {
                                let formatedPtimeleft = moment.utc(previewTimeleft2 * 1000).format('HH:mm:ss');
                                // self.stateUpdate("ramainingCountdown", formatedPtimeleft);
                                self.progress(previewTimeleft2, previewTimeleft, $('#previewProgressBar'));
                            }
                        }
                    }, 1000);
                }
                // if (livePlayerStartTime && (livePlayerStartTime + previewTimeleft) == Math.round(player.getCurrentTime())) {
                //     player.pause();
                //     self.stateUpdate("ramainingCountdown", "");
                //     clearInterval(previewProgressInt);
                //     self.setState({ 'alertpopup': true }, () => {
                //         self.manageUserActivity()
                //     });
                // }
                startPlay = false;
                if (previewRemaining <= 0) {
                    // self.stateUpdate("ramainingCountdown", "");
                } else {
                    previewRemaining = moment.utc(previewRemaining * 1000).format('HH:mm:ss');
                    //  self.stateUpdate("ramainingCountdown", previewRemaining);
                    if (self.state.isPreview) {
                        $(".bmpui-controlbar-top").css("display", "none");
                    } else {
                        $(".bmpui-controlbar-top").css("display", "");
                    }
                }
            }
            isPlaystarted = true;
        });
        player.on(bitmovin.player.PlayerEvent.Ready, function () {
            // self.addSelectDropdown();
        });
        player.on(bitmovin.player.PlayerEvent.Paused, function () {
            isPaused = true;
        });
        player.on(bitmovin.player.PlayerEvent.Play, function () {
            isPaused = false;
            // if (nextvideoData) {
            //self.getNextAsset();
            // }
            //self.addSelectDropdown();
            setInterval(function () {
                if (!isPaused) {
                    //self.markersTime()
                }
            }, sendMarkersCurrenttime);
            if (manageUserActivityTimeinterval) {
                clearInterval(manageUserActivityTimeinterval);
            }
            manageUserActivityTimeinterval = setInterval(function () {
                if (!isPaused) {
                    // self.manageUserActivity()
                }
            }, manageUserActivityInterval);
        });
        player.on(bitmovin.player.PlayerEvent.PlaybackFinished, function () {
            self.playItem(playlistCursor);
        });
        let events = bitmovin.player.PlayerEvent; //Player Events
        // if (events) {
        //     for (var evntCnt = 0, len = Object.keys(events).length; evntCnt < len; evntCnt++) {
        //         event = Object.keys(events)[evntCnt];
        //         player.on(bitmovin.player.PlayerEvent[event], function (seekEvent) {
        //             if (!isPaused) {
        //                 // self.logEvent(seekEvent)
        //             }
        //         });
        //     }
        // }
    }
    componentWillUnmount() {
        this.killplayer();
    }

    killplayer() {
        if (player) {
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            playerDestroy();
            player = null;
        }
    }
    okClick() {
        this.setState({ playerMessage: false });
    }
    render() {
        let self = this;
        const { channelAssetsData, channelassets } = self.state;
        //  console.log('channelAssetsDatachannelAssetsDatachannelAssetsData',channelassets)
        return (

            <div className="col-sm-12 player_block">
                {/* {channelAssetsData && channelAssetsData.serviceprovider == "awsInteractiveVideo" ?
                    <video id="video-player" className="el-player ivs_player" ref={this.videoElement} controls playsInline muted /> :
                    <div id="player">
                    </div>} */}
                <div id="player"></div>
                {self.state.loader && <Loader />}
                {/* <Modal isOpen={self.state.playerMessage} className="u-brand-popup u-pad-20t player-url-popup"> */}

                {/* <ModalBody> */}
                {channelassets == "" &&
                    <div className="player_url_popup">
                        <div className="url_content">
                            <h2 className="card-heading">Player URLs not available</h2>
                            {/* <p><a href="#" class="btn btn-lg btn-danger" onClick={e => self.okClick()}>Ok</a></p> */}
                        </div>
                    </div>}



                {/* <div className="row">
                    <div className="col-md-12 text-right"><button type="button" onClick={e => self.okClick()} className="jr-btn btn btn-primary" ><span>Ok</span> </button></div>
                </div> */}
                {/* </ModalBody> */}
                {/* </Modal> */}
            </div>

        );
    }
}
// Player.defaultProps = {
//     markersTime: () => { },
//     deleteMarker: false,
// }

// const mapStateToProps = ({ toDo }) => {
//     const { channelAssetsData } = toDo;
//     return { channelAssetsData }
// };
// export default connect(mapStateToProps, { managePlayList })(ChannelPlayer);

const mapState = ({ loading, managePlaylistData }) => ({ loading, managePlaylistData });
const mapDispatch = { managePlayList };
export default withRouter(connect(mapState, mapDispatch)(ChannelPlayer));
