// import React from 'react';
import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
class AsyncExample extends Component {
    constructor(props) {
        super(props);
        this.state = {
           // options: this.props.options,
        };
    }
    componentDidMount() {
        let self = this;
      
    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.options !== this.props.options) { 
            // var { labelKey } = this.props
            
            let options = this.props.options;
            //  console.log("options12",options)
            // _.each(options, function(item){
            //     if(item.name && item.name != '' && item.name != " "){
            //         labelKey = 'name'
            //     }
            //     else{
            //         labelKey = 'companyname'
            //     }
            // })
            // if (labelKey == "name" || labelKey == "emailid") {
            //     options && options.map(function (item, i) {
            //         if (item[labelKey] && item[labelKey] != "") {
            //             item[labelKey] = item[labelKey];
            //             options[i][labelKey] = item[labelKey];
            //         } else {
            //             item[labelKey] = " ";
            //             options[i][labelKey] = " ";
            //         }
            //     })
            // } else if (Array.isArray(options)) {
            //     options = options;
            // } else {
            //     options = []
            // }
            self.setState({ options })
            // console.log("childoptions....",options)
        }
    }

    render() {
        const {options, onChange, multiple, selected, placeholder, allowNew, labelKey, disabled, _handleSearch, isLoading, userType } = this.props;
        // const { options } = this.state;
        return (
            <AsyncTypeahead
                allowNew={allowNew}
                newSelectionPrefix='Add a new email:'
                id="root_testName"
                // filterBy={true}
                labelKey={labelKey}
                multiple={multiple}
                minLength={2}
                clearButton
                onSearch={e => _handleSearch(e, placeholder, userType)}
                onChange={e => onChange(e, placeholder)}
                selected={selected}
                options={options}
                isLoading={isLoading}
                disabled={disabled}
                useCache={false}
                placeholder={"Search for a " + placeholder}
            />
        );
    }
}
AsyncExample.defaultProps = {
    allowNew: false,
    multiple: true,
    isView: false,
};
export default AsyncExample;