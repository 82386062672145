import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from "react-router";
import { getAssets, managePlayList, getPlaybackUrls } from '../../redux/reducers/reducer';
import { removeSpecialCharecters, location } from '../../utils/utils';
// import Header from './Header';
// import Footer from './Footer';
// import MiniPlayer from './MiniPlayer';
import PlaylistPlayer from './PlaylistPlayer';
import Modal from 'react-modal';
import Loader from '../Loader';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let { appName, appEnvName } = window.app;
var urlParams = location("id");
let player;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#0C0C0C",
    color: isDragging ? "black" : "#bbb",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : "",
    // padding: grid

});
class NewManagePlaylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            privacy: false,
            addPopup: false,
            assetCount: 12,
            pageCount: 12,
            pageNumber: 1,
            deletePopup: false,
            assetPlaylist: [],
            FilterData: [],
            playlistid: '',
            selectedasset: {},
            searchInput: '',
            playerVideo: false,
            isLoading: false,
            popupPlayer: true,
            playlistPlayer: false,
            selectedAssets: [],
            searchTerm: "",
            todos:
                [{ taskID: 1, task: 'Walk the walk' },
                { taskID: 2, task: 'Talk the talk' },
                { taskID: 3, task: 'Jump the jump' }],
            completedTasks: [],
            draggedTask: {},
            taskcolumns: [{
                title: 'userPlaylist',
                items: []
            },
            {
                title: 'userRecordings',
                items: []
            }]

        }
        this.onDragEnd = this.onDragEnd.bind(this);
        //this.playerClick = this.playerClick.bind(this);
        // this.onDrag = this.onDrag.bind(this); onDragEndAssets
        this.playerAsset = this.playerAsset.bind(this)
        this.onDragEndAssets = this.onDragEndAssets.bind(this)
        this.onDragEndLatest = this.onDragEndLatest.bind(this)
    }
    componentDidMount() {
        let self = this;
        let id = urlParams;
        this.setState({ id })
        self.fetchManagePlayList();

        // const { assetCount, pageNumber, playlistid } = self.state;
        // let userCode = localStorage.getItem('userCode') || '';
        // let payload = { userCode, playlistid: id }
        // this.props.getAssets(payload);


    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.assets !== this.props.assets) {
            let assetsData = ((this.props.assets || {}).data || []);

            let taskcolumns = [...self.state.taskcolumns];
            // 2. Make a shallow copy of the item you want to mutate
            let item = { ...taskcolumns[1] };
            // 3. Replace the property you're intested in
            item.items = assetsData;
            // 4. Put it back into our array. N.B. we *are* mutating the array here, 
            //    but that's why we made a copy first
            taskcolumns[1] = item;
            // 5. Set the state to our new copy
            // console.log("taskcolumns112 ", taskcolumns)
            self.setState({ taskcolumns });
            self.setState({ assetsData, FilterData: assetsData })


        }
        if (prevProps.playbackUrls != this.props.playbackUrls) {
            let playbackUrlsData = (this.props.playbackUrls || {});
            this.setState({ playbackUrlsData })
            let playerUrl = '';
            if (playbackUrlsData) {
                let playerUrls = playbackUrlsData;
                if (playerUrls && playerUrls.playback) {
                    let playbackUrls = playerUrls.playback;
                    if (playbackUrls) {
                        playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl;
                    }
                }
                //Added this to play for previous object 
                if (!playerUrl) {
                    playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || (playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
                    // console.log("playerUrls22", playerUrl)

                }
                self.playerAsset(playerUrl)
            }
            if (!playerUrl) {
                this.setState({
                    showMessage: true
                })
            } else {
                this.setState({ playbackURL: playerUrl })
            }
        }

    }
    componentWillUnmount() {
        this.killplayer();
    }
    killplayer() {
        if (player) {
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            playerDestroy();
            player = null;
        }
    }
    playerAsset(playbackURL) {
        let self = this;
        const { playerDivID, selectedasset } = self.state


        let filename = selectedasset && selectedasset.assetname || "";
        if (filename && filename.length > 25) {
            filename = filename.substring(0, 15) + "..."
        }
        //const { playbackURL } = self.state;
        let playSource = {
            "title": filename,
            "poster": selectedasset && selectedasset.landscape_logo_image || '',
            "hls": playbackURL
        }

        if (playSource)
            $("#" + playerDivID).removeClass("hidediv");
        self.bitmovinplayer(playSource, selectedasset.assetid, playerDivID)
    }
    bitmovinplayer(playSource, assetid, playerDivID) {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
        let bitAnalyticsKey = bitmovinSettings.analyticsKey;
        let playerKey = bitmovinSettings.playerKey;
        let config = {
            "key": playerKey
        };
        let configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitAnalyticsKey,
                videoId: assetid
            },
            playback: {
                autoplay: true
            }
        });
        let startPlay, isPlaystarted, livePlayerStartTime;
        var container = document.getElementById(playerDivID);
        player = new bitmovin.player.Player(container, configWithAnalytics);

        player.load(playSource).then(
            function (value) {

            },
            function (reason) {
            }
        );

        player.on(bitmovin.player.PlayerEvent.Play, function () {
            isPaused = false;
        });
        player.on(bitmovin.player.PlayerEvent.Paused, function () {
            isPaused = true;
        });


        player.on(bitmovin.player.PlayerEvent.SourceLoaded, function () {
            self.stateUpdate("playStart", true);
            startPlay = true;
            if (self.state.isPreview) {
                $(".bmpui-controlbar-top").css("display", "none");
            } else {
                $(".bmpui-controlbar-top").css("display", "");
            }
        });
    }
    fetchManagePlayList() {

        let self = this;
        // if (urlParams) {
        //     this.setState({ id: urlParams });
        // }
        let id = urlParams;
        console.log('url paramss', id)
        let playlistbody = {
            activity: "fetch"
        }
        // let url = envoiSubmissionApiBaseUrl + '/manageUserPlaylist?appname=' + appName + '&env=' + appEnvName + '&token=' + token + '&assetcount=' + self.state.assetCount + '&pageNumber=' + self.state.pageNumber + locationUrl;
        if (id) {
            let url = envoiSubmissionApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + appEnvName + '&appname=' + appName + "&id=" + id + '&scrubbing=true';
            axios.post(url, playlistbody)
                .then((response) => {


                    // 1. Make a shallow copy of the items
                    let taskcolumns = [...self.state.taskcolumns];
                    // 2. Make a shallow copy of the item you want to mutate
                    let item = { ...taskcolumns[0] };
                    // 3. Replace the property you're intested in
                    item.items = response && response.data && response.data.result && response.data.result.assetData || [];
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, 
                    //    but that's why we made a copy first
                    taskcolumns[0] = item;
                    // 5. Set the state to our new copy
                    // console.log("taskcolumns112 ", taskcolumns)
                    self.setState({ taskcolumns });


                    self.setState({
                        allPlayLists: response && response.data && response.data.result,
                        assetPlaylist: response && response.data && response.data.result && response.data.result.assetData,
                        isLoading: false,
                        playlistid: response && response.data && response.data.result && response.data.result.uuid

                    }, () => {
                        let userCode = localStorage.getItem('userCode') || '';
                        const { playlistid } = self.state;
                        // console.log('playlistid12345--->', self.state.playlistid)
                        // let payload = { userCode, playlistid }
                        //this.props.getAssets(payload);
                    });

                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }
    addClick(e) {
        let self = this;
        self.setState({
            addPopup: true,
        });
    }
    closeAddToPlaylistPopup() {
        this.setState({ addPopup: false })
    }

    addToPlaylistNew(data) {
        let self = this;
        let assets = []
        assets[0] = data
        // console.log("assets ", assets)
        if (data) {
            let playlistbody = { activity: "update", assets: assets, playlistids: [self.state.id] };
            let url = envoiSubmissionApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + appEnvName + '&appname=' + appName + '&scrubbing=true';
            axios.post(url, playlistbody)
                .then((response) => {

                    let manageplaylistsData = response && response.data && response.data.result || "";
                    if (manageplaylistsData && manageplaylistsData == 'Assets Added successfully') {
                        // self.fetchManagePlayList();
                        // self.setState({ loader: false, addPopup: false });
                    }

                })
                .catch((err) => {
                    console.log(err);
                })
            self.setState({ loader: false, addPopup: false });
        }
    }
    addToPlaylist() {
        let self = this;
        // let data = self.state.data
        const { taskcolumns, selectedAssets } = self.state;
        // console.log('taskcolumns', taskcolumns, selectedAssets)

        if (selectedAssets && selectedAssets.length > 0) {
            let assets = []
            assets = selectedAssets
            // console.log("assets ", assets)
            let sourceColumn = taskcolumns.filter((item, index) => item.title == "userRecordings")
            let destColumn = taskcolumns.filter((item, index) => item.title == "userPlaylist")

            // console.log("sourceColumn ", sourceColumn)
            // console.log("destColumn ", destColumn)
            const sourceItems = [...sourceColumn[0].items];
            const destItems = [...destColumn[0].items];

            // console.log("sourceItems ", sourceItems)
            // console.log("destItems ", destItems)
            const filteredAssets = sourceItems.filter(({ assetid: id1 }) => selectedAssets.some(({ assetid: id2 }) => id2 === id1));
            // console.log("filteredAssets ", filteredAssets)

            let sourceListFiltered = sourceItems.filter(({ assetid: id1 }) => !selectedAssets.some(({ assetid: id2 }) => id2 === id1));
            // console.log("sourceListFiltered ", sourceListFiltered)

            filteredAssets.forEach(data => {
                destItems.push(data)
            })

            // console.log("destItems ", destItems)

            let resultItems = [
                {
                    title: "userPlaylist",
                    items: destItems
                },
                {
                    title: "userRecordings",
                    items: sourceListFiltered
                }

            ]
            // console.log("resultItems ", resultItems)
            this.setState({ taskcolumns: resultItems, FilterData: sourceListFiltered, selectedAssets: [] });


            let playlistbody = { activity: "update", assets: selectedAssets, playlistids: [self.state.id] };

            let url = envoiSubmissionApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + appEnvName + '&appname=' + appName + '&scrubbing=true';
            axios.post(url, playlistbody)
                .then((response) => {
                    // console.log("response", response)
                    let manageplaylistsData = response && response.data && response.data.result || "";
                    if (manageplaylistsData && manageplaylistsData == 'Assets Added successfully') {
                        //  self.fetchManagePlayList();
                        //self.setState({ loader: false,addPopup: false  });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
            // self.setState({ loader: false, addPopup: false });
        }
    }
    handleOrder(assets) {
        let self = this;
        if (assets.length > 0) {
            assets = assets.map((elm, i) => { return { assetid: elm.assetid, assetname: elm.assetname, orderId: i } });
            let playlistbody = { activity: "order", assets: assets, playlistids: [this.state.id] };
            // this.props.managePlayList(payload);
            let url = envoiSubmissionApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + appEnvName + '&appname=' + appName + '&scrubbing=true';
            axios.post(url, playlistbody)
                .then((response) => {
                    // console.log("response", response)
                    let manageplaylistsData = response && response.data && response.data.result || "";
                    if (manageplaylistsData && manageplaylistsData == 'Ordered successfully') {
                        self.fetchManagePlayList();
                        //self.setState({ loader: false,addPopup: false  });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
            this.setState({ loader: true, successBar: 'block' });
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const assetPlaylist = reorder(
            this.state.assetPlaylist,
            result.source.index,
            result.destination.index
        );
        this.handleOrder(assetPlaylist);
        this.setState({
            assetPlaylist
        });

    }
    onDragEndAssets(result) {
        let self = this
        const { assetsData, isLoading } = this.state
        const item = assetsData && assetsData.find(eachEle => eachEle.assetid === result.draggableId)
        if (item) {
            self.setState({ isLoading: true })
            self.addToPlaylistNew(item)
        }
    }
    handleSelect(e, item) {
        let self = this;
        // console.log("item ", item)
        // let data = [...self.state.assetsData];
        let value = e.target.checked;
        const { selectedAssets } = this.state



        if (value) {
            selectedAssets.push(item)
            self.setState({ selectedAssets });
        } else {
            if (selectedAssets && selectedAssets.length > 0) {
                let destItemIndex = selectedAssets.findIndex((list, index) => list.assetid == item.assetid)
                // console.log("destItemIndex ", destItemIndex)
                selectedAssets.splice(destItemIndex, 1);
                // console.log("selectedAssets ", selectedAssets)
                self.setState({ selectedAssets });
            }
        }
        // console.log("selectedAssets ",selectedAssets)
        // console.log("value ",value)
        // if (data && data.length > 0) {
        //     if (item) {
        //         let selectedIndex = data.findIndex(elm => elm.assetid == item.assetid);
        //         item['isChecked'] = value;
        //         data[selectedIndex] = item;
        //     } else {
        //         data.map(elm => elm['isChecked'] = value)
        //     }
        //     console.log("Data ",data)
        //     self.setState({ data });
        // }
    }
    updateTaskColumns(assetid) {
        let self = this;
        const { taskcolumns } = self.state;
        let sourceColumn = taskcolumns.filter((item, index) => item.title == "userRecordings")
        let destColumn = taskcolumns.filter((item, index) => item.title == "userPlaylist")

        // console.log("sourceColumn ", sourceColumn)
        // console.log("destColumn ", destColumn)
        const sourceItems = [...sourceColumn[0].items];
        const destItems = [...destColumn[0].items];
        let destItemIndex = destItems.findIndex((item, index) => item.assetid == assetid)
        // console.log("destItemIndex ", destItemIndex)
        const [removed] = destItems.splice(destItemIndex, 1);
        // console.log("removed ", removed)
        sourceItems.push(removed);


        // console.log("sourceItems ", sourceItems)
        // console.log("destItems ", destItems)

        let resultItems = [
            {
                title: "userPlaylist",
                items: destItems
            },
            {
                title: "userRecordings",
                items: sourceItems
            }

        ]
        // console.log("resultItems ", resultItems)
        this.setState({ taskcolumns: resultItems, FilterData: sourceItems });


    }
    deleteAsset(e, asset) {
        let self = this;

        const { taskcolumns, id } = self.state;
        if (id) {
            self.setState({
                mylist: asset.mylist, deleteAsset: {}, deletePopup: false
            });
            let playlistbody = {
                assetid: asset.assetid,
                activity: "delete",
                type: 'delete',
                playlistids: [self.state.id]
            }
            // console.log("taskcolumns ", taskcolumns)

            self.updateTaskColumns(asset.assetid)


            // self.props.managePlayList(mylistbody)


            let url = envoiSubmissionApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + appEnvName + '&appname=' + appName + '&scrubbing=true';
            axios.post(url, playlistbody)
                .then((response) => {
                    // console.log("response", response)
                    let manageplaylistsData = response && response.data && response.data.result || "";
                    if (manageplaylistsData && manageplaylistsData == 'Removed Successfully') {
                        // self.fetchManagePlayList();
                        //self.setState({ loader: false,addPopup: false  });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        self.killplayer()
    }

    deleteClick(asset) {
        let self = this;
        self.setState({ deletePopup: true, deleteItem: asset });
    }
    filterList() {
        let self = this
        const { allPlayLists, assetPlaylist } = self.state

    }
    onClickSearch = event => {
        let self = this
        const { taskcolumns } = self.state
        let userRecordingsData = taskcolumns[1].items

        const FilterData = userRecordingsData && userRecordingsData.length > 0 && userRecordingsData.filter(eachItem =>
            eachItem.assetname && eachItem.assetname.toLowerCase().includes(event.target.value.toLowerCase())
        )

        self.setState({ searchInput: event.target.value, FilterData })


    }
    defaultPlaylistPlayer() {
        let self = this
        const { assetPlaylist } = self.state
        self.setState({ selectedAsset: assetPlaylist })
    }

    onClickPlayAsset(selectedAsset, playerDivID) {
        let self = this
        self.killplayer();
        let assetid = selectedAsset.assetid
        // console.log('aaaaadsetid', assetid)
        let payload = { assetId: assetid }

        self.props.getPlaybackUrls(payload)
        self.setState({ selectedAsset, playerDivID, playerVideo: true, popupPlayer: false, playlistPlayer: false })

        // self.setState({ selectedAsset }, () => console.log('selected playlist asset', self.state.selectedAsset))
    }
    // playerClick(item, playerDivID) {
    //     let self = this
    //     // let assetid = item.assetid
    //     // console.log('aaaaadsetid', assetid)
    //     // let payload = { assetId: assetid }

    //     // self.props.getPlaybackUrls(payload)
    //     self.setState({ selectedasset: item, playerDivID, playerVideo: true })


    // }
    buttonclick(e) {
        let self = this;
        self.killplayer();
       // console.log('item', "item")
        self.setState({ playlistPlayer: true, popupPlayer: false, playerVideo: false, })
    }
    onDragEndLatest(result, columns) {
        let self = this;
        // console.log("result ", result)
        // console.log("columns ", columns)
        // if (!result.destination) return;
        const { source, destination, draggableId } = result;
        // Arrange Order
        if (source && destination && source.droppableId == "userPlaylist" && destination.droppableId == "userPlaylist") {

            //   const column = columns[source.droppableId];
            let columnIndex = columns.findIndex((item, index) => item.title == source.droppableId)
            let column = columns.filter((item, index) => item.title == source.droppableId)
            const copiedItems = [...column[0].items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);


            // console.log("copiedItems ", copiedItems, column, columnIndex)

            // 1. Make a shallow copy of the items
            let taskcolumns = [...this.state.taskcolumns];
            // 2. Make a shallow copy of the item you want to mutate
            let item = { ...taskcolumns[columnIndex] };
            // 3. Replace the property you're intested in
            item.items = copiedItems;
            // 4. Put it back into our array. N.B. we *are* mutating the array here, 
            //    but that's why we made a copy first
            taskcolumns[columnIndex] = item;
            // 5. Set the state to our new copy
            this.setState({ taskcolumns });

        } else if (destination && destination.droppableId == "userPlaylist") { // Add to Playlist
            // console.log("sourceColumnd ", source.droppableId, destination.droppableId, draggableId)
            let sourceColumn = columns.filter((item, index) => item.title == source.droppableId)
            let destColumn = columns.filter((item, index) => item.title == destination.droppableId)
            //const sourceColumn = columns[source.droppableId];
            //const destColumn = columns[destination.droppableId];
            // console.log("sourceColumn ", sourceColumn)
            // console.log("destColumn ", destColumn)
            const sourceItems = [...sourceColumn[0].items];
            const destItems = [...destColumn[0].items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            // console.log("sourceItems ", sourceItems)
            // console.log("destItems ", destItems)
            let destItem = destItems.filter((item, index) => item.assetid == draggableId)
            // console.log("destItem ii ", destItem)
            self.addToPlaylistNew(destItem[0])

            let resultItems = [
                {
                    title: destination.droppableId,
                    items: destItems
                },
                {
                    title: source.droppableId,
                    items: sourceItems
                }

            ]
            // console.log("resultItems ", resultItems)
            this.setState({ taskcolumns: resultItems, FilterData: sourceItems, playlistPlayer: false, playerVideo: true, });

        }

    }
    searchAssets() {
        let self = this;
        let searchvalue = self.state.searchvalue
        if (searchvalue != "") {


            self.setState({ loader: true, searchTerm: searchvalue.toString().toUpperCase() });
            let search = { search: searchvalue }
            let formdata = { search: search }
            //   this.props.userTypeContent(formdata);
            let appSettings = window.app || {};
            let appStorage = appSettings.storage || {};
            let token = appStorage.getItem("token");
            let siteSettings = window.site || {};
            const { assetCount, pageNumber } = this.state;
            let envoiScreeningApiBaseUrl = siteSettings.screeningApiBaseUrl;
            let urlLink = `${envoiScreeningApiBaseUrl}/content?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&contentType=${"content"}&ispublished=${"true"}`;

            axios({
                method: 'POST',
                url: urlLink,
                data: search
            })
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.data) {
                        let assetsData = ((response.data.result || {}).data || []);

                        let taskcolumns = [...self.state.taskcolumns];
                        // 2. Make a shallow copy of the item you want to mutate
                        let item = { ...taskcolumns[1] };
                        // 3. Replace the property you're intested in
                        item.items = assetsData;
                        // 4. Put it back into our array. N.B. we *are* mutating the array here, 
                        //    but that's why we made a copy first
                        taskcolumns[1] = item;
                        // 5. Set the state to our new copy
                        // console.log("taskcolumns112 ", taskcolumns)
                        self.setState({ taskcolumns });
                        self.setState({ assetsData, FilterData: assetsData })
                    }
                });
        }
    }

    searchUpdated(term) {
        let self = this;
        self.setState({
            searchvalue: term.target.value,
            beforeSearch: false,
            data: []
        });
    }
    onSearchEnter(e) {
        if (e.key === 'Enter') {
            this.searchAssets();
        }
    }
    // handleSelect(e, item) {
    //     let self = this;
    //     let FilterData = [...self.state.FilterData];
    //     let value = e.target.checked;
    //     if (FilterData && FilterData.length > 0) {
    //       if (item) {
    //         let selectedIndex = FilterData.findIndex(elm => elm._id == item._id);
    //         item['isChecked'] = value;
    //         FilterData[selectedIndex] = item;
    //       } else {
    //         FilterData.map(elm => elm['isChecked'] = value)
    //       }
    //       self.setState({ FilterData });
    //     }
    //   }
    render() {
        let self = this;
        const { } = this.props;
        const { allPlayLists, todos, completedTasks, addPopup, selectedAsset, playerDivID, isLoading, assetPlaylist, assetsData, searchInput, deletePopup, item, playlistPlayer, taskcolumns, FilterData, searchTerm } = this.state;
        // console.log('selectedassetkkk', self.state.selectedasset)

        let siteSettings = window.site;

        let siteConfig = siteSettings.config;
        let imagesAssetResourcesPrefix = siteConfig && siteConfig.commonResourcesUrl
        // console.log("taskcolumns ender ", taskcolumns)
        // console.log("FilterData ender ", FilterData)
        return (
            <React.Fragment>

                <div className="manage_playlist_section">
                    <div className="manage_playlist">


                        <DragDropContext
                            onDragEnd={(result) => this.onDragEndLatest(result, taskcolumns)}
                        >

                            {taskcolumns && taskcolumns.length > 0 && taskcolumns.map((column, index) => {
                                let assetsList = column.title == "userRecordings" ? FilterData : column.items
                                return (
                                    <Droppable key={index} droppableId={column.title}>
                                        {(provided, snapshot) => (

                                            <div className={column.title == "userPlaylist" ? "playlist_left" : "playlist_right"} {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}>

                                                <div className={column.title == "userRecordings" ? 'playlist-pp' : ''}>

                                                    {column.title == "userPlaylist" && assetsList && assetsList.length > 0 &&
                                                        <div className='playlist_hed'>
                                                            <h3>Playlist</h3>
                                                            {assetsList && <h5>Asset Count:{assetsList && assetsList.length}</h5>}
                                                            <button className="preview_btn" onClick={(e) => self.buttonclick(e)}>Preview</button>
                                                        </div>
                                                    }
                                                    {column.title == "userRecordings" &&
                                                        <>

                                                            <div className='video_preview'>
                                                                {playlistPlayer &&
                                                                   <PlaylistPlayer id={self.state.playlistid} />
                                                                }
                                                                {self.state.playerVideo &&
                                                                    <div className='video' id="playMovie">
                                                                    </div>}
                                                                {self.state.popupPlayer &&
                                                                    <div className='video' id="playMovie">
                                                                        {/* <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a> */}
                                                                        <img src={siteConfig.defaultLandscape + siteConfig.imageSuffix} />
                                                                    </div>}
                                                                <div className='video_info'>

                                                                    {selectedAsset && selectedAsset.formatedduration &&
                                                                        <div className='drs'>{selectedAsset.formatedduration}</div>}
                                                                </div>
                                                            </div>

                                                            <div className="pl_search">
                                                                <input type="search" className="input" onKeyDown={e => this.onSearchEnter(e)} onChange={e => this.searchUpdated(e)}
                                                                    value={this.state.searchvalue}
                                                                    placeholder="Search Videos"
                                                                />
                                                                <button onClick={e => self.searchAssets(e)}><i className="material-icons">search</i></button>
                                                            </div>

                                                        </>
                                                    }
                                                    {FilterData && FilterData.length > 0 &&
                                                        <div className="results mt-3">

                                                            {searchTerm && FilterData && FilterData.length > 0 ? <h6>Showing results for <strong>{searchTerm}</strong>, <strong>{FilterData.length}</strong> assets</h6> : searchTerm && <h6>No results found for <strong>{searchTerm}</strong></h6>}
                                                            {/* {FilterData && FilterData.length > 0 &&
                                                                <div className="check-grp">
                                                                    <label>Select All</label><input name='isChecked' type="checkbox" onChange={e => self.handleSelect(e)} />
                                                                </div>} */}
                                                        </div>}
                                                    <ul className={column.title == "userPlaylist" ? "playlist_items done" : "playlist_items"}>


                                                        {assetsList && assetsList.length > 0 ? (<>
                                                            {assetsList && assetsList.length > 0 && assetsList.map((item, index) => (
                                                                <Draggable key={item.assetid} draggableId={item.assetid} index={index}>
                                                                    {(provided) => (
                                                                        <li className="pl_item_con"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >

                                                                            <i className="material-icons drag-mark">drag_indicator</i>
                                                                            <div className="img" onClick={() => self.onClickPlayAsset(item, "playMovie")}>
                                                                                <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a>
                                                                                <img src={item && item.landscape_logo_image ? item.landscape_logo_image : siteConfig.defaultLandscape} />

                                                                            </div>
                                                                            <div className="title">

                                                                                <h4>{item.assetname}</h4>
                                                                                <p>{item && item.formatedduration}</p>


                                                                            </div>

                                                                            {column.title == "userRecordings" &&
                                                                                <label className="checkbox">
                                                                                    <input type="checkbox" name='isChecked' onChange={e => self.handleSelect(e, item)} />
                                                                                    <span className="check"></span>
                                                                                </label>}

                                                                            {column.title == "userPlaylist" &&
                                                                                <a className="pl_delete" href="#" onClick={e => self.deleteClick(item)}><span className="material-icons">delete</span></a>}

                                                                        </li>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        </>) :
                                                            <div className="warning-wrapper empty-result">
                                                                <div className="error-text">
                                                                    <p> No Assets Available</p>
                                                                </div>

                                                            </div>}

                                                    </ul>

                                                    {column.title == "userRecordings" && FilterData && FilterData.length > 0 &&
                                                        <button type="button" className="btn btn-primary" onClick={() => self.addToPlaylist()}>Select & Add</button>
                                                    }
                                                </div>
                                            </div>

                                        )}
                                    </Droppable>
                                );
                            })}


                        </DragDropContext>




                        {/* {assetPlaylist && assetPlaylist.length > 0 &&
                                <button className="text-right" onClick={(e) => self.buttonclick(e)}>Preview</button>
                                } */}
                        {/* <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <ul className="playlist_items done"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}

                                        >
                                           
                                            {assetPlaylist && assetPlaylist.length > 0 ? (<>
                                                {assetPlaylist && assetPlaylist.length > 0 && assetPlaylist.map((item, index) => (
                                                    <Draggable key={item.assetid} draggableId={item.assetid} index={index}>
                                                        {(provided, snapshot) => (
                                                            <li
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div className="pl_item_con">
                                                                    <i className="material-icons drag-mark">drag_indicator</i>
                                                                    <div className="img" onClick={() => self.onClickPlayAsset(item, "playMovie")}>
                                                                        <img src={item && item.landscape_logo_image ? item.landscape_logo_image : siteConfig.defaultLandscape} />

                                                                    </div>
                                                                    <div className="title">

                                                                        <h4>{item.assetname}</h4>
                                                                        <p>{item && item.formatedduration}</p>
                                                                    </div>

                                                                    <a className="pl_delete" href="#" onClick={e => self.deleteClick(item)}><span className="material-icons">delete</span></a>
                                                                </div>

                                                            </li>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </>)

                                                : isLoading ? <Loader /> :
                                                    <div className="warning-wrapper empty-result">
                                                        <div className="error-text">
                                                            <p> No Playlists available</p>
                                                        </div>

                                                    </div>}
                                            {provided.placeholder}
                                     
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext> */}

                        {/*  <div className="playlist_right">

                            <div>
                                {FilterData && FilterData.length > 0 &&
                                    <div className="pl_search">
                                        <input type="search" className="input" onChange={this.onClickSearch}
                                            value={searchInput}
                                            placeholder="Search Videos"
                                        />
                                    </div>}
                                <div className="playlist-pp">
                                    <DragDropContext onDragEnd={this.onDragEndAssets}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <ul className="playlist_items"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {FilterData && FilterData.length > 0 ? (<>
                                                        {FilterData && FilterData.length > 0 && FilterData.map((item, index) => (
                                                            <Draggable key={item.assetid} draggableId={item.assetid} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <li
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}

                                                                    >
                                                                        <div className="pl_item_con">
                                                                            <i className="material-icons drag-mark">drag_indicator</i>
                                                                            <div className="img" onClick={() => self.onClickPlayAsset(item, "playMovie")}>
                                                                                <img src={item && item.landscape_logo_image ? item.landscape_logo_image : siteConfig.defaultLandscape} />

                                                                            </div>
                                                                            <div className="title">

                                                                                <h4>{item.assetname ? item.assetname.slice(0, 29) + '...' : ""}</h4>
                                                                                <p>{item && item.formatedduration}</p>
                                                                            </div>

                                                                            <label className="checkbox">
                                                                                <input type="checkbox" name='isChecked' onChange={e => self.handleSelect(e, item)} />
                                                                                <span className="check"></span>
                                                                            </label>
                                                                        </div>

                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        ))}</>) : isLoading ? <Loader /> :
                                                        <div className="warning-wrapper empty-result">
                                                            <div className="error-text">
                                                               
                                                                <p> No assets available</p>
                                                            </div>

                                                        </div>}
                                                    {provided.placeholder}

                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    {FilterData && FilterData.length > 0 ?
                                        <button type="button" className="btn btn-primary" onClick={() => self.addToPlaylist()}>Select & Add</button>
                                        : null}
                                </div> 

                            </div>


                        </div>*/}



                    </div>
                </div>
                <Modal
                    isOpen={deletePopup}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="custom_modal alert_popup">
                    <div className="alert_icon">
                        <span className="material-icons">delete_outline</span>
                    </div>
                    <p>Are you sure you want to delete ? </p>
                    <div className="form-group footer-btns">
                        <button type="button" className="btn cancelbtn" onClick={e => self.setState({ deletePopup: false, deleteItem: {} })}>Cancel</button>
                        <button type="button" className="btn" onClick={e => self.deleteAsset(e, self.state.deleteItem)} >Delete</button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapState = ({ assets, manageplaylistsData, playbackUrls }) => ({ assets, manageplaylistsData, playbackUrls });
const mapDispatch = { getAssets, managePlayList, getPlaybackUrls };
export default connect(mapState, mapDispatch)(NewManagePlaylist);