import React, { Component } from 'react';
import { connect } from 'react-redux';
import { managePlayList } from '../../redux/reducers/reducer';
import axios from "axios";
import { Modal, ModalBody, ModalHeader, CardBody, TabContent } from 'reactstrap';
// import { isArray } from 'util';
let bitmovininterval;
let playerStream = 'hls';
let playlist = [];
let playlistCursor;
let playlistShouldAutoPlay = true;
let playlistShouldResetOnFinish = true;
let isPaused = false;
let playlistShouldRepeat = true;
let manageUserActivityInterval = 10000;
let sendMarkersCurrenttime = 100;
let manageUserActivityTimeinterval;
let previewStartTime = 0;
let player;
class PlaylistPlayer extends Component {
    constructor(props) {
        super(props);
        // console.log("playerStart",props.startPlayer)
        this.state = {
            item: props.item,
            startPlayer: props.startPlayer || false,
            loader: false,
            playerMessage: false
        };
    }


    componentDidMount() {
        let self = this;
        const { id } = this.props;
        // let item = self.state.item || {};
        // self.setState({ loader: true })
        let playlistbody = {
            activity: "fetch",
            playBackUrls: true
        }
        self.props.managePlayList(playlistbody, id);

    }
    componentDidUpdate(prevProps) {
        var self = this;
        if (prevProps.manageplaylistsData !== this.props.manageplaylistsData) {
            let playlistAssetsAray = [];
            let channelAssetsData = (this.props.manageplaylistsData) || {};
            if (channelAssetsData && channelAssetsData.assetData) {
                let channelassets = channelAssetsData.assetData;
                channelassets && channelassets.length > 0 && channelassets.forEach((element) => {

                    let obj = {
                        assetname: element.assetname,
                        assetid: element.assetid,
                        m3u8_Url: element.playerurls && element.playerurls.m3u8_Url || element.playerurls.m3u8_url
                    }
                    playlistAssetsAray.push(obj);
                    if(element && element.playerurls && element.playerurls == {}){
                        self.setState({ playerMessage: true });
                    }
                   else if (obj && obj.m3u8_Url == "" && (obj && obj.m3u8_Url == "undefined" || obj.m3u8_Url == undefined)) {
                        self.setState({ playerMessage: true });
                    } else if (obj && obj.m3u8_Url != "") {
                        console.log('obj',obj)
                        playlist = [];
                        playlistCursor = 0;
                        self.fetchPlaylist(playlistAssetsAray)
                        self.loadBitmovin();
                        self.setState({ channelAssetsAray: playlistAssetsAray })
                    } else {
                        self.setState({ playerMessage: true });
                    }
                    // self.bitmovinplayer(channelAssetsAray)

                });
                // console.log("channelassets",channelassets)


            } else {
                self.setState({ playerMessage: true });
            }
            self.setState({ channelAssetsData: this.props.manageplaylistsData, loader: false });
        }
    }
    loadBitmovin() {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = (siteSettings && siteSettings.config)
        var playerkey, bitAnalyticsKey;
        playerkey = ((siteConfig.player || {}).bitmovin || {}).playerKey;
        bitAnalyticsKey = ((siteConfig.player || {}).bitmovin || {}).analyticsKey;
        let config = {
            "key": playerkey
        };
        let configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitAnalyticsKey,
            },
            playback: {
                autoplay: false
            }
        });
        let container = document.getElementById("player");
        player = new bitmovin.player.Player(container, configWithAnalytics);
        self.setState({ bitmovinReady: true }, () => {
            self.loadBitmovinInterval();
        });

    }

    loadBitmovinInterval() {
        let self = this;
        clearInterval(bitmovininterval);
        bitmovininterval = setInterval(function () {
            if (self.state.bitmovinReady && bitmovin) {
                self.playItem(0);
            }
            clearInterval(bitmovininterval);
        }, 1000);
    }
    fetchPlaylist(assets) {
        let self = this;
        let siteSettings = window.site || {};
        let siteConfig = siteSettings.config || {};
      //  console.log('assets', assets)
        // This would be a result from a get play list API call
        // let playlist = [];
        if (assets && assets.length > 0) {
            assets.forEach(function (asset, i) {
             //   console.log('asset1234', asset)
                if (asset) {
                    let playSource = {};
                    if (asset && asset.assetid) {
                        playSource['assetid'] = asset.assetid
                    }
                    if (asset && asset.assetname) {
                        playSource['title'] = asset.assetname
                    }
                    if (asset && asset.markers) {
                        playSource['markers'] = asset.markers
                    }
                    if (asset.landscape_logo_image) {
                        playSource['poster'] = asset.landscape_logo_image
                    } else {
                        playSource['poster'] = siteConfig && siteConfig.defaultLandscape;
                    }
                    if (asset.preview && asset.preview.enable && !asset.buy && !asset.rent) {
                        playSource['options'] = {
                            startTime: previewStartTime
                        }
                    }
                    if (asset.subtitles) {
                        playSource['subtitles'] = asset.subtitles
                    }
                    if (asset.viewduration) {
                        playSource['viewduration'] = asset.viewduration
                    }
                    let playerUrl = (asset && asset.m3u8_Url) || (asset && asset.m3u8_url)
                    // let playerUrl = (asset && asset.playerurls && asset.playerurls.m3u8_Url) || (asset && asset.playerurls && asset.playerurls.m3u8_url) || (asset && asset.playerurls && asset.playerurls.safestreamUrl) || (asset && asset.playerurls && asset.playerurls.vdms_url) || (asset && asset.playerurls && asset.playerurls.iconik_proxy_url) || (asset && asset.playerurls && asset.playerurls.Mp4_proxy_URL) || (asset && asset.playerurls && asset.playerurls.eluvio_url) || (asset && asset.playerurls && asset.playerurls.source_Url);                    
                   // console.log('playerUrl', playerUrl)
                    if (asset && asset.playerurls && (asset.playerurls.m3u8_Url || asset.playerurls.m3u8_url)) {
                        if (asset.playerurls.m3u8_Url.includes(".m3u8") || asset.playerurls.m3u8_url.includes(".m3u8")) {

                            playerStream = "hls";
                            console.log('playerStream', playerStream)
                        } else {
                            playerStream = "progressive";
                        }
                        playSource[playerStream] = asset.playerurls.m3u8_Url || asset.playerurls.m3u8_url

                    } else {
                        if (playerUrl && playerUrl.includes(".m3u8")) {
                            playerStream = "hls";
                        } else {
                            playerStream = "progressive";
                        }
                        playSource[playerStream] = playerUrl;

                    }
                    //console.log('playSource', playSource)
                    if (playSource[playerStream])
                        playlist.push(playSource);
                }
            })
        }
    }

    playItem(playListIndex) {
        let self = this;
        if (!player) {
            console.log("player", player)
                ; return;
        }
        if (playlist && !playlist.length) {
           // console.log("playlist", playlist);
           self.setState({ playerMessage: true });
            return;
        }
       // console.log('playListIndex', playListIndex)
        let playlistMaxIndex = playlist.length - 1;
     //   console.log('playlistMaxIndex', playlistMaxIndex)
        let shouldPlay = true;
        if (playListIndex)
            playlistCursor = playListIndex;
        if (playlistCursor === -1) {
            // We only want to play if autoplay isn't already set on the player
            let playerConfig = player.getConfig();
            let playerIsSetToAutoPlay = playerConfig.playback.autoplay;
            shouldPlay = playlistShouldAutoPlay && !playerIsSetToAutoPlay;
            // Start over!
            // playlistCursor = 0;
        }
        if (playlistCursor <= playlistMaxIndex) {
            // We aren't at the end yet
            playlistCursor++;
        } else if (playlistCursor > playlistMaxIndex) {
            // We've reached the end of the playlist
            // If we don't want to repeat we at least want to load the first element
            if (!playlistShouldRepeat) {
                if (playlistShouldResetOnFinish) {
                    shouldPlay = false;
                } else {
                    return;
                }
            }
            // Start over!
            playlistCursor = 0;
        }
       // console.log("playlistCursor!!!!", playlistCursor);
        let source = playlist[playlistCursor];
      //  console.log('source', source)
        let startPlay, isPlaystarted, livePlayerStartTime;
        // console.log("source", source)
        player.load(source)
            .then(() => {
                if (shouldPlay) {
                    player.play();
                }
                // self.stateUpdate("playStart", true);
                startPlay = true;
                // if (playlist[playlistCursor] && playlist[playlistCursor].subtitles && playlist[playlistCursor].subtitles.length > 0) {
                //     self.addSubTitles(playlist[playlistCursor].subtitles)
                // }
                //let seekStart = playlist[playlistCursor].viewduration || 0

                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('t') == true) {
                    let seek = urlParams.get('t');
                 //   console.log('seek ,', seek)
                    player.seek(seek)
                }
                //player.seek(seekStart)
            });
        player.on(bitmovin.player.PlayerEvent.SourceLoaded, function () {
            // self.stateUpdate("playStart", true);
            startPlay = true;
            if (self.state.isPreview) {
                $(".bmpui-controlbar-top").css("display", "none");
            } else {
                $(".bmpui-controlbar-top").css("display", "");
            }
        });
        player.on(bitmovin.player.PlayerEvent.StallStarted, function () {
            isPaused = true;
        });
        player.on(bitmovin.player.PlayerEvent.StallEnded, function () {
            isPaused = false;
        });
        player.on(bitmovin.player.PlayerEvent.TimeChanged, function () {
            if (self.state.isPreview) {
                if (startPlay) {
                    isPlaystarted = true;
                }
                let previewRemaining = previewTimeleft - parseInt(player.getCurrentTime() - previewStartTime)
                //self.progress(previewRemaining, previewTimeleft, $('#previewProgressBar'));

                if (isPlaystarted && startPlay) {
                    livePlayerStartTime = Math.round(player.getCurrentTime());
                    isPlaystarted = false;
                    let previewTimeleft2 = previewTimeleft;
                    if (previewProgressInt) {
                        clearInterval(previewProgressInt);
                    }
                    previewProgressInt = setInterval(function () {
                        if (!isPaused) {
                            previewTimeleft2 = previewTimeleft2 - 1;
                            if (previewTimeleft2 <= 0) {
                                // self.stateUpdate("ramainingCountdown", "");
                                clearInterval(previewProgressInt);
                            } else {
                                let formatedPtimeleft = moment.utc(previewTimeleft2 * 1000).format('HH:mm:ss');
                                // self.stateUpdate("ramainingCountdown", formatedPtimeleft);
                                self.progress(previewTimeleft2, previewTimeleft, $('#previewProgressBar'));
                            }
                        }
                    }, 1000);
                }
                // if (livePlayerStartTime && (livePlayerStartTime + previewTimeleft) == Math.round(player.getCurrentTime())) {
                //     player.pause();
                //     self.stateUpdate("ramainingCountdown", "");
                //     clearInterval(previewProgressInt);
                //     self.setState({ 'alertpopup': true }, () => {
                //         self.manageUserActivity()
                //     });
                // }
                startPlay = false;
                if (previewRemaining <= 0) {
                    // self.stateUpdate("ramainingCountdown", "");
                } else {
                    previewRemaining = moment.utc(previewRemaining * 1000).format('HH:mm:ss');
                    //  self.stateUpdate("ramainingCountdown", previewRemaining);
                    if (self.state.isPreview) {
                        $(".bmpui-controlbar-top").css("display", "none");
                    } else {
                        $(".bmpui-controlbar-top").css("display", "");
                    }
                }
            }
            isPlaystarted = true;
        });
        player.on(bitmovin.player.PlayerEvent.Ready, function () {
            // self.addSelectDropdown();
        });
        player.on(bitmovin.player.PlayerEvent.Paused, function () {
            isPaused = true;
        });
        player.on(bitmovin.player.PlayerEvent.Play, function () {
            isPaused = false;
            // if (nextvideoData) {
            //self.getNextAsset();
            // }
            //self.addSelectDropdown();
            setInterval(function () {
                if (!isPaused) {
                    //self.markersTime()
                }
            }, sendMarkersCurrenttime);
            if (manageUserActivityTimeinterval) {
                clearInterval(manageUserActivityTimeinterval);
            }
            manageUserActivityTimeinterval = setInterval(function () {
                if (!isPaused) {
                    // self.manageUserActivity()
                }
            }, manageUserActivityInterval);
        });
        player.on(bitmovin.player.PlayerEvent.PlaybackFinished, function () {
            self.playItem(playlistCursor);
        });
        let events = bitmovin.player.PlayerEvent; //Player Events
        // if (events) {
        //     for (var evntCnt = 0, len = Object.keys(events).length; evntCnt < len; evntCnt++) {
        //         event = Object.keys(events)[evntCnt];
        //         player.on(bitmovin.player.PlayerEvent[event], function (seekEvent) {
        //             if (!isPaused) {
        //                 // self.logEvent(seekEvent)
        //             }
        //         });
        //     }
        // }
    }
    componentWillUnmount() {
        this.killplayer();
    }

    killplayer() {
        if (player) {
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            playerDestroy();
            player = null;
        }
    }
    okClick() {
        this.setState({ playerMessage: false });
    }
    render() {
        let self = this;

        return (


            <>
                <div id="player">
                </div>
                {/* {self.state.loader && <Loader />} */}
                {self.state.playerMessage &&
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <h2 className="card-heading">Player URLs not available</h2>
                                <button type="button" onClick={e => self.okClick()} className="jr-btn btn btn-primary" ><span>Ok</span> </button>
                            </div>
                        </div>
                    </div>
                }
            </>


        );
    }
}
// Player.defaultProps = {
//     markersTime: () => { },
//     deleteMarker: false,
// }
const mapState = ({ manageplaylistsData }) => ({ manageplaylistsData });
const mapDispatch = { managePlayList };
export default connect(mapState, mapDispatch)(PlaylistPlayer);

